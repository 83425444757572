import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function FactoryDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Factory Method Pattern: Creating Objects with Flexibility</h1>
      
      <p>The Factory Method Pattern is a creational design pattern that provides an interface for creating objects, but it allows subclasses to decide which class to instantiate. This pattern promotes loose coupling by decoupling the client code from the concrete classes it uses. In this tutorial, we will explore the Factory Method Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Factory Method Pattern:</b></h3>
			The Factory Method Pattern separates the responsibility of creating objects from the client code. Instead of directly instantiating objects, the client uses a factory method, which is implemented by subclasses to produce instances of classes. This pattern promotes code reusability and scalability by allowing new classes to be added without modifying the existing client code.</p>
			
			<p><b>Implementing Factory Method in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Product interface
public interface Product {
    void create();
}

// Concrete Product A
public class ConcreteProductA implements Product {
    public void create() {
        System.out.println("Product A created.");
    }
}

// Concrete Product B
public class ConcreteProductB implements Product {
    public void create() {
        System.out.println("Product B created.");
    }
}

// Creator interface (Factory Method)
public interface Creator {
    Product createProduct();
}

// Concrete Creator A
public class ConcreteCreatorA implements Creator {
    public Product createProduct() {
        return new ConcreteProductA();
    }
}

// Concrete Creator B
public class ConcreteCreatorB implements Creator {
    public Product createProduct() {
        return new ConcreteProductB();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Factory Method in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Product interface
class Product:
    def create(self):
        pass

# Concrete Product A
class ConcreteProductA(Product):
    def create(self):
        print("Product A created.")

# Concrete Product B
class ConcreteProductB(Product):
    def create(self):
        print("Product B created.")

# Creator interface (Factory Method)
class Creator:
    def create_product(self):
        pass

# Concrete Creator A
class ConcreteCreatorA(Creator):
    def create_product(self):
        return ConcreteProductA()

# Concrete Creator B
class ConcreteCreatorB(Creator):
    def create_product(self):
        return ConcreteProductB()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Factory Method Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class FactoryMethodUsage {
    public static void main(String[] args) {
        Creator creatorA = new ConcreteCreatorA();
        Product productA = creatorA.createProduct();
        productA.create(); // Output: Product A created.

        Creator creatorB = new ConcreteCreatorB();
        Product productB = creatorB.createProduct();
        productB.create(); // Output: Product B created.
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    creator_a = ConcreteCreatorA()
    product_a = creator_a.create_product()
    product_a.create() # Output: Product A created.

    creator_b = ConcreteCreatorB()
    product_b = creator_b.create_product()
    product_b.create() # Output: Product B created.`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Factory Method Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`      +------------------------+
      |        Product         |
      +------------------------+
      | +create()              |
      +------------------------+
                 ^
                 |
     +--------------------------+
     |         Creator          |
     +--------------------------+
     | +createProduct()         |
     +--------------------------+
          ^           ^
          |           |
+-----------------+ +-----------------+
|ConcreteProductA | |ConcreteProductB |
+-----------------+ +-----------------+
| +create()       | | +create()       |
+-----------------+ +-----------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Product is the interface representing the objects created by the factory method. Creator is the interface that declares the factory method createProduct(), which is implemented by ConcreteCreatorA and ConcreteCreatorB to create instances of ConcreteProductA and ConcreteProductB, respectively.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Factory Method Pattern is a versatile design pattern that allows flexible object creation and promotes code reusability. By implementing the factory method in subclasses, you can easily extend your application to include new product classes without changing the existing client code. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Factory Method Pattern in your projects can lead to more maintainable and scalable code, making it easier to add new classes and features in the future.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default FactoryDesignPattern;