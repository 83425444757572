import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function IteratorDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Iterator Design Pattern: Efficiently Accessing Collection Elements</h1>
      
      <p>The Iterator Design Pattern is a behavioral design pattern that provides a way to access elements of a collection sequentially without exposing the underlying structure of the collection. It decouples the client code from the collection's implementation, making it easy to iterate over different types of collections using a common interface. The Iterator pattern is particularly useful when you want to traverse complex data structures such as lists, trees, or graphs. In this tutorial, we will explore the Iterator Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Iterator Design Pattern:</b></h3>
			The Iterator Pattern involves two main components: Iterator and Aggregate. The Iterator defines the interface for accessing elements in the collection. The Aggregate defines the interface for creating an iterator that can traverse the collection.</p>
			
			<p><b>Implementing Iterator in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Iterator: defines the interface for accessing elements in the collection
interface Iterator<T> {
    boolean hasNext();
    T next();
}

// Aggregate: defines the interface for creating an iterator
interface Aggregate<T> {
    Iterator<T> createIterator();
}

// ConcreteIterator: implements the Iterator interface for a specific collection
class ConcreteIterator<T> implements Iterator<T> {
    private T[] collection;
    private int index;

    public ConcreteIterator(T[] collection) {
        this.collection = collection;
        this.index = 0;
    }

    public boolean hasNext() {
        return index < collection.length;
    }

    public T next() {
        if (hasNext()) {
            return collection[index++];
        }
        throw new NoSuchElementException("No more elements in the collection.");
    }
}

// ConcreteAggregate: implements the Aggregate interface for a specific collection
class ConcreteAggregate<T> implements Aggregate<T> {
    private T[] collection;

    public ConcreteAggregate(T[] collection) {
        this.collection = collection;
    }

    public Iterator<T> createIterator() {
        return new ConcreteIterator<>(collection);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Iterator in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Iterator: defines the interface for accessing elements in the collection
class Iterator:
    def has_next(self):
        pass

    def next(self):
        pass

# Aggregate: defines the interface for creating an iterator
class Aggregate:
    def create_iterator(self):
        pass

# ConcreteIterator: implements the Iterator interface for a specific collection
class ConcreteIterator(Iterator):
    def __init__(self, collection):
        self.collection = collection
        self.index = 0

    def has_next(self):
        return self.index < len(self.collection)

    def next(self):
        if self.has_next():
            element = self.collection[self.index]
            self.index += 1
            return element
        raise StopIteration("No more elements in the collection.")

# ConcreteAggregate: implements the Aggregate interface for a specific collection
class ConcreteAggregate(Aggregate):
    def __init__(self, collection):
        self.collection = collection

    def create_iterator(self):
        return ConcreteIterator(self.collection)`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Iterator Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class IteratorUsage {
    public static void main(String[] args) {
        String[] names = {"Alice", "Bob", "Charlie", "David"};
        Aggregate<String> collection = new ConcreteAggregate<>(names);
        Iterator<String> iterator = collection.createIterator();

        while (iterator.hasNext()) {
            System.out.println(iterator.next());
        }
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    names = ["Alice", "Bob", "Charlie", "David"]
    collection = ConcreteAggregate(names)
    iterator = collection.create_iterator()

    while iterator.has_next():
        print(iterator.next())
`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Iterator Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`   +----------------+       +------------------------+
   |    Iterator    |<>-----|   ConcreteIterator     |
   +----------------+       +------------------------+
   | +hasNext():    |       | +hasNext(): boolean    |
   |   boolean      |       | +next(): T             |
   | +next(): T     |       +------------------------+
   +----------------+
                         ^
                         |
                +--------------------+
                |     Aggregate      |
                +--------------------+
                | +createIterator(): |
                |   Iterator         |
                +--------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Iterator is the iterator interface, and ConcreteIterator is the concrete iterator. Aggregate is the aggregate interface, and ConcreteAggregate is the concrete aggregate.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Iterator Design Pattern provides a standard way to traverse elements in a collection without exposing the collection's implementation details. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Iterator Pattern can lead to more flexible and maintainable code, especially when dealing with complex collections and data structures.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default IteratorDesignPattern;
