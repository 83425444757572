import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/lupuk-home.png';
import './Header.css';

function Header() {
    return (
      <header className="header">
        <div className="logo">
          <img src={logo} alt="Logo" />
          <h1>Lupuk</h1>
          <h4>(Endless Learning)</h4>
        </div>
        <nav>
          <input type="checkbox" id="nav-toggle" className="nav-toggle" />
          <ul className="nav-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/about">About Me</Link></li>
          </ul>
          <label htmlFor="nav-toggle" className="nav-toggle-label">
            <span></span>
          </label>
        </nav>
      </header>
    );
  }  

export default Header;
