import React from 'react';
import sqlCheatSheet from '../../assets/images/sql-cheat-sheet.jpeg';
import './sql-cheatsheet.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';

function SqlCheatSheet() {
  return (
    <div className="sql-cheat-sheet-container">
      <h1>SQL CheatSheet</h1>
      {/* Your home page content */}
      <img src={sqlCheatSheet} alt="Sql CheatSheet" className='image-container' />
      <h2><b>SQL Basics</b></h2>
      <p><b>SELECT Statement:</b> Used to retrieve data from a database.
      <SyntaxHighlighter language="sql" style={nord}>{`SELECT column1, column2 FROM table_name;
SELECT * FROM table_name; -- Select all columns`}
      </SyntaxHighlighter>
      </p>
      <br/>
<p><b>FROM Clause:</b> Specifies the table(s) to query from.
<SyntaxHighlighter language="sql" style={nord}>{`SELECT * FROM table_name;`}
    </SyntaxHighlighter>
    </p>
    <br/>
		<p><b>WHERE Clause:</b> Filters the result set based on a condition.
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT * FROM table_name WHERE condition;`}
    </SyntaxHighlighter
    >
    </p>
		<p><b>ORDER BY Clause:</b> Sorts the result set based on one or more columns.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT * FROM table_name ORDER BY column_name ASC/DESC;`}
    </SyntaxHighlighter>
    <br/>

		<p><b>LIMIT Clause:</b>  Limits the number of rows returned.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT * FROM table_name LIMIT number;`}
    </SyntaxHighlighter>
    <br/>

		<p><b>DISTINCT Keyword:</b>  Removes duplicates from the result set.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT DISTINCT column FROM table_name;`}
    </SyntaxHighlighter>
    <br/>

		<p><b>IN Operator:</b>  Checks if a value matches any value in a list.</p>

    <SyntaxHighlighter language="sql" style={nord}>{`SELECT * FROM table_name WHERE column IN (value1, value2, value3);`}
    </SyntaxHighlighter>
    <br/>

		<p><b>BETWEEN Operator:</b>  Filters results within a specified range.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT * FROM table_name WHERE column BETWEEN value1 AND value2;`}
    </SyntaxHighlighter>
    <br/>

		<p><b>LIKE Operator:</b>  Matches a pattern using wildcards (% for zero or more characters, _ for a single character).</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT * FROM table_name WHERE column LIKE 'pattern%';`}
    </SyntaxHighlighter>
    <br/>

		<h2><b>SQL Joins</b></h2>

		<p><b>INNER JOIN:</b>  Returns rows with matching values in both tables.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT * FROM table1 INNER JOIN table2 ON table1.column = table2.column;`}
    </SyntaxHighlighter>
    <br/>

		<p><b>LEFT JOIN (OUTER JOIN): </b>  Returns all rows from the left table and matching rows from the right table.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT * FROM table1 LEFT JOIN table2 ON table1.column = table2.column;`}
    </SyntaxHighlighter>
    <br/>

		<p><b>RIGHT JOIN (OUTER JOIN): </b>  Returns all rows from the right table and matching rows from the left table.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT * FROM table1 RIGHT JOIN table2 ON table1.column = table2.column;`}
    </SyntaxHighlighter>
    <br/>

		<p><b>FULL JOIN (OUTER JOIN): </b>  Returns all rows when there is a match in either table.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT * FROM table1 FULL JOIN table2 ON table1.column = table2.column;`}
    </SyntaxHighlighter>
    <br/>

		<h2><b>Grouping and Aggregation</b></h2>

		<p><b>GROUP BY Clause: </b>  Groups rows based on one or more columns.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT column1, COUNT(column2) FROM table_name GROUP BY column1;`}
    </SyntaxHighlighter>
    <br/>

		<p><b>HAVING Clause: </b>  Filters grouped data based on a condition.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT column1, COUNT(column2) FROM table_name GROUP BY column1 HAVING COUNT(column2)`}
    </SyntaxHighlighter>
    <br/>

		<h2><b>Table Operations</b></h2>
		<p><b>CREATE TABLE Statement: </b>  Creates a new table with specified columns.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`CREATE TABLE table_name (
  column1 datatype1,
  column2 datatype2,
  ...
);`}
    </SyntaxHighlighter>
    <br/>

		<p><b>ALTER TABLE Statement: </b>  Modifies the structure of an existing table.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`ALTER TABLE table_name ADD column datatype;
ALTER TABLE table_name MODIFY column datatype;
ALTER TABLE table_name DROP column;`}
    </SyntaxHighlighter>
    <br/>

		<p><b>DROP TABLE Statement: </b>  Deletes an existing table.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`DROP TABLE table_name;`}
    </SyntaxHighlighter>
    <br/>

		<h2><b>Constraints</b></h2>
		<p><b>PRIMARY KEY Constraint: </b>  Uniquely identifies each record in a table.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`CREATE TABLE table_name (
  column1 datatype1 PRIMARY KEY,
  ...
);`}
    </SyntaxHighlighter>
    <br/>

		<p><b>FOREIGN KEY Constraint: </b>  Establishes a link between two tables based on a column.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`CREATE TABLE table1 (
      column1 datatype1 PRIMARY KEY,
      column2 datatype2,
      FOREIGN KEY (column2) REFERENCES table2(column2)
);`}
    </SyntaxHighlighter>
    <br/>

		<p><b>UNIQUE Constraint: </b>  Ensures that all values in a column are unique.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`CREATE TABLE table_name (
      column1 datatype1 UNIQUE,
      ...
);`}
    </SyntaxHighlighter>
    <br/>

		<p><b>CHECK Constraint: </b>  Ensures that column values meet a specified condition.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`CREATE TABLE table_name (
      column1 datatype1,
      column2 datatype2 CHECK (column2 > 0),
      ...
);`}
    </SyntaxHighlighter>
    <br/>

		<h2><b>Miscellaneous</b></h2>
		<p><b>Aggregate Functions: </b>  Perform calculations on a set of values and return a single value.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT COUNT(column) FROM table_name;
SELECT SUM(column) FROM table_name;
SELECT AVG(column) FROM table_name;
SELECT MIN(column) FROM table_name;
SELECT MAX(column) FROM table_name;`}
    </SyntaxHighlighter>
    <br/>

		<p><b>NULL Values: </b>  Represents missing or unknown data.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT * FROM table_name WHERE column IS NULL;
SELECT * FROM table_name WHERE column IS NOT NULL;`}
    </SyntaxHighlighter>
    <br/>

		<p><b>Subqueries:  </b>  Queries inside other queries.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`SELECT column1 FROM table_name WHERE column2 IN (SELECT column2 FROM table2);`}
    </SyntaxHighlighter>
    <br/>

		<p><b>Views: </b>  Virtual tables based on the result of a SELECT statement.</p>
    <SyntaxHighlighter language="sql" style={nord}>{`CREATE VIEW view_name AS SELECT column1, column2 FROM table_name WHERE condition;`}
    </SyntaxHighlighter>

    </div>
  );
}

export default SqlCheatSheet;
