import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function MediatorDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Mediator Design Pattern: Centralized Communication</h1>
      
      <p>The Mediator Design Pattern is a behavioral design pattern that promotes loose coupling among objects by centralizing communication between them. It encapsulates how objects interact with each other, reducing direct dependencies and making the system more maintainable. The Mediator acts as a communication hub, coordinating the interactions between the participating objects. In this tutorial, we will explore the Mediator Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Mediator Design Pattern:</b></h3>
			The Mediator Pattern involves several components: Mediator, Colleague, and ConcreteColleague. The Mediator interface defines communication methods for Colleagues to use. Colleagues interact with each other through the Mediator, and ConcreteColleague classes implement specific behavior.</p>
			
			<p><b>Implementing Mediator in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Mediator: defines the interface for communication between Colleagues
interface Mediator {
    void sendMessage(String message, Colleague colleague);
}

// ConcreteMediator: implements the Mediator interface and manages Colleague interactions
class ConcreteMediator implements Mediator {
    private Colleague colleagueA;
    private Colleague colleagueB;

    public void setColleagueA(Colleague colleague) {
        this.colleagueA = colleague;
    }

    public void setColleagueB(Colleague colleague) {
        this.colleagueB = colleague;
    }

    public void sendMessage(String message, Colleague colleague) {
        if (colleague == colleagueA) {
            colleagueB.receiveMessage(message);
        } else if (colleague == colleagueB) {
            colleagueA.receiveMessage(message);
        }
    }
}

// Colleague: defines the interface for interaction with the Mediator
abstract class Colleague {
    protected Mediator mediator;

    public Colleague(Mediator mediator) {
        this.mediator = mediator;
    }

    public abstract void sendMessage(String message);
    public abstract void receiveMessage(String message);
}

// ConcreteColleagueA: implements the Colleague interface
class ConcreteColleagueA extends Colleague {
    public ConcreteColleagueA(Mediator mediator) {
        super(mediator);
    }

    public void sendMessage(String message) {
        mediator.sendMessage(message, this);
    }

    public void receiveMessage(String message) {
        System.out.println("ConcreteColleagueA received: " + message);
    }
}

// ConcreteColleagueB: implements the Colleague interface
class ConcreteColleagueB extends Colleague {
    public ConcreteColleagueB(Mediator mediator) {
        super(mediator);
    }

    public void sendMessage(String message) {
        mediator.sendMessage(message, this);
    }

    public void receiveMessage(String message) {
        System.out.println("ConcreteColleagueB received: " + message);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Mediator in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Mediator: defines the interface for communication between Colleagues
class Mediator:
    def send_message(self, message, colleague):
        pass

# ConcreteMediator: implements the Mediator interface and manages Colleague interactions
class ConcreteMediator(Mediator):
    def set_colleague_a(self, colleague):
        self.colleague_a = colleague

    def set_colleague_b(self, colleague):
        self.colleague_b = colleague

    def send_message(self, message, colleague):
        if colleague == self.colleague_a:
            self.colleague_b.receive_message(message)
        elif colleague == self.colleague_b:
            self.colleague_a.receive_message(message)

# Colleague: defines the interface for interaction with the Mediator
class Colleague:
    def __init__(self, mediator):
        self.mediator = mediator

    def send_message(self, message):
        pass

    def receive_message(self, message):
        pass

# ConcreteColleagueA: implements the Colleague interface
class ConcreteColleagueA(Colleague):
    def send_message(self, message):
        self.mediator.send_message(message, self)

    def receive_message(self, message):
        print(f"ConcreteColleagueA received: {message}")

# ConcreteColleagueB: implements the Colleague interface
class ConcreteColleagueB(Colleague):
    def send_message(self, message):
        self.mediator.send_message(message, self)

    def receive_message(self, message):
        print(f"ConcreteColleagueB received: {message}")`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Mediator Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class MediatorUsage {
    public static void main(String[] args) {
        ConcreteMediator mediator = new ConcreteMediator();

        ConcreteColleagueA colleagueA = new ConcreteColleagueA(mediator);
        ConcreteColleagueB colleagueB = new ConcreteColleagueB(mediator);

        mediator.setColleagueA(colleagueA);
        mediator.setColleagueB(colleagueB);

        colleagueA.sendMessage("Hello from Colleague A!");
        colleagueB.sendMessage("Hi from Colleague B!");
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    mediator = ConcreteMediator()

    colleague_a = ConcreteColleagueA(mediator)
    colleague_b = ConcreteColleagueB(mediator)

    mediator.set_colleague_a(colleague_a)
    mediator.set_colleague_b(colleague_b)

    colleague_a.send_message("Hello from Colleague A!")
    colleague_b.send_message("Hi from Colleague B!")`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Mediator Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`   +-------------------+      +----------------------+
   |      Mediator     |<>----|   ConcreteMediator   |
   +-------------------+      +----------------------+
   | +sendMessage():   |      | +sendMessage(): void |
   |    void           |      | +setColleagueA():    |
   +-------------------+      |    void              |
                               | +setColleagueB():    |
                               |    void              |
                               +----------------------+
                                             ^
                                             |
                        +--------------------|----------------------+
                        |                    |                      |
           +-----------------------+ +-----------------------+
           |  ConcreteColleagueA  | |   ConcreteColleagueB  |
           +-----------------------+ +-----------------------+
           | +sendMessage(): void | | +sendMessage(): void |
           | +receiveMessage():   | | +receiveMessage():   |
           |   void              | |   void              |
           +-----------------------+ +-----------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Mediator is the mediator interface, and ConcreteMediator is the concrete mediator that manages Colleague interactions. ConcreteColleagueA and ConcreteColleagueB are the concrete colleagues that communicate with each other through the Mediator.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Mediator Design Pattern promotes loose coupling by centralizing communication between objects. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Mediator Pattern can lead to a more maintainable and flexible codebase, especially when dealing with complex interactions between objects in a system.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default MediatorDesignPattern;
