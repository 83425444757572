import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-principle.css';
import SoftwareDesignPrincipleTopicsList from './SoftwareDesignPrincipleTopicsList'

function YouArenotGonnaNeedItPrinciple() {
  return (
    <div className="software-design-principle-container">
      <h1>YAGNI (You Aren't Gonna Need It) Principle</h1>
      
      <p>The YAGNI (You Aren't Gonna Need It) principle advises against implementing functionality until it is necessary. It prevents overengineering and unnecessary complexity.
			</p>
			<p><b>Example in Java:</b>Consider adding an additional feature to a class that is not currently required:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Not following YAGNI
public class ShoppingCart {
    // ... existing methods and attributes ...

    public double calculateTotalWithDiscount(double totalPrice, String discountType) {
        // Complex discount calculations based on discountType
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow YAGNI, we only implement the required functionality:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Following YAGNI
public class ShoppingCart {
    // ... existing methods and attributes ...

    // Other necessary methods
}`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Example in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Python
# Not following YAGNI
class ShoppingCart:
    # ... existing methods and attributes ...

    def calculate_total_with_discount(self, total_price, discount_type):
        # Complex discount calculations based on discount_type`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow YAGNI in Python, we only implement the required functionality:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Following YAGNI
class ShoppingCart:
    # ... existing methods and attributes ...

    # Other necessary methods`}
        </SyntaxHighlighter>
      <br/>
      <h2><b>Conclusion</b></h2>
      <p>In this tutorial, we have explored essential design principles in software system design, including SOLID (Single Responsibility, Open-Closed, Liskov Substitution, Interface Segregation, Dependency Inversion), DRY (Don't Repeat Yourself), KISS (Keep It Simple, Stupid), and YAGNI (You Aren't Gonna Need It). By adhering to these principles, developers can create well-structured, maintainable, and flexible software systems. The examples in both Java and Python, along with the diagrams, have illustrated the practical application of these principles. By incorporating these design principles into your software development practices, you can build high-quality and reliable software systems.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <SoftwareDesignPrincipleTopicsList/>
      </div>
    </div>
  );
}

export default YouArenotGonnaNeedItPrinciple;
