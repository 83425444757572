import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-principle.css';
import SoftwareDesignPrincipleTopicsList from './SoftwareDesignPrincipleTopicsList'

function KeepItSimpleStupidPrinciple() {
  return (
    <div className="software-design-principle-container">
      <h1>KISS (Keep It Simple, Stupid) Principle</h1>
      <p>The KISS (Keep It Simple, Stupid) principle suggests that simplicity is preferable to complexity. It encourages developers to choose the simplest solution that meets the requirements.
			</p>
			<p><b>Example in Java:</b>Consider a complex implementation of a method to add two numbers.</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Not following KISS
public int add(int a, int b) {
    int result = 0;
    for (int i = 0; i < a; i++) {
        result++;
    }
    for (int i = 0; i < b; i++) {
        result++;
    }
    return result;
}`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow KISS, we simplify the method to directly return the sum:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Following KISS
public int add(int a, int b) {
    return a + b;
}`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Example in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Python
# Not following KISS
def add(a, b):
    result = 0
    for i in range(a):
        result += 1
    for i in range(b):
        result += 1
    return result`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow KISS in Python, we simplify the function to directly return the sum:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Following KISS
def add(a, b):
    return a + b`}
        </SyntaxHighlighter>
      <br/>
      <h2><b>Conclusion</b></h2>
      <p>In this tutorial, we have explored essential design principles in software system design, including SOLID (Single Responsibility, Open-Closed, Liskov Substitution, Interface Segregation, Dependency Inversion), DRY (Don't Repeat Yourself), KISS (Keep It Simple, Stupid), and YAGNI (You Aren't Gonna Need It). By adhering to these principles, developers can create well-structured, maintainable, and flexible software systems. The examples in both Java and Python, along with the diagrams, have illustrated the practical application of these principles. By incorporating these design principles into your software development practices, you can build high-quality and reliable software systems.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <SoftwareDesignPrincipleTopicsList/>
      </div>
    </div>
  );
}

export default KeepItSimpleStupidPrinciple;
