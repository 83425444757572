import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function VisitorDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Visitor Design Pattern: Separating Operations from Objects</h1>
      
      <p>The Visitor Design Pattern is a behavioral design pattern that allows adding new operations to objects without modifying their structure. It achieves this by separating the algorithms (operations) from the objects on which they operate. The Visitor Pattern uses double dispatch to call the appropriate visitor method based on both the object type and the visitor type. This pattern promotes flexibility and extensibility in complex object structures. In this tutorial, we will explore the Visitor Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Visitor Design Pattern:</b></h3>
			The Visitor Pattern involves four main components: Visitor, ConcreteVisitor, Element, and ConcreteElement. The Visitor declares the visit methods for different ConcreteElements. The ConcreteVisitor implements these visit methods with specific behavior. The Element defines the accept method that accepts a visitor, and the ConcreteElement implements the accept method.</p>
			
			<p><b>Implementing Visitor in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Visitor: declares the interface for visiting ConcreteElements
interface Visitor {
    void visit(ConcreteElementA element);
    void visit(ConcreteElementB element);
}

// ConcreteVisitorA: implements the Visitor interface with specific behavior
class ConcreteVisitorA implements Visitor {
    public void visit(ConcreteElementA element) {
        System.out.println("ConcreteVisitorA is visiting ConcreteElementA");
    }

    public void visit(ConcreteElementB element) {
        System.out.println("ConcreteVisitorA is visiting ConcreteElementB");
    }
}

// ConcreteVisitorB: implements the Visitor interface with specific behavior
class ConcreteVisitorB implements Visitor {
    public void visit(ConcreteElementA element) {
        System.out.println("ConcreteVisitorB is visiting ConcreteElementA");
    }

    public void visit(ConcreteElementB element) {
        System.out.println("ConcreteVisitorB is visiting ConcreteElementB");
    }
}

// Element: declares the interface for accepting a visitor
interface Element {
    void accept(Visitor visitor);
}

// ConcreteElementA: implements the Element interface
class ConcreteElementA implements Element {
    public void accept(Visitor visitor) {
        visitor.visit(this);
    }
}

// ConcreteElementB: implements the Element interface
class ConcreteElementB implements Element {
    public void accept(Visitor visitor) {
        visitor.visit(this);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Visitor in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Visitor: declares the interface for visiting ConcreteElements
class Visitor:
    def visit_concrete_element_a(self, element):
        pass

    def visit_concrete_element_b(self, element):
        pass

# ConcreteVisitorA: implements the Visitor interface with specific behavior
class ConcreteVisitorA(Visitor):
    def visit_concrete_element_a(self, element):
        print("ConcreteVisitorA is visiting ConcreteElementA")

    def visit_concrete_element_b(self, element):
        print("ConcreteVisitorA is visiting ConcreteElementB")

# ConcreteVisitorB: implements the Visitor interface with specific behavior
class ConcreteVisitorB(Visitor):
    def visit_concrete_element_a(self, element):
        print("ConcreteVisitorB is visiting ConcreteElementA")

    def visit_concrete_element_b(self, element):
        print("ConcreteVisitorB is visiting ConcreteElementB")

# Element: declares the interface for accepting a visitor
class Element:
    def accept(self, visitor):
        pass

# ConcreteElementA: implements the Element interface
class ConcreteElementA(Element):
    def accept(self, visitor):
        visitor.visit_concrete_element_a(self)

# ConcreteElementB: implements the Element interface
class ConcreteElementB(Element):
    def accept(self, visitor):
        visitor.visit_concrete_element_b(self)`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Visitor Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class VisitorUsage {
    public static void main(String[] args) {
        Element elementA = new ConcreteElementA();
        Element elementB = new ConcreteElementB();

        Visitor visitorA = new ConcreteVisitorA();
        Visitor visitorB = new ConcreteVisitorB();

        elementA.accept(visitorA);
        elementA.accept(visitorB);

        elementB.accept(visitorA);
        elementB.accept(visitorB);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    element_a = ConcreteElementA()
    element_b = ConcreteElementB()

    visitor_a = ConcreteVisitorA()
    visitor_b = ConcreteVisitorB()

    element_a.accept(visitor_a)
    element_a.accept(visitor_b)

    element_b.accept(visitor_a)
    element_b.accept(visitor_b)`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Visitor Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`      +-----------------+      +----------------------+
      |     Visitor     |<>----|   ConcreteVisitorA   |
      +-----------------+      +----------------------+
      | +visit(): void  |      | +visit(): void       |
      +-----------------+      | +visit(): void       |
                               +----------------------+
                                          ^
                                          |
                                 +---------------------+
                                 |   ConcreteVisitorB   |
                                 +---------------------+
                                 | +visit(): void      |
                                 | +visit(): void      |
                                 +---------------------+
      +-----------------+          +-----------------------+
      |     Element     |      <>--|   ConcreteElementA    |
      +-----------------+          +-----------------------+
      | +accept(): void |          | +accept(): void       |
      +-----------------+          +-----------------------+
                       ^                                    ^
                       |                                    |
              +-----------------+                +-----------------------+
              | ConcreteElementB|                |   ConcreteElementB    |
              +-----------------+                +-----------------------+
              | +accept(): void |                | +accept(): void       |
              +-----------------+                +-----------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Visitor is the visitor interface, and ConcreteVisitorA and ConcreteVisitorB are the concrete visitors. Element is the element interface, and ConcreteElementA and ConcreteElementB are the concrete elements.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Visitor Design Pattern enables the separation of operations from objects, making it easy to add new operations without modifying object structures. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Visitor Pattern can lead to a more extensible and maintainable codebase, especially when dealing with complex object structures with multiple operations.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default VisitorDesignPattern;
