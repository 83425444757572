import React from 'react';
import { Link } from 'react-router-dom';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function SoftwareDesignPattern() {
    return (
        <div className='software-design-pattern-container'>
            <h1>Software Design Pattern</h1>
            <p>Software design patterns are proven solutions to common software design problems that have emerged from the collective experience of software developers. These patterns provide a structured approach to solving recurring design challenges, promoting code reusability, maintainability, and scalability. In this tutorial, we will explore some of the most widely used software design patterns with detailed explanations, code examples in Java and Python, and diagram illustrations.</p>
            
            <p><h3><b>☐ 1. Creational Design Patterns</b></h3></p>
            <p>Creational design patterns are a category of software design patterns that deal with object creation. They provide ways to create objects in a flexible, reusable, and controlled manner, promoting decoupling and code organization during the object instantiation process.</p>

            <ul>
                <p><b>Creational design patterns are particularly useful when:</b></p>
                <li>The system should be independent of how its objects are created, composed, and represented.</li>
                <li>The system needs to be extensible with minimal changes to the existing codebase.</li>
                <li>The process of creating objects should be hidden from the client code.</li>
                <li>Objects need to be created based on certain conditions or configurations.</li>
                <br />
                <p><b>There are several creational design patterns, including:</b></p>
                <p>▸<b><Link to="/singleton-design-pattern">Singleton Design Pattern</Link></b>: Ensures that a class has only one instance and provides a global point of access to that instance.</p>
                
                <p>▸<b><Link to="/factory-design-pattern">Factory Design Pattern</Link></b>: Provides an interface for creating objects but allows subclasses to decide which class to instantiate.</p>

                <p>▸<b><Link to="/abstract-design-pattern">Abstract Design Pattern</Link></b>: Provides an interface for creating families of related or dependent objects without specifying their concrete classes.</p>

                <p>▸<b><Link to="/builder-design-pattern">Builder Design Pattern</Link></b>: Separates the construction of a complex object from its representation, allowing the same construction process to create different representations.</p>

                <p>▸<b><Link to="/prototype-design-pattern">Prototype Design Pattern</Link></b>: Creates new objects by copying an existing object, thereby avoiding the overhead of creating objects from scratch.</p>

                <p>▸<b><Link to="/object-design-pattern">Object Design Pattern</Link></b>: Maintains a pool of reusable objects that can be borrowed and returned, reducing the overhead of object creation.</p>
            </ul>
            <p>Each creational design pattern has its unique purpose and use cases, making it essential for developers to understand when and how to apply them effectively. By using creational design patterns, developers can achieve better code organization, maintainability, and flexibility in the object creation process.</p>

          
            <p><h3><b>☐ 2. Structural Design Patterns</b></h3></p>
            <p>Structural design patterns are a category of software design patterns that focus on how classes and objects are composed to form larger structures, such as complex objects or subsystems. These patterns help define relationships between classes and provide solutions to represent and manage these relationships effectively. Structural design patterns do not deal with the creation of objects like creational patterns, but they concentrate on how these objects work together.</p>
            <p><b>The key goals of structural design patterns are to:</b></p>
            <ul>
                <li>Simplify the design by identifying simple ways to realize relationships between entities.</li>
                <li>Promote code reusability and flexibility by composing objects instead of using inheritance.</li>
                <li>Encapsulate and manage complex class relationships within the patterns.</li>
                <li>Ensure that changes in one part of the system do not affect other parts of the system.</li>
                <br />
                <p><b>There are several structural design patterns, including:</b></p>
                <p>▸<b><Link to="/adapter-design-pattern">Adapter Design Pattern</Link></b>: Converts the interface of a class into another interface that clients expect, allowing classes with incompatible interfaces to work together.</p>

                <p>▸<b><Link to="/decorator-design-pattern">Decorator Design Pattern</Link></b>: Dynamically adds responsibilities or behaviors to objects without modifying their code, providing a flexible alternative to subclassing.</p>

                <p>▸<b><Link to="/proxy-design-pattern">Proxy Design Pattern</Link></b>: Acts as an intermediary for accessing an object, controlling its access, or providing additional functionality.</p>

                <p>▸<b><Link to="/composite-design-pattern">Composite Design Pattern</Link> </b>: Treats individual objects and compositions of objects uniformly, allowing clients to treat single objects and compositions uniformly.</p>

                <p>▸<b><Link to="/bridge-design-pattern">Bridge Design Pattern</Link> </b>: Decouples an abstraction from its implementation, allowing both to vary independently.</p>

                <p>▸<b><Link to="/facade-design-pattern">Facade Design Pattern</Link> </b>: Provides a unified interface to a set of interfaces in a subsystem, simplifying the usage of the subsystem.</p>

                <p>▸<b><Link to="/flyweight-design-pattern">Flyweight Design Pattern</Link> </b>: Shares objects to support large numbers of fine-grained objects efficiently.</p>

            </ul>

            <p>Structural design patterns are powerful tools to improve code organization, maintainability, and scalability. They help developers design systems in a way that allows for easy integration of new functionalities and reduces the impact of changes on the existing codebase. Understanding the various structural design patterns and their appropriate use cases is crucial for writing efficient and maintainable software systems.</p>

            <p><h3><b>☐ 3. Behavioral Design Patterns</b></h3></p>
            <p>Behavioral design patterns are a category of software design patterns that focus on how objects and classes interact and distribute responsibilities among them. These patterns help define the communication between different objects and the flow of control in the application. Behavioral patterns are concerned with algorithms, communication, and the assignment of responsibilities between objects.</p>

            <p><b>The key goals of behavioral design patterns are to:</b></p>
            <ul>
                <li>Encapsulate algorithms and behaviors in a way that they can be reused and interchanged without affecting the clients.</li>
                <li>Promote loose coupling between objects by allowing them to interact without having detailed knowledge of each other's implementations.</li>
                <li>Provide a clear and understandable way to organize the responsibilities of objects.</li>
                <br />

                <p><b>There are several behavioral design patterns, including:</b></p>
                <p>▸<b><Link to="/observer-design-pattern">Observer Design Pattern</Link></b>: Defines a dependency between objects so that when one object changes state, its dependents (observers) are notified and updated automatically.</p>

                <p>▸<b><Link to="/strategy-design-pattern">Strategy Design Pattern</Link></b>: Allows you to define a family of algorithms, encapsulate each one separately, and make them interchangeable at runtime.</p>

                <p>▸<b><Link to="/template-design-pattern">Template Design Pattern</Link></b>: Defines the structure of an algorithm in a method, but lets subclasses override specific steps of the algorithm without changing its structure.</p>

                <p>▸<b><Link to="/command-design-pattern">Command Design Pattern</Link></b>: Encapsulates a request as an object, thereby allowing parameterization of clients with different requests, queuing of requests, and logging of the requests.</p>

                <p>▸<b><Link to="/chain-design-pattern">Chain of Responsibility Design Pattern</Link></b>: Allows multiple objects to handle a request without the sender having explicit knowledge of which object will handle it.</p>

                <p>▸<b><Link to="/state-design-pattern">State Design Pattern</Link></b>: Allows an object to change its behavior when its internal state changes, and the object will appear to change its class.</p>

                <p>▸<b><Link to="/mediator-design-pattern">Mediator Design Pattern</Link></b>: Centralizes complex communications and interactions between objects in a system by introducing a mediator object.</p>

                <p>▸<b><Link to="/visitor-design-pattern">Visitor Design Pattern</Link></b>: Allows you to add further operations to objects without having to modify them.</p>

                <p>▸<b><Link to="/interceptor-design-pattern">Interceptor Design Pattern</Link></b>: Provides a way to evaluate language grammar or expressions.</p>

                <p>▸<b><Link to="/iterator-design-pattern">Iterator Design Pattern</Link></b>: Provides a way to access elements of a collection sequentially without exposing its underlying representation.</p>

                <p>▸<b><Link to="/memento-design-pattern">Memento Design Pattern</Link></b>: That allows an object's state to be captured and stored externally, so it can be restored to that state later without exposing its internal structure.</p>
                
                <p>Behavioral design patterns are essential for creating flexible, maintainable, and extensible software systems. They help developers manage the interactions between objects and components effectively, making the code more readable and easier to maintain. By understanding and applying behavioral design patterns, developers can enhance the overall architecture and design of their software.</p>

            </ul>
            <h2><b>Conclusion</b></h2>
            <p>In this tutorial, we have explored various design patterns with easy-to-understand explanations, diagrams, and examples in both Java and Python. These design patterns play a crucial role in software development by providing reusable solutions to common design problems. By implementing these patterns in your projects, you can improve the flexibility, maintainability, and scalability of your software systems.</p>
            <div>
            <hr/>
                <DesignPatternTopicsList />
            </div>
        </div>
    );
}

export default SoftwareDesignPattern;
