import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function CompositeDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Composite Design Pattern: Treating Individual Objects and Compositions Uniformly</h1>
      
      <p>The Composite Design Pattern is a structural design pattern that allows you to treat individual objects and compositions of objects uniformly. It composes objects into tree-like structures to represent part-whole hierarchies. This pattern allows clients to interact with individual objects or groups of objects in the same way. The Composite Pattern simplifies the client code by abstracting the complexities of the underlying hierarchical structure. In this tutorial, we will explore the Composite Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Composite Design Pattern:</b></h3>
			The Composite Pattern composes objects into tree-like structures called "composites" and "leaves." A composite is an object that contains child components, which can be either other composites or leaves (individual objects). Both composites and leaves implement a common interface, enabling clients to treat them uniformly. This pattern follows the "open-closed principle" as it allows new components to be added to the hierarchy without affecting existing code.</p>
			
			<p><b>Implementing Composite in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`import java.util.ArrayList;
import java.util.List;

// Component interface: the common interface for both the Composite and Leaf classes
interface Shape {
    void draw();
}

// Leaf: represents individual objects
class Circle implements Shape {
    public void draw() {
        System.out.println("Drawing Circle");
    }
}

// Composite: represents a group of objects (compositions)
class Drawing implements Shape {
    private List<Shape> shapes = new ArrayList<>();

    public void add(Shape shape) {
        shapes.add(shape);
    }

    public void remove(Shape shape) {
        shapes.remove(shape);
    }

    public void draw() {
        for (Shape shape : shapes) {
            shape.draw();
        }
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Composite in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Component interface: the common interface for both the Composite and Leaf classes
class Shape:
    def draw(self):
        pass

# Leaf: represents individual objects
class Circle(Shape):
    def draw(self):
        print("Drawing Circle")

# Composite: represents a group of objects (compositions)
class Drawing(Shape):
    def __init__(self):
        self.shapes = []

    def add(self, shape):
        self.shapes.append(shape)

    def remove(self, shape):
        self.shapes.remove(shape)

    def draw(self):
        for shape in self.shapes:
            shape.draw()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Composite Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class CompositeUsage {
    public static void main(String[] args) {
        Circle circle1 = new Circle();
        Circle circle2 = new Circle();

        Drawing drawing = new Drawing();
        drawing.add(circle1);
        drawing.add(circle2);

        drawing.draw();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    circle1 = Circle()
    circle2 = Circle()

    drawing = Drawing()
    drawing.add(circle1)
    drawing.add(circle2)

    drawing.draw()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Composite Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`       +-------------------+
       |        Shape      |
       +-------------------+
       | +draw(): void    |
       +-------------------+
                  ^
                  |
    +---------------------------+
    |       Drawing           |
    +---------------------------+
    | - shapes: List<Shape>  |
    +---------------------------+
    | +add(shape: Shape): void|
    | +remove(shape: Shape): void|
    | +draw(): void          |
    +---------------------------+
                  ^
                  |
          +-------------------+
          |       Circle      |
          +-------------------+
          | +draw(): void    |
          +-------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Shape is the interface representing both the Leaf (Circle) and Composite (Drawing) classes. Drawing is the composite that holds a list of Shape objects (compositions), and Circle is the individual object (leaf).</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Composite Design Pattern allows you to treat individual objects and compositions uniformly, simplifying client code and providing flexibility in handling complex hierarchies. By abstracting the complexities of hierarchical structures, this pattern provides a consistent interface for interacting with both individual objects and groups of objects. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Composite Pattern can lead to more maintainable and scalable code, especially when dealing with hierarchical structures with varying levels of complexity.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default CompositeDesignPattern;
