import React from 'react';

function AboutMe() {
  return (
    <div>
      <h1>About me</h1>
      {/* Your home page content */}
      <p>
      Welcome to Lupuk - Endless Learning!
      <br/>
      <p>Here at Lupuk, we offer a comprehensive online tutorial that covers exciting topics in software engineering. Whether you're eager to create strong and reliable systems or solve common design challenges, we've got you covered every step of the way. Our lessons are designed to be easy-to-follow, with fun examples and practical exercises that make learning a delightful experience. Embrace your curiosity and unleash your potential as you explore a world of possibilities with us. At Lupuk, we believe that learning never stops, and together, we can soar to new heights of knowledge and success. So, let's begin this amazing journey together and unlock the limitless potential within you!</p>
				<br/>
				Happy learning @Lupuk!
      </p>
    </div>
  );
}

export default AboutMe;
