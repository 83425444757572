import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function ChainDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Chain of Responsibility Design Pattern: Handling Requests in a Chain</h1>
      
      <p>The Chain of Responsibility Design Pattern is a behavioral design pattern that decouples the sender of a request from its receiver by allowing more than one object to handle the request. The pattern creates a chain of handler objects, where each handler decides whether to process the request or pass it to the next handler in the chain. This approach promotes flexibility and reduces the coupling between the sender and the receiver. In this tutorial, we will explore the Chain of Responsibility Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Chain of Responsibility Design Pattern:</b></h3>
			The Chain of Responsibility Pattern consists of a series of handler objects connected as a chain. Each handler has a reference to the next handler in the chain. When a request is made, it travels through the chain until a handler processes it or until the end of the chain is reached. This allows multiple handlers to have a chance to handle the request.</p>
			
			<p><b>Implementing Chain of Responsibility in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Handler: declares the interface for handling requests
interface Handler {
    void setNext(Handler handler);
    void handleRequest(Request request);
}

// ConcreteHandlerA: implements the Handler interface and handles specific types of requests
class ConcreteHandlerA implements Handler {
    private Handler nextHandler;

    public void setNext(Handler handler) {
        this.nextHandler = handler;
    }

    public void handleRequest(Request request) {
        if (request.getType().equals("TypeA")) {
            System.out.println("ConcreteHandlerA handles the request of TypeA");
        } else if (nextHandler != null) {
            nextHandler.handleRequest(request);
        } else {
            System.out.println("No handler available for this request");
        }
    }
}

// ConcreteHandlerB: implements the Handler interface and handles specific types of requests
class ConcreteHandlerB implements Handler {
    private Handler nextHandler;

    public void setNext(Handler handler) {
        this.nextHandler = handler;
    }

    public void handleRequest(Request request) {
        if (request.getType().equals("TypeB")) {
            System.out.println("ConcreteHandlerB handles the request of TypeB");
        } else if (nextHandler != null) {
            nextHandler.handleRequest(request);
        } else {
            System.out.println("No handler available for this request");
        }
    }
}

// Request: defines the request to be handled
class Request {
    private String type;

    public Request(String type) {
        this.type = type;
    }

    public String getType() {
        return type;
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Chain of Responsibility in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Handler: declares the interface for handling requests
class Handler:
    def set_next(self, handler):
        pass

    def handle_request(self, request):
        pass

# ConcreteHandlerA: implements the Handler interface and handles specific types of requests
class ConcreteHandlerA(Handler):
    def set_next(self, handler):
        self.next_handler = handler

    def handle_request(self, request):
        if request.get_type() == "TypeA":
            print("ConcreteHandlerA handles the request of TypeA")
        elif self.next_handler is not None:
            self.next_handler.handle_request(request)
        else:
            print("No handler available for this request")

# ConcreteHandlerB: implements the Handler interface and handles specific types of requests
class ConcreteHandlerB(Handler):
    def set_next(self, handler):
        self.next_handler = handler

    def handle_request(self, request):
        if request.get_type() == "TypeB":
            print("ConcreteHandlerB handles the request of TypeB")
        elif self.next_handler is not None:
            self.next_handler.handle_request(request)
        else:
            print("No handler available for this request")

# Request: defines the request to be handled
class Request:
    def __init__(self, type):
        self.type = type

    def get_type(self):
        return self.type`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Chain of Responsibility Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class ChainOfResponsibilityUsage {
    public static void main(String[] args) {
        Handler handlerA = new ConcreteHandlerA();
        Handler handlerB = new ConcreteHandlerB();

        handlerA.setNext(handlerB);

        Request request1 = new Request("TypeA");
        Request request2 = new Request("TypeB");
        Request request3 = new Request("TypeC");

        handlerA.handleRequest(request1);
        handlerA.handleRequest(request2);
        handlerA.handleRequest(request3);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    handlerA = ConcreteHandlerA()
    handlerB = ConcreteHandlerB()

    handlerA.set_next(handlerB)

    request1 = Request("TypeA")
    request2 = Request("TypeB")
    request3 = Request("TypeC")

    handlerA.handle_request(request1)
    handlerA.handle_request(request2)
    handlerA.handle_request(request3)`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Chain of Responsibility Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`   +-------------------+      +---------------------+
   |       Handler     |<>----|   ConcreteHandlerA  |
   +-------------------+      +---------------------+
   | +setNext(): void  |      | +handleRequest():   |
   | +handleRequest(): |      |    void             |
   +-------------------+      +---------------------+
               ^                          ^
               |                          |
               v                          |
   +-------------------+                  |
   |   ConcreteHandlerB|                  |
   +-------------------+                  |
   | +setNext(): void  |                  |
   | +handleRequest(): |------------------+
   |    void           |
   +-------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Handler is the handler interface, and ConcreteHandlerA and ConcreteHandlerB are the concrete handlers. Each handler can handle specific types of requests and pass the request to the next handler in the chain if needed.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Chain of Responsibility Design Pattern allows multiple objects to handle a request in a chain, reducing coupling and providing flexibility in the system. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Chain of Responsibility Pattern can lead to a more organized and maintainable codebase, especially when dealing with various request handling scenarios in a flexible manner.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default ChainDesignPattern;
