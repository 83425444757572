import React from 'react';
import { Link } from 'react-router-dom';
import './software-system-topics-list.css';

function SoftwareSystemDesignTopicsList() {
  const topics = [
   
    { id: 1, name: 'Software System Design', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/software-system-design' },
    { id: 2, name: 'Software Architectural Patterns', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/software-architectural-patterns' },
    { id: 3, name: 'Software Design Principles', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/software-design-principles' },
    { id: 4, name: 'Software Design Patterns', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/software-design-pattern' },
    /*
    { id: 5, name: 'Software Component-Based Design', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/software-component-based-design' },
    { id: 6, name: 'Software Database Design', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/software-database-design' },
    { id: 7, name: 'User Interface (UI) Design', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/user-interface-design' },
    { id: 8, name: 'System Integration Design', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/system-integration-design' },
    { id: 9, name: 'Scalability and Performance Design', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/scalability-and-performance-design' },*/
    
   
    
    
    // Add more topics here
  ];

  return (
    <div className="topics-list-container">
      <h2>Software System Design Topics List</h2>
      <div className="topics-list">
        {topics.map(topic => (
          <Link key={topic.id} to={topic.route} className="topic-item">
            <img src={topic.imageUrl} alt={topic.name} />
            <p>{topic.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SoftwareSystemDesignTopicsList;
