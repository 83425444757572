import React from 'react';
import { Link } from 'react-router-dom';
import './topics-list.css';

function TopicsList() {
  const topics = [
    { id: 0, name: 'Software Design Principles', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/software-design-principles' },
    { id: 1, name: 'Sofware System Design', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/software-system-design' },
    { id: 2, name: 'Software Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/software-design-pattern' },
    { id: 3, name: 'PySpark Performance', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance' },
    { id: 4, name: 'SQL CheatSheet', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/sql-cheatsheet' },
    //{ id: 5, name: 'Advance SQL CheatSheet', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql' },
    { id: 6, name: 'Java Integration With ChatGpt', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/java-integration-with-chatgpt' },
    { id: 7, name: 'Python Integration With ChatGpt', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/python-integration-with-chatgpt' },
    { id: 8, name: 'Java Integration With Facebook', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/java-integration-with-facebook' },
    { id: 9, name: 'Python Integration With Facebook', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/python-integration-with-facebook' },
    { id: 10, name: 'Java Integration With LinkedIn', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/java-integration-with-linkedin' },
    { id: 11, name: 'Python Integration With LinkedIn', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/python-integration-with-linkedin' },
    { id: 12, name: 'Java Integration With Twitter', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/java-integration-with-twitter' },
    { id: 13, name: 'Python Integration With Twitter', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/python-integration-with-twitter' },
    { id: 14, name: 'Java Integration With WhatsApp', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/java-integration-with-whatsapp' },
    { id: 15, name: 'Python Integration With WhatsApp', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/python-integration-with-whatsapp' },
    { id: 16, name: 'Nmon for System Performance Monitoring ', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/nmon' },
    
    
    
    
    // Add more topics here
  ];

  return (
    <div className="topics-list-container">
      <h2>Topics in Trending</h2>
      <div className="topics-list">
        {topics.map(topic => (
          <Link key={topic.id} to={topic.route} className="topic-item">
            <img src={topic.imageUrl} alt={topic.name} />
            <p>{topic.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default TopicsList;
