import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function FacadeDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Facade Design Pattern: Simplifying Complex Systems</h1>
      
      <p>The Facade Design Pattern is a structural design pattern that provides a simple interface to a complex subsystem, making it easier to use. It acts as a single entry point that hides the underlying complexity of the system by providing a higher-level interface. The Facade Pattern allows clients to interact with the system through a unified interface, reducing the dependencies and making the code more maintainable. In this tutorial, we will explore the Facade Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Facade Design Pattern:</b></h3>
			The Facade Pattern simplifies the usage of a complex system by providing a unified and user-friendly interface. It encapsulates multiple subsystem components and exposes only the essential functionalities required by the clients. This pattern follows the "principle of least knowledge" (Law of Demeter), promoting loose coupling between clients and the underlying system.</p>
			
			<p><b>Implementing Facade in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Subsystem component: SubsystemA
class SubsystemA {
    public void operationA() {
        System.out.println("SubsystemA: Operation A");
    }
}

// Subsystem component: SubsystemB
class SubsystemB {
    public void operationB() {
        System.out.println("SubsystemB: Operation B");
    }
}

// Subsystem component: SubsystemC
class SubsystemC {
    public void operationC() {
        System.out.println("SubsystemC: Operation C");
    }
}

// Facade: Simplified interface to the complex subsystem
class Facade {
    private SubsystemA subsystemA;
    private SubsystemB subsystemB;
    private SubsystemC subsystemC;

    public Facade() {
        this.subsystemA = new SubsystemA();
        this.subsystemB = new SubsystemB();
        this.subsystemC = new SubsystemC();
    }

    // Facade methods that provide a unified interface
    public void operation1() {
        subsystemA.operationA();
        subsystemB.operationB();
    }

    public void operation2() {
        subsystemB.operationB();
        subsystemC.operationC();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Facade in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Subsystem component: SubsystemA
class SubsystemA:
    def operation_a(self):
        print("SubsystemA: Operation A")

# Subsystem component: SubsystemB
class SubsystemB:
    def operation_b(self):
        print("SubsystemB: Operation B")

# Subsystem component: SubsystemC
class SubsystemC:
    def operation_c(self):
        print("SubsystemC: Operation C")

# Facade: Simplified interface to the complex subsystem
class Facade:
    def __init__(self):
        self.subsystem_a = SubsystemA()
        self.subsystem_b = SubsystemB()
        self.subsystem_c = SubsystemC()

    # Facade methods that provide a unified interface
    def operation_1(self):
        self.subsystem_a.operation_a()
        self.subsystem_b.operation_b()

    def operation_2(self):
        self.subsystem_b.operation_b()
        self.subsystem_c.operation_c()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Facade Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class FacadeUsage {
    public static void main(String[] args) {
        Facade facade = new Facade();

        facade.operation1(); // Client only needs to interact with the Facade
        facade.operation2();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    facade = Facade()

    facade.operation_1()  # Client only needs to interact with the Facade
    facade.operation_2()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Facade Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`   +-------------------+        +---------------------+
   |       Facade      |------->|     SubsystemA      |
   +-------------------+        +---------------------+
   | +operation1()    |        | +operationA()       |
   | +operation2()    |        +---------------------+
   +-------------------+               ^
                                       |
                               +---------------------+
                               |     SubsystemB      |
                               +---------------------+
                               | +operationB()       |
                               +---------------------+
                                       ^
                                       |
                               +---------------------+
                               |     SubsystemC      |
                               +---------------------+
                               | +operationC()       |
                               +---------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Facade is the simplified interface that clients interact with. It internally uses SubsystemA, SubsystemB, and SubsystemC, which represent the complex subsystem components.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Facade Design Pattern simplifies the usage of complex subsystems by providing a unified and easy-to-understand interface. It shields clients from the underlying complexity, promoting loose coupling and modularity. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Facade Pattern can lead to more maintainable and scalable code, especially when dealing with intricate systems with multiple components and interactions.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default FacadeDesignPattern;