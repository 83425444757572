import React from 'react';
import './Footer.css';


function Footer() {
  return (
    <footer>
      <p>&copy; Copyright {new Date().getFullYear()} <strong><span>Lupuk PVT. LTD</span></strong>. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
