import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function InterceptorDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Interceptor Design Pattern: Intercepting Requests and Responses</h1>
      
      <p>The Interceptor Design Pattern, also known as the Middleware Pattern, is a behavioral design pattern that allows interception and manipulation of requests and responses between components in a system. It provides a chain of handlers (interceptors) that can intercept and process requests before they reach their final destination, and responses before they are returned to the caller. This pattern is commonly used in web applications for tasks like authentication, logging, caching, and input validation. In this tutorial, we will explore the Interceptor Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Interceptor Design Pattern:</b></h3>
			The Interceptor Pattern involves four main components: Handler, ConcreteHandler, Client, and Interceptor. The Handler declares the interface for handling requests and responses. The ConcreteHandler implements the Handler interface and performs specific handling tasks. The Client initiates requests and receives responses. The Interceptor is responsible for intercepting and processing requests and responses.</p>
			
			<p><b>Implementing Interceptor in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Handler: declares the interface for handling requests and responses
interface Handler {
    void handleRequest(Request request);
    void handleResponse(Response response);
}

// ConcreteHandler: implements the Handler interface and performs specific handling tasks
class ConcreteHandler implements Handler {
    public void handleRequest(Request request) {
        System.out.println("Handling Request: " + request.getData());
    }

    public void handleResponse(Response response) {
        System.out.println("Handling Response: " + response.getData());
    }
}

// Request: defines the request data
class Request {
    private String data;

    public Request(String data) {
        this.data = data;
    }

    public String getData() {
        return data;
    }
}

// Response: defines the response data
class Response {
    private String data;

    public Response(String data) {
        this.data = data;
    }

    public String getData() {
        return data;
    }
}

// Interceptor: declares the interface for intercepting requests and responses
interface Interceptor {
    void interceptRequest(Request request);
    void interceptResponse(Response response);
}

// ConcreteInterceptor: implements the Interceptor interface and performs interception tasks
class ConcreteInterceptor implements Interceptor {
    public void interceptRequest(Request request) {
        System.out.println("Intercepting Request: " + request.getData());
        request.setData(request.getData() + " - Interceptor");
    }

    public void interceptResponse(Response response) {
        System.out.println("Intercepting Response: " + response.getData());
        response.setData(response.getData() + " - Interceptor");
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Interceptor in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Handler: declares the interface for handling requests and responses
class Handler:
    def handle_request(self, request):
        pass

    def handle_response(self, response):
        pass

# ConcreteHandler: implements the Handler interface and performs specific handling tasks
class ConcreteHandler(Handler):
    def handle_request(self, request):
        print("Handling Request:", request.data)

    def handle_response(self, response):
        print("Handling Response:", response.data)

# Request: defines the request data
class Request:
    def __init__(self, data):
        self.data = data

# Response: defines the response data
class Response:
    def __init__(self, data):
        self.data = data

# Interceptor: declares the interface for intercepting requests and responses
class Interceptor:
    def intercept_request(self, request):
        pass

    def intercept_response(self, response):
        pass

# ConcreteInterceptor: implements the Interceptor interface and performs interception tasks
class ConcreteInterceptor(Interceptor):
    def intercept_request(self, request):
        print("Intercepting Request:", request.data)
        request.data += " - Interceptor"

    def intercept_response(self, response):
        print("Intercepting Response:", response.data)
        response.data += " - Interceptor"`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Interceptor Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class InterceptorUsage {
    public static void main(String[] args) {
        Handler handler = new ConcreteHandler();
        Interceptor interceptor = new ConcreteInterceptor();

        // Set up the chain
        handler.handleRequest(new Request("Data 1"));
        handler.handleResponse(new Response("Response 1"));

        interceptor.interceptRequest(new Request("Data 2"));
        interceptor.interceptResponse(new Response("Response 2"));
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    handler = ConcreteHandler()
    interceptor = ConcreteInterceptor()

    # Set up the chain
    handler.handle_request(Request("Data 1"))
    handler.handle_response(Response("Response 1"))

    interceptor.intercept_request(Request("Data 2"))
    interceptor.intercept_response(Response("Response 2"))`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Interceptor Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`   +----------------+       +---------------------+
   |    Handler     |  +--->|   ConcreteHandler   |
   +----------------+  |    +---------------------+
   |+handleRequest()|  |    | +handleRequest()    |
   |+handleResponse()|  |    | +handleResponse()   |
   +----------------+  |    +---------------------+
                        |
   +----------------+  |    +----------------------+
   |  Interceptor   |  +--->| ConcreteInterceptor  |
   +----------------+       +----------------------+
   |+interceptRequest()|    | +interceptRequest()  |
   |+interceptResponse()|    | +interceptResponse() |
   +----------------+       +----------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Handler is the handler interface, and ConcreteHandler is the concrete handler. Interceptor is the interceptor interface, and ConcreteInterceptor is the concrete interceptor.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Interceptor Design Pattern provides a way to intercept and manipulate requests and responses in a flexible manner without modifying the components. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Interceptor Pattern can lead to cleaner and more reusable code, especially when adding cross-cutting concerns to the application.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default InterceptorDesignPattern;
