import React from 'react';
import '../thirdparty-integgration-topics-list.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ThirdpartyIntegrationTopicsList from '../ThirdpartyIntegrationTopicsList'

function JavaIntegrationWithLinkedIn() {
  return (
    <div className="thirdparty-integgration-container">
      <h1>Java Integration with LinkedIn API</h1>
      <p>LinkedIn provides an API that allows developers to interact with LinkedIn's platform programmatically. In this tutorial, we will demonstrate how to integrate Java with the LinkedIn API to access user data, post updates, and perform various actions on LinkedIn.</p>

      <p><h3><b>Prerequisites</b></h3>
      <ul>
		<li><p>Sign up for a LinkedIn Developer Account: Go to  <a href="https://www.linkedin.com/developers/">LinkedIn Developer</a> and sign up for a LinkedIn developer account. Once you have an account, create a new application to obtain the necessary API credentials.</p></li>
		<li><p>Obtain LinkedIn API Credentials: After creating a LinkedIn application, you'll get a Client ID and Client Secret. These credentials are required to authenticate your requests to the LinkedIn API.</p></li>
		<li><p>Set up Java: Make sure you have Java installed on your machine. You can download the latest version of Java from  <a href="https://www.oracle.com/java/technologies/javase-downloads.html">Oracle</a>.</p></li>
		<li><p>IDE or Text Editor: Choose your preferred Java Integrated Development Environment (IDE) or a text editor to write Java code.</p></li>
		</ul>
    </p>

      <p><h3><b>1. Set Up LinkedIn Developer Account and Obtain API Credentials: </b></h3>
      Sign up for a LinkedIn developer account at <a href="https://www.linkedin.com/developers/">LinkedIn Developer</a>. After creating an application, you'll obtain a Client ID and Client Secret from the LinkedIn developer dashboard.</p>

    <p><h3><b>2. Set Up a Java Project: </b></h3>
		Create a new Java project in your preferred IDE or text editor.</p>

    <p><h3><b>3. Install Required Libraries: </b></h3>
		To interact with the LinkedIn API, we'll use the RestTemplate class from the Spring Web library. If you're using Maven, add the following dependency to your pom.xml</p>
		
  <SyntaxHighlighter language="xml" style={nord}>
{`<dependencies>
        <dependency>
            <groupId>org.springframework.boot</groupId>
            <artifactId>spring-boot-starter-web</artifactId>
            <version>2.5.4</version>
        </dependency>
    </dependencies>`}
  </SyntaxHighlighter>

  <p>If you're using Gradle, add the following dependency to your build.gradle</p>
  <SyntaxHighlighter language="xml" style={nord}>
{`dependencies {
    implementation 'org.springframework.boot:spring-boot-starter-web:2.5.4'
}`}
  </SyntaxHighlighter>
     
  <p><h3><b>4. Authenticate with LinkedIn API:</b></h3>
  In order to make requests to the LinkedIn API, we need to authenticate our application using OAuth 2.0. The exact authentication flow depends on the type of application you are building (web-based, server-based, etc.). For this tutorial, we'll use the Client Credentials Flow for simplicity.</p>
  <p>Here's an example Java code to authenticate using the Client Credentials Flow.</p>
<SyntaxHighlighter language="java" style={nord}>
{`import org.springframework.http.HttpHeaders;
import org.springframework.http.HttpMethod;
import org.springframework.http.MediaType;
import org.springframework.http.RequestEntity;
import org.springframework.web.client.RestTemplate;

public class LinkedInIntegration {

    public static final String CLIENT_ID = "YOUR_CLIENT_ID";
    public static final String CLIENT_SECRET = "YOUR_CLIENT_SECRET";

    public static void main(String[] args) {
        RestTemplate restTemplate = new RestTemplate();

        // Set the request headers with the Client ID and Client Secret
        HttpHeaders headers = new HttpHeaders();
        headers.setContentType(MediaType.APPLICATION_FORM_URLENCODED);
        headers.setBasicAuth(CLIENT_ID, CLIENT_SECRET);

        // Set the request body (empty for the Client Credentials Flow)
        String requestBody = "";

        // Create the request entity
        RequestEntity<String> requestEntity = new RequestEntity<>(requestBody, headers, HttpMethod.POST, URI.create("https://www.linkedin.com/oauth/v2/accessToken"));

        // Make the request to get the access token
        String response = restTemplate.exchange(requestEntity, String.class).getBody();

        // Parse the access token from the response
        String accessToken = /* parse the access token from the response */;

        // Now you can use the access token to make authenticated requests to the LinkedIn API
    }
}	`}
</SyntaxHighlighter>

<p>Note: The exact process to obtain the access token may vary based on the LinkedIn API version and OAuth flow you choose. Refer to the LinkedIn API documentation for more details: <a href="https://docs.microsoft.com/en-us/linkedin/shared/authentication/client-credentials-flow?context=linkedin/marketing/context">LinkedIn API documentation</a>.</p>

<p><h3><b>5. Access User Data:</b></h3>
You can also use the LinkedIn API to post updates on behalf of a user. This allows your application to share content, such as articles or status updates, directly on LinkedIn.</p>
		
<p>Here's an example Java code to post a share update on LinkedIn.</p>
<SyntaxHighlighter language="java" style={nord}>
{`import org.springframework.http.HttpHeaders;
import org.springframework.http.HttpMethod;
import org.springframework.http.MediaType;
import org.springframework.http.RequestEntity;
import org.springframework.web.client.RestTemplate;

public class LinkedInIntegration {

    // ... (previous code)

    public static void main(String[] args) {
        // ... (previous code)

        // Create the request entity with the access token
        HttpHeaders headers = new HttpHeaders();
        headers.set("Authorization", "Bearer " + accessToken);

        RequestEntity<String> requestEntity = new RequestEntity<>(headers, HttpMethod.GET, URI.create("https://api.linkedin.com/v2/me"));

        // Make the request to get the user's profile
        String userProfile = restTemplate.exchange(requestEntity, String.class).getBody();

        System.out.println("User Profile: " + userProfile);
    }
}`}
</SyntaxHighlighter>  

<p><h3><b>6. Post a Share Update on LinkedIn:</b></h3>
You can also use the LinkedIn API to post updates on behalf of a user. This allows your application to share content, such as articles or status updates, directly on LinkedIn.</p>
		
<p>Here's an example Java code to post a share update on LinkedIn.</p>
<SyntaxHighlighter language="java" style={nord}>
{`import org.springframework.http.HttpHeaders;
import org.springframework.http.HttpMethod;
import org.springframework.http.MediaType;
import org.springframework.http.RequestEntity;
import org.springframework.web.client.RestTemplate;

public class LinkedInIntegration {

    // ... (previous code)

    public static void main(String[] args) {
        // ... (previous code)

        // Create the request entity with the access token and the share content
        HttpHeaders headers = new HttpHeaders();
        headers.set("Authorization", "Bearer " + accessToken);
        headers.setContentType(MediaType.APPLICATION_JSON);

        String shareContent = "{ "author": "urn:li:person:YOUR_LINKEDIN_USER_ID", "lifecycleState": "PUBLISHED", "specificContent": { "com.linkedin.ugc.ShareContent": { "shareCommentary": { "text": "Hello from my Java app!" }, "shareMediaCategory": "NONE" } }, "visibility": { "com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC" } }";

        RequestEntity requestEntity = new RequestEntity<>(shareContent, headers, HttpMethod.POST, URI.create("https://api.linkedin.com/v2/ugcPosts"));

        // Make the request to post the share update
        String response = restTemplate.exchange(requestEntity, String.class).getBody();

        System.out.println("Share Update Response: " + response);
    }
}`}
</SyntaxHighlighter>  

<p><b>Here's the complete code for integrating Java with the LinkedIn API to access user data and post a share update.</b></p> 
<SyntaxHighlighter language="java" style={nord}>
{`import org.springframework.http.HttpHeaders;
import org.springframework.http.HttpMethod;
import org.springframework.http.MediaType;
import org.springframework.http.RequestEntity;
import org.springframework.web.client.RestTemplate;

import java.net.URI;

public class LinkedInIntegration {

    public static final String CLIENT_ID = "YOUR_CLIENT_ID";
    public static final String CLIENT_SECRET = "YOUR_CLIENT_SECRET";
    public static final String ACCESS_TOKEN = "YOUR_ACCESS_TOKEN";

    public static void main(String[] args) {
        RestTemplate restTemplate = new RestTemplate();

        // Fetch user profile
        String userProfile = getUserProfile(ACCESS_TOKEN, restTemplate);
        System.out.println("User Profile: " + userProfile);

        // Post a share update
        String shareResponse = postShareUpdate(ACCESS_TOKEN, restTemplate);
        System.out.println("Share Update Response: " + shareResponse);
    }

    public static String getUserProfile(String accessToken, RestTemplate restTemplate) {
        HttpHeaders headers = new HttpHeaders();
        headers.set("Authorization", "Bearer " + accessToken);

        RequestEntity requestEntity = new RequestEntity<>(headers, HttpMethod.GET, URI.create("https://api.linkedin.com/v2/me"));

        return restTemplate.exchange(requestEntity, String.class).getBody();
    }

    public static String postShareUpdate(String accessToken, RestTemplate restTemplate) {
        HttpHeaders headers = new HttpHeaders();
        headers.set("Authorization", "Bearer " + accessToken);
        headers.setContentType(MediaType.APPLICATION_JSON);

        String shareContent = "{ "author": "urn:li:person:YOUR_LINKEDIN_USER_ID", "lifecycleState": "PUBLISHED", "specificContent": { "com.linkedin.ugc.ShareContent": { "shareCommentary": { "text": "Hello from my Java app!" }, "shareMediaCategory": "NONE" } }, "visibility": { "com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC" } }";

        RequestEntity requestEntity = new RequestEntity<>(shareContent, headers, HttpMethod.POST, URI.create("https://api.linkedin.com/v2/ugcPosts"));

        return restTemplate.exchange(requestEntity, String.class).getBody();
    }
}`}
</SyntaxHighlighter>  
      <h2><b>Conclusion</b></h2>
      <p>By following this tutorial, you've learned how to integrate Java with the LinkedIn API. You can now authenticate your application, access user data, and perform actions like posting updates on LinkedIn. Keep exploring the LinkedIn API documentation for more features and use cases.
      </p>
      
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <ThirdpartyIntegrationTopicsList/>
      </div>
    </div>
  );
}

export default JavaIntegrationWithLinkedIn;
