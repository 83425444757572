import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function PrototypeDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Prototype Pattern: Creating Objects through Cloning</h1>
      
      <p>The Prototype Pattern is a creational design pattern that allows the creation of new objects by copying or cloning existing objects, known as prototypes. This pattern is useful when the cost of creating a new object from scratch is expensive, and a similar object with a few modifications is required. By using the Prototype Pattern, you can create new objects without relying on complex constructors or directly instantiating classes. In this tutorial, we will explore the Prototype Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Prototype Pattern:</b></h3>
			The Prototype Pattern aims to avoid the overhead of creating objects from scratch. It provides a prototype interface or abstract class that declares a method to clone the object. Concrete classes implement this interface to provide their own cloning mechanism. When a new object is needed, the client can request a clone of the prototype, and the clone will be customized according to the specific requirements.</p>
			
			<p><b>Implementing Prototype in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Prototype interface
interface Prototype {
    Prototype clone();
}

// Concrete Prototype A
class ConcretePrototypeA implements Prototype {
    private String property;

    public ConcretePrototypeA(String property) {
        this.property = property;
    }

    public Prototype clone() {
        return new ConcretePrototypeA(this.property);
    }

    // Other methods and attributes as needed
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Prototype in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Prototype interface
from copy import deepcopy

class Prototype:
    def clone(self):
        pass

# Concrete Prototype A
class ConcretePrototypeA(Prototype):
    def __init__(self, property):
        self.property = property

    def clone(self):
        return deepcopy(self)

    # Other methods and attributes as needed`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Prototype Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class PrototypeUsage {
    public static void main(String[] args) {
        Prototype prototypeA = new ConcretePrototypeA("Property Value");
        Prototype clonedA = prototypeA.clone();

        // Use the cloned object
        System.out.println(((ConcretePrototypeA) clonedA).getProperty()); // Output: Property Value
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    prototype_a = ConcretePrototypeA("Property Value")
    cloned_a = prototype_a.clone()

    # Use the cloned object
    print(cloned_a.property) # Output: Property Value`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Prototype Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`    +----------------------+
    |       Prototype      |
    +----------------------+
    | +clone()             |
    +----------------------+
                ^
                |
    +--------------------------+
    |   ConcretePrototypeA     |
    +--------------------------+
    | - property: type         |
    +--------------------------+
    | +clone()                 |
    +--------------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Prototype is the interface that declares the clone() method. ConcretePrototypeA is the concrete implementation of the prototype interface, providing its cloning mechanism.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Prototype Pattern provides a convenient way to create new objects through cloning existing ones. By using a prototype interface or abstract class, the pattern allows you to create copies of objects without directly invoking constructors. We explored its implementation in both Java and Python, along with a simple usage example. Utilizing the Prototype Pattern can lead to improved performance and flexibility when creating objects with similar configurations or properties.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default PrototypeDesignPattern;
