import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function AbstractDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Abstract Factory Pattern: Building Families of Related Objects</h1>
      
      <p>The Abstract Factory Pattern is a creational design pattern that provides an interface for creating families of related or dependent objects without specifying their concrete classes. It allows you to create objects that are designed to work together while remaining decoupled from their implementations. In this tutorial, we will explore the Abstract Factory Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Abstract Factory Pattern:</b></h3>
			The Abstract Factory Pattern is an extension of the Factory Method Pattern. It allows you to create families of objects that are designed to work together in a specific context. The pattern provides an abstract factory interface, which defines methods to create different types of related objects. Concrete factory classes implement this interface to produce objects belonging to specific families.</p>
			
			<p><b>Implementing Abstract Factory in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Abstract Product A
interface ProductA {
    void createProductA();
}

// Concrete Product A1
class ConcreteProductA1 implements ProductA {
    public void createProductA() {
        System.out.println("Product A1 created.");
    }
}

// Concrete Product A2
class ConcreteProductA2 implements ProductA {
    public void createProductA() {
        System.out.println("Product A2 created.");
    }
}

// Abstract Product B
interface ProductB {
    void createProductB();
}

// Concrete Product B1
class ConcreteProductB1 implements ProductB {
    public void createProductB() {
        System.out.println("Product B1 created.");
    }
}

// Concrete Product B2
class ConcreteProductB2 implements ProductB {
    public void createProductB() {
        System.out.println("Product B2 created.");
    }
}

// Abstract Factory
interface AbstractFactory {
    ProductA createProductA();
    ProductB createProductB();
}

// Concrete Factory 1
class ConcreteFactory1 implements AbstractFactory {
    public ProductA createProductA() {
        return new ConcreteProductA1();
    }

    public ProductB createProductB() {
        return new ConcreteProductB1();
    }
}

// Concrete Factory 2
class ConcreteFactory2 implements AbstractFactory {
    public ProductA createProductA() {
        return new ConcreteProductA2();
    }

    public ProductB createProductB() {
        return new ConcreteProductB2();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Abstract Factory in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Abstract Product A
class ProductA:
    def create_product_a(self):
        pass

# Concrete Product A1
class ConcreteProductA1(ProductA):
    def create_product_a(self):
        print("Product A1 created.")

# Concrete Product A2
class ConcreteProductA2(ProductA):
    def create_product_a(self):
        print("Product A2 created.")

# Abstract Product B
class ProductB:
    def create_product_b(self):
        pass

# Concrete Product B1
class ConcreteProductB1(ProductB):
    def create_product_b(self):
        print("Product B1 created.")

# Concrete Product B2
class ConcreteProductB2(ProductB):
    def create_product_b(self):
        print("Product B2 created.")

# Abstract Factory
class AbstractFactory:
    def create_product_a(self):
        pass

    def create_product_b(self):
        pass

# Concrete Factory 1
class ConcreteFactory1(AbstractFactory):
    def create_product_a(self):
        return ConcreteProductA1()

    def create_product_b(self):
        return ConcreteProductB1()

# Concrete Factory 2
class ConcreteFactory2(AbstractFactory):
    def create_product_a(self):
        return ConcreteProductA2()

    def create_product_b(self):
        return ConcreteProductB2()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Abstract Factory Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class AbstractFactoryUsage {
    public static void main(String[] args) {
        AbstractFactory factory1 = new ConcreteFactory1();
        ProductA productA1 = factory1.createProductA();
        ProductB productB1 = factory1.createProductB();

        productA1.createProductA(); // Output: Product A1 created.
        productB1.createProductB(); // Output: Product B1 created.

        AbstractFactory factory2 = new ConcreteFactory2();
        ProductA productA2 = factory2.createProductA();
        ProductB productB2 = factory2.createProductB();

        productA2.createProductA(); // Output: Product A2 created.
        productB2.createProductB(); // Output: Product B2 created.
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    factory1 = ConcreteFactory1()
    productA1 = factory1.create_product_a()
    productB1 = factory1.create_product_b()

    productA1.create_product_a() # Output: Product A1 created.
    productB1.create_product_b() # Output: Product B1 created.

    factory2 = ConcreteFactory2()
    productA2 = factory2.create_product_a()
    productB2 = factory2.create_product_b()

    productA2.create_product_a() # Output: Product A2 created.
    productB2.create_product_b() # Output: Product B2 created.`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Abstract Factory Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`      +--------------------------+
      |       AbstractFactory    |
      +--------------------------+
      | +createProductA()        |
      | +createProductB()        |
      +--------------------------+
               ^                 ^
               |                 |
   +-----------------+  +-----------------+
   |ConcreteFactory1 |  |ConcreteFactory2 |
   +-----------------+  +-----------------+
   | +createProductA() |  | +createProductA() |
   | +createProductB() |  | +createProductB() |
   +-----------------+  +-----------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, AbstractFactory is the interface that declares the abstract methods createProductA() and createProductB(). ConcreteFactory1 and ConcreteFactory2 are the concrete factory classes that implement these methods to create instances of ConcreteProductA1, ConcreteProductA2, ConcreteProductB1, and ConcreteProductB2, respectively.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Abstract Factory Pattern enables the creation of families of related objects in a flexible and decoupled manner. By providing an abstract factory interface, the pattern allows you to switch between different families of objects without modifying the client code. We explored its implementation in both Java and Python, along with a simple usage example. Using the Abstract Factory Pattern can lead to more modular and maintainable code, making it easier to accommodate changes or introduce new families of objects in your application.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default AbstractDesignPattern;
