import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-principle.css';
import SoftwareDesignPrincipleTopicsList from './SoftwareDesignPrincipleTopicsList'

function DonotRepeatYourselfPrinciple() {
  return (
    <div className="software-design-principle-container">
      <h1>DRY (Don't Repeat Yourself) Principle</h1>
      
      <p>The DRY (Don't Repeat Yourself) principle advocates for avoiding code duplication and promoting code reuse. It improves maintainability and reduces the chance of bugs.
			</p>
			<p><b>Example in Java:</b>Consider having duplicate code to calculate the total price for two different types of products:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Not following DRY
public class Product {
    // ... other attributes and methods ...

    public double calculateTotalPrice() {
        // Code to calculate total price for regular products
    }
}

public class DiscountedProduct {
    // ... other attributes and methods ...

    public double calculateTotalPrice() {
        // Code to calculate total price for discounted products
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow DRY, we extract the common functionality into a separate method:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Following DIP
public class CustomerService {
    private CustomerRepository customerRepository;

    public CustomerService(CustomerRepository customerRepository) {
        this.customerRepository = customerRepository;
    }

    // Methods using customerRepository
}`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Example in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Python
# Not following DIP
class CustomerService:
    def __init__(self):
        self.customer_repository = CustomerRepository()

    # Methods using customer_repository`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow DIP in Python, we use dependency injection and rely on abstractions:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Following DIP
class CustomerService:
    def __init__(self, customer_repository):
        self.customer_repository = customer_repository

    # Methods using customer_repository`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`         Java                             Python
+--------------------+             +--------------------+
|  CustomerService   |             |  CustomerService   |
+--------------------+             +--------------------+
|                    |             |                    |
+--------------------+             +--------------------+
            |                              |
            |                              |
+-----------------------+    	+-----------------------+
| CustomerRepository   |     	| CustomerRepository    |
+-----------------------+    	+-----------------------+`}
        </SyntaxHighlighter>
      <br/>

      <h2><b>Conclusion</b></h2>
      <p>In this tutorial, we have explored essential design principles in software system design, including SOLID (Single Responsibility, Open-Closed, Liskov Substitution, Interface Segregation, Dependency Inversion), DRY (Don't Repeat Yourself), KISS (Keep It Simple, Stupid), and YAGNI (You Aren't Gonna Need It). By adhering to these principles, developers can create well-structured, maintainable, and flexible software systems. The examples in both Java and Python, along with the diagrams, have illustrated the practical application of these principles. By incorporating these design principles into your software development practices, you can build high-quality and reliable software systems.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <SoftwareDesignPrincipleTopicsList/>
      </div>
    </div>
  );
}

export default DonotRepeatYourselfPrinciple;
