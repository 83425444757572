import React from 'react';
import '../thirdparty-integgration-topics-list.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ThirdpartyIntegrationTopicsList from '../ThirdpartyIntegrationTopicsList'

function PythonIntegrationWithWhatsApp() {
  return (
    <div className="thirdparty-integgration-container">
      <h1>Python Integration with WhatsApp</h1>
      <p>WhatsApp is a popular messaging platform, and integrating it with Python allows you to send and receive messages programmatically. In this tutorial, we will demonstrate how to integrate WhatsApp with Python using the Twilio API. Twilio is a cloud communications platform that provides APIs to interact with various messaging services, including WhatsApp.</p>

      <p><h3><b>Prerequisites</b></h3>
      <ul>
		<li><p>Sign up for a Twilio account: Go to <a href="https://www.twilio.com/try-twilio">Twilio</a> and sign up for a free Twilio account.</p></li>
		<li><p>Obtain Twilio API Credentials: Once you have a Twilio account, you'll get an Account SID and Auth Token from the Twilio dashboard. These credentials are required to authenticate your requests to the Twilio API.</p></li>
		<li><p>Set up Python: Make sure you have Python installed on your machine. You can download the latest version of Python from <a href="https://www.python.org/downloads/">Python</a>.</p></li>
		<li><p>Twilio Python Library: Install the Twilio Python library using :pip install twilio</p></li>
    <li><p>IDE or Text Editor: Choose your preferred Python Integrated Development Environment (IDE) or a text editor to write Python code.</p></li>
		</ul>
    </p>

      <p><h3><b>1. Set Up Twilio and Obtain API Credentials: </b></h3>
      Sign up for a Twilio account at <a href="https://www.twilio.com/try-twilio">Twilio</a>. After signing up, obtain the Account SID and Auth Token from the Twilio dashboard.</p>

    <p><h3><b>2. Send WhatsApp Message: </b></h3>
		Now, let's write Python code to send a WhatsApp message using the Twilio API. Replace YOUR_ACCOUNT_SID, YOUR_AUTH_TOKEN, and YOUR_TWILIO_PHONE_NUMBER with your Twilio credentials and Twilio phone number.</p>
  <SyntaxHighlighter language="python" style={nord}>
{`from twilio.rest import Client

def send_whatsapp_message():
    ACCOUNT_SID = "YOUR_ACCOUNT_SID"
    AUTH_TOKEN = "YOUR_AUTH_TOKEN"
    TWILIO_PHONE_NUMBER = "YOUR_TWILIO_PHONE_NUMBER"
    WHATSAPP_TO_NUMBER = "RECIPIENT_WHATSAPP_NUMBER_WITH_COUNTRY_CODE"

    client = Client(ACCOUNT_SID, AUTH_TOKEN)

    message = client.messages.create(
        from_='whatsapp:' + TWILIO_PHONE_NUMBER,
        body="Hello from Twilio!",
        to='whatsapp:' + WHATSAPP_TO_NUMBER
    )

    print("Message SID:", message.sid)

if __name__ == "__main__":
    send_whatsapp_message()`}
  </SyntaxHighlighter>
  
<p><h3><b>5. Receive WhatsApp Message (Webhook Setup): </b></h3>
Setting up a webhook involves more than just Python code, so we'll provide a brief overview of the process.
<ul>
		<li><p>Configure a public URL for your application that will receive incoming WhatsApp messages from Twilio. You can use tools like ngrok to create a temporary public URL for testing purposes.</p></li>
		<li><p>Set up a webhook in your Twilio dashboard to point to your public URL. Twilio will send incoming WhatsApp messages to this URL.</p></li>
		<li><p>Write a web server application in Python or any other language to handle incoming WhatsApp messages and respond accordingly.</p></li>
		
		</ul>
    <p>The webhook implementation is beyond the scope of this text-based tutorial, but Twilio's documentation provides detailed steps on how to set up a webhook for receiving WhatsApp messages: <a href="https://www.twilio.com/docs/whatsapp/api#receiving-inbound-messages">receiving-inbound-messages</a></p>
</p>
		

      <h2><b>Conclusion</b></h2>
      <p>By following this tutorial, you've learned how to integrate WhatsApp with Python using the Twilio API. You can now send WhatsApp messages programmatically and explore advanced features such as receiving messages through webhooks.
      </p>
      
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <ThirdpartyIntegrationTopicsList/>
      </div>
    </div>
  );
}

export default PythonIntegrationWithWhatsApp;
