import React from 'react';
import { Link } from 'react-router-dom';
import './design-pattern-topics-list.css';

function DesignPatternTopicsList() {
  const topics = [
   
    { id: 1, name: 'Software Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/software-design-pattern' },
    { id: 2, name: 'Singleton Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/singleton-design-pattern' },
    { id: 3, name: 'Factory Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/factory-design-pattern' },
    { id: 4, name: 'Abstract Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/abstract-design-pattern' },
    { id: 5, name: 'Builder Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/builder-design-pattern' },
    { id: 6, name: 'Prototype Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/prototype-design-pattern' },
    { id: 7, name: 'Object Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/object-design-pattern' },
    { id: 8, name: 'Adapter Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/adapter-design-pattern' },
    { id: 9, name: 'Decorator Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/decorator-design-pattern' },
    { id: 10, name: 'Proxy Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/proxy-design-pattern' },
    { id: 11, name: 'Composite Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/composite-design-pattern' },
    { id: 12, name: 'Bridge Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/bridge-design-pattern' },
    { id: 13, name: 'Facade Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/facade-design-pattern' },
    { id: 14, name: 'Flyweight Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/flyweight-design-pattern' },
    { id: 15, name: 'Observer Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/observer-design-pattern' },
    { id: 16, name: 'Strategy Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/strategy-design-pattern' },
    { id: 17, name: 'Template Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/template-design-pattern' },
    { id: 18, name: 'Command Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/command-design-pattern' },
    { id: 19, name: 'Chain Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/chain-design-pattern' },
    { id: 20, name: 'State Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/state-design-pattern' },
    { id: 21, name: 'Mediator Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/mediator-design-pattern' },
    { id: 22, name: 'Visitor Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/visitor-design-pattern' },
    { id: 23, name: 'Interceptor Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/interceptor-design-pattern' },
    { id: 24, name: 'Iterator Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/iterator-design-pattern' },
    { id: 25, name: 'Memento Design Pattern', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/memento-design-pattern' },
    
    
    
    // Add more topics here
  ];

  return (
    <div className="topics-list-container">
      <h1>Software Design Patterns</h1>
      <div className="topics-list">
        {topics.map(topic => (
          <Link key={topic.id} to={topic.route} className="topic-item">
            <img src={topic.imageUrl} alt={topic.name} />
            <p>{topic.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default DesignPatternTopicsList;
