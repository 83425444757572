import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function StateDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>State Design Pattern: Managing Object State with Ease</h1>
      
      <p>The State Design Pattern is a behavioral design pattern that allows an object to change its behavior when its internal state changes. It promotes the "state" of an object to be represented by separate state classes. The context class, which holds a reference to the current state, delegates requests to the state objects. This pattern simplifies complex conditional logic related to state transitions and enhances code maintainability. In this tutorial, we will explore the State Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the State Design Pattern:</b></h3>
			The State Pattern involves two main components: Context and State. The Context represents the object whose behavior changes based on its internal state. The State classes encapsulate the specific behavior for each state. By delegating responsibilities to state objects, the Context class keeps its code focused and clean.</p>
			
			<p><b>Implementing State in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// State: declares the interface for the concrete state classes
interface State {
    void doAction(Context context);
}

// ConcreteStateA: implements the State interface with specific behavior
class ConcreteStateA implements State {
    public void doAction(Context context) {
        System.out.println("State A: Performing action and transitioning to State B");
        context.setState(new ConcreteStateB());
    }
}

// ConcreteStateB: implements the State interface with specific behavior
class ConcreteStateB implements State {
    public void doAction(Context context) {
        System.out.println("State B: Performing action and transitioning back to State A");
        context.setState(new ConcreteStateA());
    }
}

// Context: maintains a reference to the current state and delegates requests
class Context {
    private State currentState;

    public Context() {
        // Set an initial state
        currentState = new ConcreteStateA();
    }

    public void setState(State state) {
        this.currentState = state;
    }

    public void doAction() {
        currentState.doAction(this);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing State in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# State: declares the interface for the concrete state classes
class State:
    def do_action(self, context):
        pass

# ConcreteStateA: implements the State interface with specific behavior
class ConcreteStateA(State):
    def do_action(self, context):
        print("State A: Performing action and transitioning to State B")
        context.set_state(ConcreteStateB())

# ConcreteStateB: implements the State interface with specific behavior
class ConcreteStateB(State):
    def do_action(self, context):
        print("State B: Performing action and transitioning back to State A")
        context.set_state(ConcreteStateA())

# Context: maintains a reference to the current state and delegates requests
class Context:
    def __init__(self):
        # Set an initial state
        self.current_state = ConcreteStateA()

    def set_state(self, state):
        self.current_state = state

    def do_action(self):
        self.current_state.do_action(self)`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the State Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class StateUsage {
    public static void main(String[] args) {
        Context context = new Context();
        context.doAction();
        context.doAction();
        context.doAction();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    context = Context()

    context.do_action()
    context.do_action()
    context.do_action()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the State Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`   +-------------------+    +---------------------+
   |       State       |<>--|   ConcreteStateA    |
   +-------------------+    +---------------------+
   | +doAction(): void |    | +doAction(): void   |
   +-------------------+    +---------------------+
               ^
               |
    +---------------------+
    |   ConcreteStateB    |
    +---------------------+
    | +doAction(): void   |
    +---------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, State is the state interface, and ConcreteStateA and ConcreteStateB are the concrete state classes. The Context class maintains a reference to the current state and delegates requests to the current state.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The State Design Pattern allows an object to change its behavior when its internal state changes, making code more maintainable and reducing conditional complexity. We explored its implementation in both Java and Python, along with a simple usage example. Applying the State Pattern can lead to cleaner and more flexible code, especially when managing complex state transitions in an object.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}


export default StateDesignPattern;
