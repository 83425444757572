import React from 'react';
import { Link } from 'react-router-dom';
import './pyspark-topics-list.css';

function PySparkTopicsList() {
  const topics = [
   
    { id: 1, name: 'PySpark Performance', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance' },
    { id: 2, name: 'Data Partitioning and Caching', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance/data-partitioning' },
    { id: 3, name: 'Broadcasting Small DataFrame', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance/broadcasting-small-dataframe' },
    { id: 4, name: 'Choose the Right Join Type', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance/choose-riht-join-type' },
    { id: 5, name: 'Optimize Memory Usage', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance/optimize-memory-usage' },
    { id: 6, name: 'Coalesce and Repartition', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance/coalesce-and-repartition' },
    { id: 7, name: 'SQL Optimization', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance/sql-optimization' },
    { id: 8, name: 'Use Catalyst Optimizer', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance/use-catalyst-optimizer' },
    { id: 9, name: 'Avoid Unnecessary Actions', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance/avoid-unnecessary-actions' },
    { id: 10, name: 'Use Data Types Wisely', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance/use-data-types-wisely' },
    { id: 11, name: 'Cluster Configuration', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance/cluster-configuration' },
    { id: 12, name: 'Leverage Caching', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/pyspark-performance/leverage-caching' },
    
    
    
    // Add more topics here
  ];

  return (
    <div className="topics-list-container">
      <h2>PySpark Improvement List</h2>
      <div className="topics-list">
        {topics.map(topic => (
          <Link key={topic.id} to={topic.route} className="topic-item">
            <img src={topic.imageUrl} alt={topic.name} />
            <p>{topic.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default PySparkTopicsList;
