import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function BuilderDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Builder Design Pattern: Constructing Complex Objects Step by Step</h1>
      
      <p>The Builder Design Pattern is a creational design pattern that separates the construction of complex objects from their representation. It allows you to create objects by providing a step-by-step approach, hiding the internal details of object construction. This pattern is particularly useful when dealing with objects that require multiple configuration options or parameters. In this tutorial, we will explore the Builder Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Builder Pattern:</b></h3>
			The Builder Pattern aims to address the challenges of creating complex objects with numerous properties or configurations. It provides a clear separation between the construction and representation of objects. The pattern uses a separate builder class to construct the object, and the final object is returned when all the desired properties have been set.</p>
			
			<p><b>Implementing Builder in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Product class
public class Product {
    private String property1;
    private int property2;
    private boolean property3;

    public Product() {
        // Constructor with required properties or default values
    }

    // Setters for properties
    public void setProperty1(String property1) {
        this.property1 = property1;
    }

    public void setProperty2(int property2) {
        this.property2 = property2;
    }

    public void setProperty3(boolean property3) {
        this.property3 = property3;
    }

    // Other methods as needed
}`}
        </SyntaxHighlighter>
        <br/>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Builder class
public class ProductBuilder {
    private Product product;

    public ProductBuilder() {
        product = new Product();
    }

    public ProductBuilder withProperty1(String property1) {
        product.setProperty1(property1);
        return this;
    }

    public ProductBuilder withProperty2(int property2) {
        product.setProperty2(property2);
        return this;
    }

    public ProductBuilder withProperty3(boolean property3) {
        product.setProperty3(property3);
        return this;
    }

    public Product build() {
        return product;
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Builder in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Product class
class Product:
    def __init__(self):
        # Constructor with required properties or default values
        self.property1 = None
        self.property2 = None
        self.property3 = None

    # Setters for properties
    def set_property1(self, property1):
        self.property1 = property1

    def set_property2(self, property2):
        self.property2 = property2

    def set_property3(self, property3):
        self.property3 = property3

    # Other methods as needed`}
        </SyntaxHighlighter>
      <br/>
      <SyntaxHighlighter  language="python" style={nord}>
{`# Builder class
class ProductBuilder:
    def __init__(self):
        self.product = Product()

    def with_property1(self, property1):
        self.product.set_property1(property1)
        return self

    def with_property2(self, property2):
        self.product.set_property2(property2)
        return self

    def with_property3(self, property3):
        self.product.set_property3(property3)
        return self

    def build(self):
        return self.product`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Builder Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class BuilderUsage {
    public static void main(String[] args) {
        ProductBuilder builder = new ProductBuilder();
        Product product = builder
            .withProperty1("Value 1")
            .withProperty2(42)
            .withProperty3(true)
            .build();

        // Use the product object
        System.out.println(product.getProperty1()); // Output: Value 1
        System.out.println(product.getProperty2()); // Output: 42
        System.out.println(product.isProperty3());   // Output: true
    }
}	`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    builder = ProductBuilder()
    product = builder \
        .with_property1("Value 1") \
        .with_property2(42) \
        .with_property3(True) \
        .build()

    # Use the product object
    print(product.property1) # Output: Value 1
    print(product.property2) # Output: 42
    print(product.property3) # Output: True`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Builder Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`         +----------------------------------+
         |            Product               |
         +----------------------------------+
         | - property1: type                |
         | - property2: type                |
         | - property3: type                |
         +----------------------------------+
               ^       ^       ^
               |       |       |
   +-----------------+   +-----------------+
   |ProductBuilder  |   |ProductBuilder  |
   +-----------------+   +-----------------+
   | +withProperty1() |  | +withProperty1() |
   | +withProperty2() |  | +withProperty2() |
   | +withProperty3() |  | +withProperty3() |
   | +build()         |  | +build()         |
   +-----------------+   +-----------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Product is the complex object being constructed. The ProductBuilder class provides methods to set various properties of the product and build the final product.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Builder Pattern provides an elegant way to construct complex objects with multiple properties step-by-step. By using a separate builder class, the pattern allows you to maintain a clean and flexible construction process. We explored its implementation in both Java and Python, along with a simple usage example. Utilizing the Builder Pattern can lead to more maintainable and readable code, especially when creating objects with many optional parameters or configurations.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default BuilderDesignPattern;
