import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function BridgeDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Bridge Design Pattern: Decoupling Abstraction from Implementation</h1>
      
      <p>The Bridge Design Pattern is a structural design pattern that separates the abstraction from its implementation, allowing them to vary independently. It is used to decouple complex class hierarchies by creating two separate hierarchies—one for the abstraction and another for the implementation. This pattern allows changes in one hierarchy to have no impact on the other. In this tutorial, we will explore the Bridge Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Bridge Design Pattern:</b></h3>
			The Bridge Pattern follows the "prefer composition over inheritance" principle. It is especially useful when there are multiple dimensions of variations in a class hierarchy. The Bridge Pattern creates a bridge between these dimensions, enabling them to evolve independently. This way, the changes in the implementation do not affect the abstraction, and vice versa.</p>
			
			<p><b>Implementing Bridge in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Abstraction: the high-level abstraction class
abstract class Shape {
    protected DrawAPI drawAPI;

    public Shape(DrawAPI drawAPI) {
        this.drawAPI = drawAPI;
    }

    public abstract void draw();
}

// Implementation: the low-level implementation interface
interface DrawAPI {
    void drawCircle(int radius, int x, int y);
}

// ConcreteImplementation: the concrete implementation class
class RedCircle implements DrawAPI {
    public void drawCircle(int radius, int x, int y) {
        System.out.println("Drawing Red Circle: radius=" + radius + ", x=" + x + ", y=" + y);
    }
}

// ConcreteImplementation: another concrete implementation class
class GreenCircle implements DrawAPI {
    public void drawCircle(int radius, int x, int y) {
        System.out.println("Drawing Green Circle: radius=" + radius + ", x=" + x + ", y=" + y);
    }
}

// Refined Abstraction: a refined abstraction class
class Circle extends Shape {
    private int x, y, radius;

    public Circle(int x, int y, int radius, DrawAPI drawAPI) {
        super(drawAPI);
        this.x = x;
        this.y = y;
        this.radius = radius;
    }

    public void draw() {
        drawAPI.drawCircle(radius, x, y);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Bridge in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Abstraction: the high-level abstraction class
class Shape:
    def __init__(self, draw_api):
        self.draw_api = draw_api

    def draw(self):
        pass

# Implementation: the low-level implementation interface
class DrawAPI:
    def draw_circle(self, radius, x, y):
        pass

# ConcreteImplementation: the concrete implementation class
class RedCircle(DrawAPI):
    def draw_circle(self, radius, x, y):
        print(f"Drawing Red Circle: radius={radius}, x={x}, y={y}")

# ConcreteImplementation: another concrete implementation class
class GreenCircle(DrawAPI):
    def draw_circle(self, radius, x, y):
        print(f"Drawing Green Circle: radius={radius}, x={x}, y={y}")

# Refined Abstraction: a refined abstraction class
class Circle(Shape):
    def __init__(self, x, y, radius, draw_api):
        super().__init__(draw_api)
        self.x = x
        self.y = y
        self.radius = radius

    def draw(self):
        self.draw_api.draw_circle(self.radius, self.x, self.y)`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Bridge Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class BridgeUsage {
    public static void main(String[] args) {
        Shape redCircle = new Circle(100, 100, 10, new RedCircle());
        Shape greenCircle = new Circle(200, 200, 20, new GreenCircle());

        redCircle.draw();
        greenCircle.draw();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    red_circle = Circle(100, 100, 10, RedCircle())
    green_circle = Circle(200, 200, 20, GreenCircle())

    red_circle.draw()
    green_circle.draw()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Bridge Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`     +-------------------+         +------------------+
     |      Shape        |         |     DrawAPI      |
     +-------------------+         +------------------+
     | - drawAPI: DrawAPI|         | +draw_circle()   |
     +-------------------+         +------------------+
             ^                            ^
             |                            |
     +--------------------+      +---------------------+
     |      Circle        |      |   RedCircle        |
     +--------------------+      +---------------------+
     | - x, y, radius     |      | +draw_circle()     |
     +--------------------+      +---------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Shape is the abstraction class, DrawAPI is the implementation interface, and Circle is the refined abstraction class. RedCircle and GreenCircle are concrete implementation classes.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Bridge Design Pattern allows you to decouple the abstraction from its implementation, providing flexibility in handling variations in class hierarchies. By creating separate hierarchies for abstraction and implementation and connecting them through a bridge, the Bridge Pattern facilitates easy extension and modification without impacting the other part. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Bridge Pattern can lead to more maintainable and adaptable code, especially when dealing with complex class hierarchies with multiple dimensions of variations.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default BridgeDesignPattern;
