import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function SingletonDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Singleton Design Pattern: Ensuring Unique Instances</h1>
      
      <p>The Singleton Design Pattern is a creational design pattern that ensures a class has only one instance and provides a global access point to that instance. This pattern is useful when you want to restrict the creation of multiple objects and ensure that only one instance of the class exists throughout the application's lifecycle. In this tutorial, we will explore the Singleton Design Pattern with examples in both Java and Python, along with diagram illustrations.
			</p>

      <p><h3><b>☐ Understanding the Singleton Design Pattern:</b></h3>
			The Singleton pattern follows the concept of a single instance of a class throughout the application. It typically involves a private constructor to prevent direct instantiation and a static method to return the single instance. The pattern also ensures that all subsequent requests for an instance return the same unique instance.</p>
			
			<p><b>Implementing Singleton in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class Singleton {
    private static Singleton instance;

    // Private constructor to prevent direct instantiation
    private Singleton() {
    }

    public static Singleton getInstance() {
        if (instance == null) {
            instance = new Singleton();
        }
        return instance;
    }

    // Other methods and attributes can be added as needed
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Singleton in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`class Singleton:
    _instance = None

    # Private constructor to prevent direct instantiation
    def __new__(cls):
        if not cls._instance:
            cls._instance = super(Singleton, cls).__new__(cls)
        return cls._instance

    # Other methods and attributes can be added as needed`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Singleton Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class SingletonUsage {
    public static void main(String[] args) {
        Singleton singleton1 = Singleton.getInstance();
        Singleton singleton2 = Singleton.getInstance();

        // Both instances are the same
        System.out.println(singleton1 == singleton2); // Output: true
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`class SingletonUsage:
    if __name__ == "__main__":
        singleton1 = Singleton()
        singleton2 = Singleton()

        # Both instances are the same
        print(singleton1 is singleton2) # Output: True`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Singleton Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`+----------------------+
|       Singleton      |
+----------------------+
| -instance: Singleton |
+----------------------+
| +getInstance()       |
+----------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Singleton is the class with a private instance variable and a static getInstance() method to access the unique instance.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Singleton Design Pattern is a powerful approach to ensure that a class has only one instance throughout the application. We explored its implementation in both Java and Python, along with a simple usage example. The Singleton pattern is particularly useful when you need to manage resources, configuration settings, or shared objects efficiently. By understanding and applying the Singleton pattern, you can enhance the structure and behavior of your applications, providing a unique and controlled instance of a class when needed.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default SingletonDesignPattern;
