import React from 'react';
import '../thirdparty-integgration-topics-list.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ThirdpartyIntegrationTopicsList from '../ThirdpartyIntegrationTopicsList'

function PythonIntegrationWithTwitter() {
  return (
    <div className="thirdparty-integgration-container">
      <h1>Python Integration with Twitter API</h1>
      <p>Twitter provides an API that allows developers to interact with Twitter's platform programmatically. In this tutorial, we will demonstrate how to integrate Python with the Twitter API to access tweets, post new tweets, and perform various actions on Twitter.</p>

      <p><h3><b>Prerequisites</b></h3>
      <ul>
		<li><p>Create a Twitter Developer Account: Go to <a href="https://developer.twitter.com/">Twitter Developer</a> and sign up for a Twitter developer account. Once you have an account, create a new app to obtain the necessary API credentials.</p></li>
		<li><p>Obtain Twitter API Credentials: After creating a Twitter app, you'll get an API key, API secret key, access token, and access token secret. These credentials are required to authenticate your requests to the Twitter API.</p></li>
		<li><p>Set Up a Python Development Environment: Make sure you have Python installed on your system. You can download it from <a href="https://www.python.org/downloads/">Python</a>.</p></li>
		<li><p>Install Required Libraries: We'll use the tweepy library for Python to interact with the Twitter API. You can install it using the following command:pip install tweepy</p></li>
		</ul>
    </p>

      <p><h3><b>1. Set Up Twitter Developer Account and Obtain API Credentials: </b></h3>
      Sign up for a Twitter developer account at <a href="https://developer.twitter.com/">Twitter Developer</a>. After creating an application, you'll obtain an API key, API secret key, access token, and access token secret from the Twitter developer dashboard.</p>

    <p><h3><b>2. Authenticate with Twitter API: </b></h3>
		To make requests to the Twitter API, we need to authenticate our application using OAuth 1.0a or OAuth 2.0. For this tutorial, we'll use OAuth 1.0a for user-based authentication.</p>

    <p>Here's an example Python code to authenticate using the Twitter API.</p>

  <SyntaxHighlighter language="python" style={nord}>
{`import tweepy

CONSUMER_KEY = 'YOUR_CONSUMER_KEY'
CONSUMER_SECRET = 'YOUR_CONSUMER_SECRET'
ACCESS_TOKEN = 'YOUR_ACCESS_TOKEN'
ACCESS_TOKEN_SECRET = 'YOUR_ACCESS_TOKEN_SECRET'

# Create the OAuth handler and set the access tokens
auth = tweepy.OAuth1Handler(CONSUMER_KEY, CONSUMER_SECRET)
auth.set_access_token(ACCESS_TOKEN, ACCESS_TOKEN_SECRET)

# Create the Twitter API object
api = tweepy.API(auth)

# Now you can use the 'api' object to interact with the Twitter API.
# Implement your desired functionality using the 'api' object.`}
  </SyntaxHighlighter>

<p><h3><b>3. Access Tweets and User Data:</b></h3>
Once authenticated, you can use the Twitter object to access tweets, user profiles, and other data from Twitter. You can fetch tweets, user timelines, mentions, and more.</p>
		
<p>Here's an example Python code to fetch a user's timeline using the Twitter API.</p>
<SyntaxHighlighter language="python" style={nord}>
{`import tweepy

# ... (Previous code)

try:
    # Fetch user's timeline
    user_timeline = api.home_timeline()
    print("User's Timeline:")
    for tweet in user_timeline:
        print(tweet.user.name, "-", tweet.text)
except tweepy.TweepError as e:
    print("Error:", e)`}
</SyntaxHighlighter>  

<p><h3><b>4. Post a New Tweet on Twitter:</b></h3>
You can also use the 'api' object to post new tweets on Twitter. This allows your application to share content directly on Twitter.</p>
		
<p>Here's an example Python code to post a new tweet using the Twitter API.</p>
<SyntaxHighlighter language="python" style={nord}>
{`import tweepy

# ... (Previous code)

try:
    # Post a new tweet
    api.update_status("Hello from my Python app!")
    print("Tweet posted successfully.")
except tweepy.TweepError as e:
    print("Error:", e)`}
</SyntaxHighlighter>  

<p><b>Here's the complete Python code for integrating with the Twitter API.</b></p> 
<SyntaxHighlighter language="python" style={nord}>
{`import tweepy

CONSUMER_KEY = 'YOUR_CONSUMER_KEY'
CONSUMER_SECRET = 'YOUR_CONSUMER_SECRET'
ACCESS_TOKEN = 'YOUR_ACCESS_TOKEN'
ACCESS_TOKEN_SECRET = 'YOUR_ACCESS_TOKEN_SECRET'

# Create the OAuth handler and set the access tokens
auth = tweepy.OAuth1Handler(CONSUMER_KEY, CONSUMER_SECRET)
auth.set_access_token(ACCESS_TOKEN, ACCESS_TOKEN_SECRET)

# Create the Twitter API object
api = tweepy.API(auth)

try:
    # Fetch user's timeline
    user_timeline = api.home_timeline()
    print("User's Timeline:")
    for tweet in user_timeline:
        print(tweet.user.name, "-", tweet.text)

    # Post a new tweet
    api.update_status("Hello from my Python app!")
    print("Tweet posted successfully.")
except tweepy.TweepError as e:
    print("Error:", e)`}
</SyntaxHighlighter>  
      <h2><b>Conclusion</b></h2>
      <p>By following this tutorial, you've learned how to integrate Python with the Twitter API. You can now authenticate your application, access tweets and user data, and post new tweets on Twitter. Make sure to handle your Twitter API credentials securely and avoid sharing them publicly to protect your application's security.
      </p>
      <p>The 'tweepy' library provides many other functionalities to interact with various parts of the Twitter API. Explore the documentation to access other objects like mentions, direct messages, and more.</p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <ThirdpartyIntegrationTopicsList/>
      </div>
    </div>
  );
}

export default PythonIntegrationWithTwitter;

