import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function AdapterDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Adapter Design Pattern: Bridging the Gap Between Incompatible Interfaces</h1>
      
      <p>The Adapter Design Pattern is a structural design pattern that allows two incompatible interfaces to work together. It acts as a bridge between the client code and the target class, enabling the client to interact seamlessly with the target class through a common interface. The Adapter Pattern is particularly useful when integrating existing systems or classes with different interfaces without modifying their source code. In this tutorial, we will explore the Adapter Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Adapter Design Pattern:</b></h3>
			The Adapter Pattern enables communication between classes with different interfaces by creating an adapter that translates one interface to another. It acts as a wrapper around the target class, providing the required methods that the client code expects. This way, the client code can interact with the adapter as if it were working with the target class directly.</p>
			
			<p><b>Implementing Adapter in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Target interface expected by the client code
interface Target {
    void request();
}

// Adaptee: the existing class with an incompatible interface
class Adaptee {
    void specificRequest() {
        System.out.println("Adaptee's specificRequest() method called.");
    }
}

// Adapter: bridges the Adaptee's interface with the Target interface
class Adapter implements Target {
    private Adaptee adaptee;

    public Adapter(Adaptee adaptee) {
        this.adaptee = adaptee;
    }

    public void request() {
        adaptee.specificRequest();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Adapter in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Target interface expected by the client code
class Target:
    def request(self):
        pass

# Adaptee: the existing class with an incompatible interface
class Adaptee:
    def specific_request(self):
        print("Adaptee's specific_request() method called.")

# Adapter: bridges the Adaptee's interface with the Target interface
class Adapter(Target):
    def __init__(self, adaptee):
        self.adaptee = adaptee

    def request(self):
        self.adaptee.specific_request()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Adapter Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class AdapterUsage {
    public static void main(String[] args) {
        Adaptee adaptee = new Adaptee();
        Target adapter = new Adapter(adaptee);

        // Client code interacts with the Target interface
        adapter.request(); // Output: Adaptee's specificRequest() method called.
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    adaptee = Adaptee()
    adapter = Adapter(adaptee)

    # Client code interacts with the Target interface
    adapter.request() # Output: Adaptee's specific_request() method called.`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Adapter Design Pattern:</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`        +-------------------------+
        |         Target          |
        +-------------------------+
        | +request()              |
        +-------------------------+
                  ^
                  |
        +-------------------+
        |       Adapter     |
        +-------------------+
        | - adaptee: Adaptee |
        +-------------------+
        | +request()        |
        +-------------------+
                  ^
                  |
        +---------------------+
        |       Adaptee       |
        +---------------------+
        | +specificRequest() |
        +---------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Target is the interface that the client code expects. Adaptee is the existing class with an incompatible interface. The Adapter class bridges the gap between Target and Adaptee, allowing the client code to interact with Adaptee through the common Target interface.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Adapter Design Pattern provides a way to connect classes with different interfaces, making it easier to integrate existing code or third-party libraries without modifying their source. By implementing an adapter, the pattern allows the client code to interact with the target class through a common interface. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Adapter Pattern in your projects can lead to more flexible and reusable code, enabling seamless interactions between disparate components.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default AdapterDesignPattern;
