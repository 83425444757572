import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-system-design.css';
import SoftwareSystemDesignTopicsList from './SoftwareSystemDesignTopicsList'

function SoftwareArchitecturalPatterns() {
  return (
    <div className="software-system-design-container">
      <h1>Software Architectural Patterns</h1>
      <p>Architectural patterns play a crucial role in software system design as they provide proven solutions to common design challenges. In this tutorial, we will explore some essential architectural patterns with easy-to-understand examples to illustrate their concepts.</p>
      
      <p><b><h3>Monolithic Architecture</h3></b></p>

      <p>Monolithic architecture is a traditional approach where all components of a software application are tightly coupled and run as a single unit. It is easy to develop but lacks scalability and maintainability. Let's consider an example of a simple online shopping application.
			</p>
			<p>Example: In a monolithic architecture, an online shopping application contains a single codebase that handles user interface, business logic, and database access. All features, such as product listing, shopping cart, and order processing, are within the same application.</p>
        <SyntaxHighlighter  language="none" style={nord}>
{` 
+------------------------------------+
|      Online Shopping Website       |
+------------------------------------+
|                                    |
|          UI and Logic              |
|                                    |
|                                    |
|   +------------------------+       |
|   |      Database          |       |
|   +------------------------+       |
|                                    |
+------------------------------------+          
`}
        </SyntaxHighlighter>
        <p>In the monolithic architecture, all components, including the user interface, business logic, and database access, are tightly coupled within a single application.</p>
      <br/>

      <p><b><h3>Microservices Architecture</h3></b></p>

      <p>Microservices architecture breaks down a software application into small, independent services that communicate with each other through APIs. Each service focuses on a specific business capability, offering better scalability and maintainability.
			</p>
			<p>Example: In the same online shopping application, each functionality, like product catalog, user management, and order processing, would be a separate microservice. These microservices can be developed, deployed, and scaled independently, making the system more flexible.</p>
        <SyntaxHighlighter  language="none" style={nord}>
{` 
+----------------------------------------+
|           Online Shopping              |
|           Microservices                |
+----------------------------------------+
|  Product Catalog Microservice          |
|  +---------------------------------+   |
|  |        Database                 |   |
|  +---------------------------------+   |
|                                        |
|  User Management Microservice          |
|  +---------------------------------+   |
|  |        Database                 |   |
|  +---------------------------------+   |
|                                        |
|  Order Processing Microservice         |
|  +---------------------------------+   |
|  |        Database                 |   |
|  +---------------------------------+   |
|                                        |
+----------------------------------------+        
`}
        </SyntaxHighlighter>
        <p>In the microservices architecture, the application is broken down into smaller independent microservices, each with its own database and functionality.</p>
      <br/>
      
      <p><b><h3>Layered Architecture</h3></b></p>

      <p>Layered architecture organizes a software application into multiple layers, each responsible for specific functionality. This separation improves maintainability and reusability.
			</p>
			<p>Example: In a layered architecture, an application might have three layers - Presentation layer for user interface, Business layer for logic processing, and Data layer for database interactions. Each layer communicates with the layer above or below it.</p>
        <SyntaxHighlighter  language="none" style={nord}>
{` 
+------------------------------------+
|    Presentation Layer (UI)         |
+------------------------------------+
|                                    |
|    Business Layer (Logic)          |
|                                    |
|                                    |
|      Data Layer (Database)         |
|                                    |
+------------------------------------+        
`}
        </SyntaxHighlighter>
        <p>In the layered architecture, the application is organized into different layers, with each layer responsible for specific functionality.</p>
      <br/>

      <p><b><h3>Event-Driven Architecture</h3></b></p>

      <p>Event-Driven Architecture (EDA) focuses on asynchronous communication between components through events. When an event occurs, it triggers relevant actions across the system.
			</p>
			<p>Example: In a messaging application, when a user sends a message, an event is generated. This event is then processed by various components, such as the message sender, message receiver, and notification service, which respond accordingly.</p>
        <SyntaxHighlighter  language="none" style={nord}>
{` 
+----------------------------------------+
|          Messaging Application         |
+----------------------------------------+
|          Message Sender                |
|                                        |
|            | Event: Message Sent       |
|            V                           |
|          Message Receiver              |
|                                        |
|          Notification Service          |
|                                        |
+----------------------------------------+   
`}
        </SyntaxHighlighter>
        <p>In the event-driven architecture, components communicate asynchronously through events. In this example, when a user sends a message, it triggers the "Message Sent" event, which is then processed by the message receiver and notification service.</p>
      <br/>

      <p><b><h3>Service-Oriented Architecture (SOA)</h3></b></p>

      <p>SOA is an architectural style that uses services as fundamental building blocks, promoting loose coupling between software components. These services can be distributed across different platforms and technologies.
			</p>
			<p>Example: In an e-commerce website, the payment processing service could be a standalone service that communicates with the main application through APIs. This separation allows using different payment processors without affecting the rest of the application.</p>
        <SyntaxHighlighter  language="none" style={nord}>
{` 
+-------------------------------------------+
|       E-commerce Website                  |
+-------------------------------------------+
|        |           |            |         |
|        V           V            V         |
|  Payment Service    Product Service       |
|        |           |            |         |
|        V           V            V         |
|     Payment Gateway     Product Catalog   |
|                                           |
+-------------------------------------------+
`}
        </SyntaxHighlighter>
        <p>In the service-oriented architecture, services act as fundamental building blocks. In this example, the main e-commerce website communicates with the Payment Service and Product Service through APIs, which interact with Payment Gateway and Product Catalog services, respectively.</p>
      <br/>
      

      <h2><b>Conclusion</b></h2>
      <p>Architectural patterns are essential for designing robust and scalable software systems. By understanding these patterns and their examples, you can make informed decisions when designing your next software application. Choose the architecture that best suits your project's needs and provides a solid foundation for future growth and maintenance.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <SoftwareSystemDesignTopicsList/>
      </div>
    </div>
  );
}

export default SoftwareArchitecturalPatterns;
