import React from 'react';

function ContactUs() {
  return (
    <div>
      <h1>Contact US</h1>
      {/* Your home page content */}
      <h4>Email Id: info@lupuk.com</h4>
    </div>
  );
}

export default ContactUs;
