import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-principle.css';
import SoftwareDesignPrincipleTopicsList from './SoftwareDesignPrincipleTopicsList'

function LiskovSubstitutionPrinciple() {
  return (
    <div className="software-design-principle-container">
      <h1>Liskov Substitution Principle (LSP)</h1>
     
      <p>The Liskov Substitution Principle (LSP) states that objects of a superclass should be replaceable with objects of its subclasses without affecting the correctness of the program. In other words, the behavior of the program should not be altered when using a subclass instead of the superclass.
			</p>
			<p><b>Example in Java:</b>Consider a class hierarchy for geometric shapes:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Not following LSP
public abstract class Shape {
    public abstract double calculateArea();
}

public class Circle extends Shape {
    private double radius;

    public Circle(double radius) {
        this.radius = radius;
    }

    public double calculateArea() {
        return Math.PI * radius * radius;
    }
}

public class Rectangle extends Shape {
    private double width;
    private double height;

    public Rectangle(double width, double height) {
        this.width = width;
        this.height = height;
    }

    public double calculateArea() {
        return width * height;
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow LSP, we ensure that the subclass does not change the behavior of the superclass:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Following LSP
public interface Shape {
    double calculateArea();
}

public class Circle implements Shape {
    private double radius;

    public Circle(double radius) {
        this.radius = radius;
    }

    public double calculateArea() {
        return Math.PI * radius * radius;
    }
}

public class Rectangle implements Shape {
    private double width;
    private double height;

    public Rectangle(double width, double height) {
        this.width = width;
        this.height = height;
    }

    public double calculateArea() {
        return width * height;
    }
}`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Example in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Python
# Not following LSP
class Shape:
    def calculate_area(self):
        pass

class Circle(Shape):
    def __init__(self, radius):
        self.radius = radius

    def calculate_area(self):
        return math.pi * self.radius * self.radius

class Rectangle(Shape):
    def __init__(self, width, height):
        self.width = width
        self.height = height

    def calculate_area(self):
        return self.width * self.height`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow LSP in Python, we ensure that the subclass does not change the behavior of the superclass:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Following LSP
class Shape:
    def calculate_area(self):
        pass

class Circle(Shape):
    def __init__(self, radius):
        self.radius = radius

    def calculate_area(self):
        return math.pi * self.radius * self.radius

class Rectangle(Shape):
    def __init__(self, width, height):
        self.width = width
        self.height = height

    def calculate_area(self):
        return self.width * self.height`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`         Java                           Python
+-----------------------+     +-----------------------+
|         Shape         |     |         Shape         |
+-----------------------+     +-----------------------+
| calculateArea()       |     | calculate_area()      |
+-----------------------+     +-----------------------+
        |                              |
        |                              |
+--------------------+        +--------------------+
|       Circle       |        |       Circle       |
+--------------------+        +--------------------+
| calculateArea()   |         | calculate_area()   |
+--------------------+        +--------------------+
        |                              |
        |                              |
+----------------------+     +----------------------+
|      Rectangle       |     |      Rectangle       |
+----------------------+     +----------------------+
| calculateArea()     |      | calculate_area()     |
+----------------------+     +----------------------+`}
        </SyntaxHighlighter>
      <br/>
      <h2><b>Conclusion</b></h2>
      <p>In this tutorial, we have explored essential design principles in software system design, including SOLID (Single Responsibility, Open-Closed, Liskov Substitution, Interface Segregation, Dependency Inversion), DRY (Don't Repeat Yourself), KISS (Keep It Simple, Stupid), and YAGNI (You Aren't Gonna Need It). By adhering to these principles, developers can create well-structured, maintainable, and flexible software systems. The examples in both Java and Python, along with the diagrams, have illustrated the practical application of these principles. By incorporating these design principles into your software development practices, you can build high-quality and reliable software systems.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <SoftwareDesignPrincipleTopicsList/>
      </div>
    </div>
  );
}

export default LiskovSubstitutionPrinciple;
