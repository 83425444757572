import React from 'react';
import '../thirdparty-integgration-topics-list.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ThirdpartyIntegrationTopicsList from '../ThirdpartyIntegrationTopicsList'

function PythonIntegrationWithLinkedIn() {
  return (
    <div className="thirdparty-integgration-container">
      <h1>Python Integration with LinkedIn API</h1>
      <p>LinkedIn provides an API that allows developers to interact with LinkedIn's platform programmatically. In this tutorial, we will demonstrate how to integrate Python with the LinkedIn API to access user data, post updates, and perform various actions on LinkedIn.</p>

      <p><h3><b>Prerequisites</b></h3>
      <ul>
		<li><p>Sign up for a LinkedIn Developer Account: Go to <a href="https://www.linkedin.com/developers/">LinkedIn Developer</a> and sign up for a LinkedIn developer account. Once you have an account, create a new application to obtain the necessary API credentials.</p></li>
		
    <li><p>Obtain LinkedIn API Credentials: After creating a LinkedIn application, you'll get a Client ID and Client Secret. These credentials are required to authenticate your requests to the LinkedIn API.</p></li>
	
		<li><p>Install Required Libraries: Ensure you have Python installed on your machine. You will also need the requests library to make API requests. If you don't have it installed, you can do so using the following command:pip install requests</p></li>
		</ul>
    </p>

      <p><h3><b>1. Set Up LinkedIn Developer Account and Obtain API Credentials: </b></h3>
      Sign up for a LinkedIn developer account at <a href="https://www.linkedin.com/developers/">LinkedIn Developer</a>. After creating an application, you'll obtain a Client ID and Client Secret from the LinkedIn developer dashboard.</p>

    <p><h3><b>2. Authenticate with LinkedIn API: </b></h3>
		In order to make requests to the LinkedIn API, we need to authenticate our application using OAuth 2.0. The exact authentication flow depends on the type of application you are building (web-based, server-based, etc.). For this tutorial, we'll use the Client Credentials Flow for simplicity.</p>
    <p>Here's an example Python code to authenticate using the Client Credentials Flow.</p>
  <SyntaxHighlighter language="python" style={nord}>
{`import requests

CLIENT_ID = "YOUR_CLIENT_ID"
CLIENT_SECRET = "YOUR_CLIENT_SECRET"

def get_access_token():
    auth_url = "https://www.linkedin.com/oauth/v2/accessToken"
    data = {
        "grant_type": "client_credentials",
        "client_id": CLIENT_ID,
        "client_secret": CLIENT_SECRET,
    }

    response = requests.post(auth_url, data=data)
    return response.json()["access_token"]

access_token = get_access_token()
print("Access Token:", access_token)`}
  </SyntaxHighlighter>

  
<p><h3><b>3. Access User Data:</b></h3>
Once authenticated, you can use the access token to access user data from LinkedIn. Depending on your application's scope and permissions, you can retrieve user profiles, connections, and other relevant information.</p>
		
<p>Here's an example Python code to fetch a user's profile using the LinkedIn API.</p>
<SyntaxHighlighter language="python" style={nord}>
{`import requests

def get_user_profile(access_token):
    headers = {"Authorization": f"Bearer {access_token}"}
    response = requests.get("https://api.linkedin.com/v2/me", headers=headers)
    return response.json()

user_profile = get_user_profile(access_token)
print("User Profile:", user_profile)`}
</SyntaxHighlighter>  

<p><h3><b>4. Post a Share Update on LinkedIn:</b></h3>
You can also use the LinkedIn API to post updates on behalf of a user. This allows your application to share content, such as articles or status updates, directly on LinkedIn.</p>
		
<p>Here's an example Python code to post a share update on LinkedIn.</p>
<SyntaxHighlighter language="python" style={nord}>
{`import requests

def post_share_update(access_token):
    headers = {
        "Authorization": f"Bearer {access_token}",
        "Content-Type": "application/json",
    }
    share_content = {
        "author": "urn:li:person:YOUR_LINKEDIN_USER_ID",
        "lifecycleState": "PUBLISHED",
        "specificContent": {
            "com.linkedin.ugc.ShareContent": {
                "shareCommentary": {"text": "Hello from my Python app!"},
                "shareMediaCategory": "NONE",
            }
        },
        "visibility": {"com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC"},
    }

    response = requests.post("https://api.linkedin.com/v2/ugcPosts", headers=headers, json=share_content)
    return response.json()

share_response = post_share_update(access_token)
print("Share Update Response:", share_response)`}
</SyntaxHighlighter>  

<p><b>Here's the complete Python code for integrating with the LinkedIn API, including authentication and examples for accessing user data and posting a share update.</b></p> 
<SyntaxHighlighter language="python" style={nord}>
{`import requests

# Replace with your LinkedIn API credentials
CLIENT_ID = "YOUR_CLIENT_ID"
CLIENT_SECRET = "YOUR_CLIENT_SECRET"

def get_access_token():
    auth_url = "https://www.linkedin.com/oauth/v2/accessToken"
    data = {
        "grant_type": "client_credentials",
        "client_id": CLIENT_ID,
        "client_secret": CLIENT_SECRET,
    }

    response = requests.post(auth_url, data=data)
    return response.json()["access_token"]

def get_user_profile(access_token):
    headers = {"Authorization": f"Bearer {access_token}"}
    response = requests.get("https://api.linkedin.com/v2/me", headers=headers)
    return response.json()

def post_share_update(access_token):
    headers = {
        "Authorization": f"Bearer {access_token}",
        "Content-Type": "application/json",
    }
    share_content = {
        "author": "urn:li:person:YOUR_LINKEDIN_USER_ID",
        "lifecycleState": "PUBLISHED",
        "specificContent": {
            "com.linkedin.ugc.ShareContent": {
                "shareCommentary": {"text": "Hello from my Python app!"},
                "shareMediaCategory": "NONE",
            }
        },
        "visibility": {"com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC"},
    }

    response = requests.post("https://api.linkedin.com/v2/ugcPosts", headers=headers, json=share_content)
    return response.json()

def main():
    access_token = get_access_token()
    print("Access Token:", access_token)

    user_profile = get_user_profile(access_token)
    print("User Profile:", user_profile)

    share_response = post_share_update(access_token)
    print("Share Update Response:", share_response)

if __name__ == "__main__":
    main()`}
</SyntaxHighlighter>  
      <h2><b>Conclusion</b></h2>
      <p>By following this tutorial, you've learned how to integrate Python with the LinkedIn API. You can now authenticate your application, access user data, and perform actions like posting updates on LinkedIn. Keep exploring the LinkedIn API documentation for more features and use cases.
      </p>
      
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <ThirdpartyIntegrationTopicsList/>
      </div>
    </div>
  );
}

export default PythonIntegrationWithLinkedIn;