import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-principle.css';
import SoftwareDesignPrincipleTopicsList from './SoftwareDesignPrincipleTopicsList'

function SingleResponsibilityPrinciple() {
  return (
    <div className="software-design-principle-container">
      <h1>Single Responsibility Principle (SRP)</h1>
      
      <p>The Single Responsibility Principle (SRP) states that a class should have only one reason to change. It means that each class should have a single responsibility, making it easier to maintain and understand.
			</p>
			<p><b>Example in Java:</b>Consider a class that handles both user authentication and user data retrieval.</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Not following SRP
public class UserHandler {
    public boolean authenticateUser(String username, String password) {
        // Code to authenticate user
    }

    public User getUserData(String username) {
        // Code to retrieve user data
    }
} `}
        </SyntaxHighlighter>
        <br/>
        <p>To follow SRP, we separate the responsibilities into two classes in java:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Following SRP
public class UserAuthenticator {
    public boolean authenticateUser(String username, String password) {
        // Code to authenticate user
    }
}

public class UserDataRetriever {
    public User getUserData(String username) {
        // Code to retrieve user data
    }
}`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Example in Python:</b>Consider a class that handles both user authentication and user data retrieval.</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Python
# Not following SRP
class UserHandler:
    def authenticate_user(self, username, password):
        # Code to authenticate user

    def get_user_data(self, username):
        # Code to retrieve user data`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow SRP in Python, we separate the responsibilities into two classes:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Following SRP
class UserAuthenticator:
    def authenticate_user(self, username, password):
        # Code to authenticate user

class UserDataRetriever:
    def get_user_data(self, username):
        # Code to retrieve user data`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram</b></p>
      <SyntaxHighlighter  language="python" style={nord}>
{`         Java                             Python
    +---------------+                +---------------+
    | UserHandler   |                | UserHandler   |
    +---------------+                +---------------+
    |               |                |               |
    +---------------+                +---------------+
            |                                |
            |                                |
    +----------------------+         +----------------------+
    | UserAuthenticator   |          | UserAuthenticator    |
    +----------------------+         +----------------------+
    | authenticateUser()  |          | authenticate_user()  |
    +----------------------+         +----------------------+
            |                                |
            |                                |
    +--------------------+           +--------------------+
    | UserDataRetriever  |           | UserDataRetriever  |
    +--------------------+           +--------------------+
    | getUserData()      |           | get_user_data()    |
    +--------------------+           +--------------------+	`}
        </SyntaxHighlighter>
      <br/>
      <h2><b>Conclusion</b></h2>
      <p>In this tutorial, we have explored essential design principles in software system design, including SOLID (Single Responsibility, Open-Closed, Liskov Substitution, Interface Segregation, Dependency Inversion), DRY (Don't Repeat Yourself), KISS (Keep It Simple, Stupid), and YAGNI (You Aren't Gonna Need It). By adhering to these principles, developers can create well-structured, maintainable, and flexible software systems. The examples in both Java and Python, along with the diagrams, have illustrated the practical application of these principles. By incorporating these design principles into your software development practices, you can build high-quality and reliable software systems.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <SoftwareDesignPrincipleTopicsList/>
      </div>
    </div>
  );
}

export default SingleResponsibilityPrinciple;
