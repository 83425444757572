import React from 'react';
import { Link } from 'react-router-dom';
import './advance-sql-topics-list.css';

function AdvanceSqlTopicsList() {
  const topics = [
   
    { id: 1, name: 'Advance SQL CheatSheet', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql' },
    { id: 2, name: 'Subqueries', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/subqueries' },
    { id: 3, name: 'Common Table Expressions', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/common-expression' },
    { id: 3, name: 'Window Functions', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/window-function' },
    { id: 3, name: 'Pivoting Data', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/pivoting-data' },
    { id: 3, name: 'String Aggregation', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/string-aggregation' },
    { id: 3, name: 'Date and Time Manipulation', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/date-time' },
    { id: 3, name: 'Conditional Aggregation', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/conditional-agg' },
    { id: 3, name: 'Using Common Table Expressions (CTEs) with Recursive Queries', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/common-expression' },
    { id: 3, name: 'Stored Procedures', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/stored-procedures' },
    { id: 3, name: 'Transaction Management', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/transaction-management' },
    { id: 3, name: 'Indexes and Performance Optimization', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/index-performance' },
    { id: 3, name: 'Handling NULL Values', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/handling-null' },
    { id: 3, name: 'Working with JSON Data', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/advance-sql/with-json' },
    
    
    // Add more topics here
  ];

  return (
    <div className="topics-list-container">
      <h2>Advance SQL</h2>
      <div className="topics-list">
        {topics.map(topic => (
          <Link key={topic.id} to={topic.route} className="topic-item">
            <img src={topic.imageUrl} alt={topic.name} />
            <p>{topic.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default AdvanceSqlTopicsList;
