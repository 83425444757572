import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function ObjectDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Object Pool Pattern: Reusing Objects for Improved Performance</h1>
      
      <p>The Object Pool Pattern is a creational design pattern that manages a pool of pre-initialized and reusable objects. Instead of creating and destroying objects frequently, the Object Pool Pattern allows you to recycle objects to improve performance and reduce the overhead of object creation. This pattern is especially useful when the cost of object instantiation is high, and objects are needed for short periods repeatedly. In this tutorial, we will explore the Object Pool Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Object Pool Pattern:</b></h3>
			The Object Pool Pattern aims to reduce the cost of object creation by recycling existing objects. It maintains a pool of objects that are created beforehand and keeps track of their availability. When a new object is needed, the pool provides an available object from the pool rather than creating a new one. After use, the object is returned to the pool to be reused.</p>
			
			<p><b>Implementing Object Pool in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`import java.util.ArrayList;
import java.util.List;

// Object Pool
class ObjectPool<T> {
    private List<T> pool;
    private int maxSize;

    public ObjectPool(int maxSize) {
        this.maxSize = maxSize;
        this.pool = new ArrayList<>();
    }

    public synchronized T acquire() {
        if (pool.isEmpty()) {
            return createObject();
        } else {
            return pool.remove(0);
        }
    }

    public synchronized void release(T obj) {
        if (pool.size() < maxSize) {
            pool.add(obj);
        }
    }

    private T createObject() {
        // Implement object creation logic here
        return null;
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Object Pool in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Object Pool
class ObjectPool:
    def __init__(self, max_size):
        self.max_size = max_size
        self.pool = []

    def acquire(self):
        if not self.pool:
            return self.create_object()
        else:
            return self.pool.pop(0)

    def release(self, obj):
        if len(self.pool) < self.max_size:
            self.pool.append(obj)

    def create_object(self):
        # Implement object creation logic here
        return None`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Object Pool Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class ObjectPoolUsage {
    public static void main(String[] args) {
        ObjectPool<String> pool = new ObjectPool<>(5);

        String obj1 = pool.acquire();
        String obj2 = pool.acquire();
        String obj3 = pool.acquire();

        // Use the objects
        System.out.println(obj1); // Output: null
        System.out.println(obj2); // Output: null
        System.out.println(obj3); // Output: null

        pool.release(obj1);
        pool.release(obj2);
        pool.release(obj3);

        String obj4 = pool.acquire();
        String obj5 = pool.acquire();

        // Use the objects again after releasing them back to the pool
        System.out.println(obj4); // Output: null
        System.out.println(obj5); // Output: null
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    pool = ObjectPool(5)

    obj1 = pool.acquire()
    obj2 = pool.acquire()
    obj3 = pool.acquire()

    # Use the objects
    print(obj1) # Output: None
    print(obj2) # Output: None
    print(obj3) # Output: None

    pool.release(obj1)
    pool.release(obj2)
    pool.release(obj3)

    obj4 = pool.acquire()
    obj5 = pool.acquire()

    # Use the objects again after releasing them back to the pool
    print(obj4) # Output: None
    print(obj5) # Output: None`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Object Pool Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`      +----------------------+
      |     ObjectPool       |
      +----------------------+
      | - pool: List<T>      |
      | - maxSize: int       |
      +----------------------+
      | +acquire(): T        |
      | +release(obj: T)     |
      +----------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, ObjectPool is the class that maintains a pool of objects. The acquire() method provides an available object from the pool, and the release(obj) method returns the object back to the pool for reuse.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Object Pool Pattern offers an efficient way to reuse objects and reduce the overhead of object creation in situations where object instantiation is expensive. By maintaining a pool of pre-initialized objects, the pattern allows for efficient object recycling and improved performance. We explored its implementation in both Java and Python, along with a simple usage example. Utilizing the Object Pool Pattern can lead to significant performance improvements in applications with high object creation and disposal rates.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}


export default ObjectDesignPattern;
