import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function MementoDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Memento Design Pattern: Capturing and Restoring Object States</h1>
      
      <p>The Memento Design Pattern is a behavioral design pattern that allows capturing and restoring an object's internal state without exposing its details. It provides a mechanism to save the state of an object at a particular moment and later restore it when needed. This pattern is particularly useful in scenarios where you want to implement undo/redo functionality or manage historical versions of an object. In this tutorial, we will explore the Memento Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Memento Design Pattern:</b></h3>
			The Memento Pattern involves three main components: Originator, Memento, and Caretaker. The Originator is the object whose state needs to be saved and restored. The Memento is an immutable object that stores the state of the Originator. The Caretaker is responsible for holding and managing the Mementos.</p>
			
			<p><b>Implementing Memento in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Memento: stores the state of the Originator
class Memento {
    private String state;

    public Memento(String state) {
        this.state = state;
    }

    public String getState() {
        return state;
    }
}

// Originator: the object whose state needs to be saved and restored
class Originator {
    private String state;

    public void setState(String state) {
        this.state = state;
    }

    public String getState() {
        return state;
    }

    public Memento saveStateToMemento() {
        return new Memento(state);
    }

    public void restoreStateFromMemento(Memento memento) {
        this.state = memento.getState();
    }
}

// Caretaker: manages and stores Mementos
class Caretaker {
    private List<Memento> mementos = new ArrayList<>();

    public void addMemento(Memento memento) {
        mementos.add(memento);
    }

    public Memento getMemento(int index) {
        return mementos.get(index);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Memento in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Memento: stores the state of the Originator
class Memento:
    def __init__(self, state):
        self.state = state

    def get_state(self):
        return self.state

# Originator: the object whose state needs to be saved and restored
class Originator:
    def __init__(self):
        self.state = ""

    def set_state(self, state):
        self.state = state

    def get_state(self):
        return self.state

    def save_state_to_memento(self):
        return Memento(self.state)

    def restore_state_from_memento(self, memento):
        self.state = memento.get_state()

# Caretaker: manages and stores Mementos
class Caretaker:
    def __init__(self):
        self.mementos = []

    def add_memento(self, memento):
        self.mementos.append(memento)

    def get_memento(self, index):
        return self.mementos[index]`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Memento Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class MementoUsage {
    public static void main(String[] args) {
        Originator originator = new Originator();
        Caretaker caretaker = new Caretaker();

        originator.setState("State 1");
        caretaker.addMemento(originator.saveStateToMemento());

        originator.setState("State 2");
        caretaker.addMemento(originator.saveStateToMemento());

        originator.setState("State 3");
        caretaker.addMemento(originator.saveStateToMemento());

        System.out.println("Current State: " + originator.getState());

        originator.restoreStateFromMemento(caretaker.getMemento(1));
        System.out.println("Restored State: " + originator.getState());
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    originator = Originator()
    caretaker = Caretaker()

    originator.set_state("State 1")
    caretaker.add_memento(originator.save_state_to_memento())

    originator.set_state("State 2")
    caretaker.add_memento(originator.save_state_to_memento())

    originator.set_state("State 3")
    caretaker.add_memento(originator.save_state_to_memento())

    print("Current State:", originator.get_state())

    originator.restore_state_from_memento(caretaker.get_memento(1))
    print("Restored State:", originator.get_state())`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Memento Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`   +----------------+        +-------------------+
   |   Originator   |<>------|      Memento      |
   +----------------+        +-------------------+
   | -state: String |        | +getState():      |
   | +setState():   |        |   String          |
   |   String       |        +-------------------+
   | +saveStateToMemento(): Memento |
   |   Memento       |        +-------------------+
   +----------------+                  ^
                                      |
                             +-------------------+
                             |    Caretaker     |
                             +-------------------+
                             | -mementos: List  |
                             | +addMemento():   |
                             |   void           |
                             | +getMemento():   |
                             |   Memento        |
                             +-------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Originator is the object whose state is saved and restored, and Memento is the immutable object that stores the state. Caretaker is responsible for holding and managing the Mementos.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Memento Design Pattern provides a way to capture and restore an object's internal state without exposing its details. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Memento Pattern can lead to more manageable and undo/redo-friendly code, especially in applications that require state snapshots and history tracking.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default MementoDesignPattern;
