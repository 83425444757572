import React from 'react';
import { Link } from 'react-router-dom';
import './software-design-principle.css';
import SoftwareDesignPrincipleTopicsList from './SoftwareDesignPrincipleTopicsList'

function SoftwareDesignPrinciples() {
  return (
    <div className="software-design-principle-container">
      <h1>Software Design Principles</h1>
      {/* Your home page content */}
      <p>Software design principles are fundamental guidelines that help software developers create maintainable, scalable, and flexible software systems. In this tutorial, we will explore essential design principles such as <b>SOLID (Single Responsibility, Open-Closed, Liskov Substitution, Interface Segregation, Dependency Inversion), DRY (Don't Repeat Yourself), KISS (Keep It Simple, Stupid), and YAGNI (You Aren't Gonna Need It)</b>. We will use examples in both <b>Java and Python </b> to demonstrate the application of these principles. Throughout the tutorial, we will use diagrams to visualize the relationships between classes and components.</p>
      
      <p><b><h3>▸<Link to="/single-responsibility-principle">Single Responsibility Principle (SRP)</Link></h3></b>: The Single Responsibility Principle (SRP) states that a class should have only one reason to change. It means that each class should have a single responsibility, making it easier to maintain and understand.</p>
      
      <p><b><h3>▸<Link to="/open-closed-principle">Open-Closed Principle (OCP)</Link></h3></b>: The Open-Closed Principle (OCP) states that software entities should be open for extension but closed for modification. It means that you should be able to add new features without modifying existing code.</p>
      

      <p><b><h3>▸<Link to="/liskov-substitution-principle">Liskov Substitution Principle (LSP)</Link></h3></b>: The Liskov Substitution Principle (LSP) states that objects of a superclass should be replaceable with objects of its subclasses without affecting the correctness of the program. In other words, the behavior of the program should not be altered when using a subclass instead of the superclass.</p>
      

      <p><b><h3>▸<Link to="/interface-segregation-principle">Interface Segregation Principle (ISP)</Link></h3></b>: The Interface Segregation Principle (ISP) states that a client should not be forced to depend on interfaces it does not use. It encourages creating smaller and more focused interfaces.</p>
      
     
      <p><b><h3>▸<Link to="/dependency-inversion-principle">Dependency Inversion Principle (DIP)</Link></h3></b>: The Dependency Inversion Principle (DIP) states that high-level modules should not depend on low-level modules. Both should depend on abstractions. It promotes the use of interfaces or abstract classes to reduce coupling.</p>
      
     
      <p><b><h3>▸<Link to="/donot-repeat-yourself-principle">DRY (Don't Repeat Yourself) Principle</Link></h3></b>: The DRY (Don't Repeat Yourself) principle advocates for avoiding code duplication and promoting code reuse. It improves maintainability and reduces the chance of bugs.</p>
      
      <p><b><h3>▸<Link to="/keep-it-simple-stupid-principle">KISS (Keep It Simple, Stupid) Principle</Link></h3></b>: The KISS (Keep It Simple, Stupid) principle suggests that simplicity is preferable to complexity. It encourages developers to choose the simplest solution that meets the requirements.</p>
      
      <p><b><h3>▸<Link to="/you-are-not-gonna-need-it-principle">YAGNI (You Aren't Gonna Need It) Principle</Link></h3></b>: The YAGNI (You Aren't Gonna Need It) principle advises against implementing functionality until it is necessary. It prevents overengineering and unnecessary complexity.</p>	
      
      <h2><b>Conclusion</b></h2>
      <p>In this tutorial, we have explored essential design principles in software system design, including SOLID (Single Responsibility, Open-Closed, Liskov Substitution, Interface Segregation, Dependency Inversion), DRY (Don't Repeat Yourself), KISS (Keep It Simple, Stupid), and YAGNI (You Aren't Gonna Need It). By adhering to these principles, developers can create well-structured, maintainable, and flexible software systems. The examples in both Java and Python, along with the diagrams, have illustrated the practical application of these principles. By incorporating these design principles into your software development practices, you can build high-quality and reliable software systems.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <SoftwareDesignPrincipleTopicsList/>
      </div>
    </div>
  );
}

export default SoftwareDesignPrinciples;
