import React from 'react';
import { Link } from 'react-router-dom';
import './software-design-principle-topics-list.css';

function SoftwareDesignPrincipleTopicsList() {
  const topics = [
   
    { id: 1, name: 'Software Design Principles', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/software-design-principles' },
    { id: 2, name: 'Single Responsibility Principle (SRP)', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/single-responsibility-principle' },
    { id: 3, name: 'Open-Closed Principle (OCP)', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/open-closed-principle' },
    { id: 4, name: 'Liskov Substitution Principle (LSP)', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/liskov-substitution-principle' },
    { id: 5, name: 'Interface Segregation Principle (ISP)', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/interface-segregation-principle' },
    { id: 6, name: 'Dependency Inversion Principle (DIP)', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/dependency-inversion-principle' },
    { id: 7, name: 'DRY (Donot Repeat Yourself) Principle', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/donot-repeat-yourself-principle' },
    { id: 8, name: 'KISS (Keep It Simple, Stupid) Principle', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/keep-it-simple-stupid-principle' },
    { id: 9, name: 'YAGNI (You Arenot Gonna Need It) Principle', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/you-are-not-gonna-need-it-principle' },
   
    
   
    
    
    // Add more topics here
  ];

  return (
    <div className="topics-list-container">
      <h2>Software Design Principle Topic List</h2>
      <div className="topics-list">
        {topics.map(topic => (
          <Link key={topic.id} to={topic.route} className="topic-item">
            <img src={topic.imageUrl} alt={topic.name} />
            <p>{topic.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SoftwareDesignPrincipleTopicsList;
