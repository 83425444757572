import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function StrategyDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Strategy Design Pattern: Flexible Algorithm Selection</h1>
      
      <p>The Strategy Design Pattern is a behavioral design pattern that allows clients to choose from a family of algorithms at runtime. It promotes loose coupling by defining a set of interchangeable algorithms and encapsulating each one in a separate class. The Strategy Pattern enables the client to select and switch between different strategies without modifying its code. In this tutorial, we will explore the Strategy Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Strategy Design Pattern:</b></h3>
			The Strategy Pattern separates the behavior of an algorithm from its implementation, making it easy to add new strategies or modify existing ones independently. It follows the "open-closed principle," allowing the addition of new strategies without modifying the client code. The pattern is useful when an application requires different variants of an algorithm or when it needs to support dynamic behavior selection.</p>
			
			<p><b>Implementing Strategy in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Strategy: defines the interface for all concrete strategies
interface SortingStrategy {
    void sort(int[] array);
}

// ConcreteStrategyA: implements the SortingStrategy interface
class BubbleSort implements SortingStrategy {
    public void sort(int[] array) {
        System.out.println("Using Bubble Sort");
        // Implementation of Bubble Sort algorithm
    }
}

// ConcreteStrategyB: implements the SortingStrategy interface
class MergeSort implements SortingStrategy {
    public void sort(int[] array) {
        System.out.println("Using Merge Sort");
        // Implementation of Merge Sort algorithm
    }
}

// Context: maintains a reference to the chosen strategy and performs sorting
class SortContext {
    private SortingStrategy strategy;

    public void setStrategy(SortingStrategy strategy) {
        this.strategy = strategy;
    }

    public void sortArray(int[] array) {
        strategy.sort(array);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Strategy in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Strategy: defines the interface for all concrete strategies
class SortingStrategy:
    def sort(self, array):
        pass

# ConcreteStrategyA: implements the SortingStrategy interface
class BubbleSort(SortingStrategy):
    def sort(self, array):
        print("Using Bubble Sort")
        # Implementation of Bubble Sort algorithm

# ConcreteStrategyB: implements the SortingStrategy interface
class MergeSort(SortingStrategy):
    def sort(self, array):
        print("Using Merge Sort")
        # Implementation of Merge Sort algorithm

# Context: maintains a reference to the chosen strategy and performs sorting
class SortContext:
    def __init__(self):
        self.strategy = None

    def set_strategy(self, strategy):
        self.strategy = strategy

    def sort_array(self, array):
        self.strategy.sort(array)`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Strategy Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class StrategyUsage {
    public static void main(String[] args) {
        SortContext context = new SortContext();

        int[] array1 = {4, 2, 7, 1, 5};
        int[] array2 = {9, 3, 6, 8, 0};

        context.setStrategy(new BubbleSort());
        context.sortArray(array1);

        context.setStrategy(new MergeSort());
        context.sortArray(array2);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    context = SortContext()

    array1 = [4, 2, 7, 1, 5]
    array2 = [9, 3, 6, 8, 0]

    context.set_strategy(BubbleSort())
    context.sort_array(array1)

    context.set_strategy(MergeSort())
    context.sort_array(array2)`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Strategy Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`   +-------------------+        +----------------------+
   |     Strategy      |<>------|   SortingStrategy    |
   +-------------------+        +----------------------+
   | +sort(array): void|        | +sort(array): void   |
   +-------------------+        +----------------------+
               ^
               |
    +--------------------+
    |    BubbleSort      |
    +--------------------+
    | +sort(array): void |
    +--------------------+
               ^
               |
    +--------------------+
    |    MergeSort       |
    +--------------------+
    | +sort(array): void |
    +--------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Strategy is the strategy interface, and SortingStrategy, BubbleSort, and MergeSort are the concrete strategy implementations. The SortContext maintains a reference to the chosen strategy.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Strategy Design Pattern allows clients to choose from a family of algorithms at runtime, making the code more flexible and maintainable. It promotes loose coupling by encapsulating algorithms in separate classes. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Strategy Pattern can lead to cleaner and more adaptable code, especially when dealing with multiple variants of an algorithm or dynamic behavior selection.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default StrategyDesignPattern;
