import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function TemplateDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Template Design Pattern: Defining the Skeleton of an Algorithm</h1>
      
      <p>The Template Design Pattern is a behavioral design pattern that defines the outline or skeleton of an algorithm in a method while allowing its steps to be implemented by subclasses. It promotes code reuse by encapsulating the common steps of the algorithm in a base class and letting subclasses override specific steps as needed. The Template Pattern is useful when different implementations share a common workflow but have variations in certain steps. In this tutorial, we will explore the Template Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Template Design Pattern:</b></h3>
			The Template Pattern consists of a template method in the base class that defines the sequence of steps in an algorithm. The template method calls primitive operations, which are implemented by subclasses. This way, the algorithm's structure remains consistent across different implementations, but specific details can be customized.</p>
			
			<p><b>Implementing Template in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// AbstractClass: defines the template method and primitive operations
abstract class AbstractClass {
    // Template method: defines the algorithm's skeleton
    public final void templateMethod() {
        operation1();
        operation2();
        commonOperation();
        hook(); // A hook method: optional step that can be overridden by subclasses
    }

    // Primitive operation: to be implemented by subclasses
    protected abstract void operation1();

    // Primitive operation: to be implemented by subclasses
    protected abstract void operation2();

    // Common operation: shared among subclasses
    private void commonOperation() {
        System.out.println("Common Operation");
    }

    // Hook method: an optional step that can be overridden by subclasses
    protected void hook() {}
}

// ConcreteClassA: implements the AbstractClass with specific primitive operations
class ConcreteClassA extends AbstractClass {
    protected void operation1() {
        System.out.println("ConcreteClassA: Operation 1");
    }

    protected void operation2() {
        System.out.println("ConcreteClassA: Operation 2");
    }

    protected void hook() {
        System.out.println("ConcreteClassA: Hook Operation");
    }
}

// ConcreteClassB: implements the AbstractClass with different primitive operations
class ConcreteClassB extends AbstractClass {
    protected void operation1() {
        System.out.println("ConcreteClassB: Operation 1");
    }

    protected void operation2() {
        System.out.println("ConcreteClassB: Operation 2");
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Template in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# AbstractClass: defines the template method and primitive operations
class AbstractClass:
    # Template method: defines the algorithm's skeleton
    def template_method(self):
        self.operation1()
        self.operation2()
        self.common_operation()
        self.hook()  # A hook method: optional step that can be overridden by subclasses

    # Primitive operation: to be implemented by subclasses
    def operation1(self):
        pass

    # Primitive operation: to be implemented by subclasses
    def operation2(self):
        pass

    # Common operation: shared among subclasses
    def common_operation(self):
        print("Common Operation")

    # Hook method: an optional step that can be overridden by subclasses
    def hook(self):
        pass

# ConcreteClassA: implements the AbstractClass with specific primitive operations
class ConcreteClassA(AbstractClass):
    def operation1(self):
        print("ConcreteClassA: Operation 1")

    def operation2(self):
        print("ConcreteClassA: Operation 2")

    def hook(self):
        print("ConcreteClassA: Hook Operation")

# ConcreteClassB: implements the AbstractClass with different primitive operations
class ConcreteClassB(AbstractClass):
    def operation1(self):
        print("ConcreteClassB: Operation 1")

    def operation2(self):
        print("ConcreteClassB: Operation 2")`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Template Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class TemplateUsage {
    public static void main(String[] args) {
        AbstractClass classA = new ConcreteClassA();
        AbstractClass classB = new ConcreteClassB();

        System.out.println("Using ConcreteClassA:");
        classA.templateMethod();

        System.out.println("\nUsing ConcreteClassB:");
        classB.templateMethod();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    class_a = ConcreteClassA()
    class_b = ConcreteClassB()

    print("Using ConcreteClassA:")
    class_a.template_method()

    print("\nUsing ConcreteClassB:")
    class_b.template_method()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Template Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`   +-------------------+        +---------------------+
   |   AbstractClass   |<>------|   ConcreteClassA    |
   +-------------------+        +---------------------+
   | +templateMethod() |        | +operation1()       |
   | +operation1()     |        | +operation2()       |
   | +operation2()     |        | +hook()             |
   | +commonOperation()|        +---------------------+
   | +hook()           |               ^
   +-------------------+               |
                               +---------------------+
                               |   ConcreteClassB    |
                               +---------------------+
                               | +operation1()       |
                               | +operation2()       |
                               +---------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, AbstractClass is the abstract class with the template method and primitive operations. ConcreteClassA and ConcreteClassB are the concrete implementations with specific primitive operations.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Template Design Pattern defines the skeleton of an algorithm in a method and allows subclasses to customize specific steps. It promotes code reuse and maintains a consistent structure across different implementations. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Template Pattern can lead to more modular and flexible code, particularly when different algorithms share a common workflow with variations in certain.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default TemplateDesignPattern;
