import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function CommandDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Command Design Pattern: Encapsulating Requests as Objects</h1>
      
      <p>The Command Design Pattern is a behavioral design pattern that encapsulates a request as an object, allowing clients to parameterize and decouple sender and receiver of requests. It transforms requests into standalone objects, providing flexibility and extensibility. The Command Pattern enables undo/redo functionality and supports queuing and logging of requests. In this tutorial, we will explore the Command Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Command Design Pattern:</b></h3>
			The Command Pattern consists of four main components: Command, ConcreteCommand, Receiver, and Invoker. The Command interface declares the execute method, and the ConcreteCommand implements it, binding a specific action to a Receiver. The Receiver performs the actual operations, and the Invoker triggers the command's execution. This pattern helps decouple sender and receiver, making the code more flexible and maintainable.</p>
			
			<p><b>Implementing Command in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Command: declares the interface for executing a request
interface Command {
    void execute();
}

// ConcreteCommand: implements the Command interface, binding a specific action to a Receiver
class LightOnCommand implements Command {
    private LightReceiver light;

    public LightOnCommand(LightReceiver light) {
        this.light = light;
    }

    public void execute() {
        light.turnOn();
    }
}

// Receiver: knows how to perform the operations
class LightReceiver {
    public void turnOn() {
        System.out.println("Light is ON");
    }

    public void turnOff() {
        System.out.println("Light is OFF");
    }
}

// Invoker: triggers the command's execution
class RemoteControl {
    private Command command;

    public void setCommand(Command command) {
        this.command = command;
    }

    public void pressButton() {
        command.execute();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Command in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Command: declares the interface for executing a request
class Command:
    def execute(self):
        pass

# ConcreteCommand: implements the Command interface, binding a specific action to a Receiver
class LightOnCommand(Command):
    def __init__(self, light):
        self.light = light

    def execute(self):
        self.light.turn_on()

# Receiver: knows how to perform the operations
class LightReceiver:
    def turn_on(self):
        print("Light is ON")

    def turn_off(self):
        print("Light is OFF")

# Invoker: triggers the command's execution
class RemoteControl:
    def __init__(self):
        self.command = None

    def set_command(self, command):
        self.command = command

    def press_button(self):
        self.command.execute()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Command Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class CommandUsage {
    public static void main(String[] args) {
        LightReceiver light = new LightReceiver();
        Command lightOnCommand = new LightOnCommand(light);

        RemoteControl remote = new RemoteControl();
        remote.setCommand(lightOnCommand);

        remote.pressButton();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    light = LightReceiver()
    light_on_command = LightOnCommand(light)

    remote = RemoteControl()
    remote.set_command(light_on_command)

    remote.press_button()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Command Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`    +--------------------+     +------------------+    +-------------------+
    |      Command       |<>---|  LightOnCommand  |    |    LightReceiver  |
    +--------------------+     +------------------+    +-------------------+
    | +execute(): void   |     | +execute(): void |    | +turnOn(): void   |
    +--------------------+     +------------------+    | +turnOff(): void  |
                                                        +-------------------+
                                                                ^
                                                                |
                                                        +------------------+
                                                        |   RemoteControl  |
                                                        +------------------+
                                                        | - command: Command|
                                                        +------------------+
                                                        | +setCommand(): void|
                                                        | +pressButton(): void|
                                                        +------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Command is the command interface, and LightOnCommand is the concrete command that binds a specific action to LightReceiver. The LightReceiver performs the actual operations, and the RemoteControl triggers the command's execution.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Command Design Pattern encapsulates requests as objects, promoting decoupling and extensibility in the code. It enables undo/redo functionality, request queuing, and logging. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Command Pattern can lead to more organized and maintainable code, especially when dealing with complex interactions between sender and receiver components.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default CommandDesignPattern;
