import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function ObserverDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Observer Design Pattern: Keeping Objects in Sync</h1>
      
      <p>The Observer Design Pattern is a behavioral design pattern that establishes a one-to-many dependency between objects. When the state of one object (subject) changes, all its dependent objects (observers) are automatically notified and updated. This pattern promotes loose coupling between objects and enables a flexible and efficient way to maintain consistency across the system. In this tutorial, we will explore the Observer Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Observer Design Pattern:</b></h3>
			The Observer Pattern consists of two key components: the subject and the observer. The subject maintains a list of observers and notifies them of any state changes. Observers register themselves with the subject and receive updates when the subject's state changes. This pattern follows the "open-closed principle," as new observers can be added without modifying the existing subject.</p>
			
			<p><b>Implementing Observer in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`import java.util.ArrayList;
import java.util.List;

// Subject: maintains a list of observers and notifies them of state changes
interface Subject {
    void registerObserver(Observer observer);
    void removeObserver(Observer observer);
    void notifyObservers();
}

// ConcreteSubject: implements the Subject interface
class ConcreteSubject implements Subject {
    private List<Observer> observers = new ArrayList<>();
    private int state;

    public void registerObserver(Observer observer) {
        observers.add(observer);
    }

    public void removeObserver(Observer observer) {
        observers.remove(observer);
    }

    public void notifyObservers() {
        for (Observer observer : observers) {
            observer.update(state);
        }
    }

    public void setState(int state) {
        this.state = state;
        notifyObservers();
    }
}

// Observer: defines the interface for receiving updates
interface Observer {
    void update(int state);
}

// ConcreteObserver: implements the Observer interface
class ConcreteObserver implements Observer {
    private String name;

    public ConcreteObserver(String name) {
        this.name = name;
    }

    public void update(int state) {
        System.out.println(name + " received update: State is now " + state);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Observer in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Subject: maintains a list of observers and notifies them of state changes
class Subject:
    def __init__(self):
        self.observers = []
        self.state = 0

    def register_observer(self, observer):
        self.observers.append(observer)

    def remove_observer(self, observer):
        self.observers.remove(observer)

    def notify_observers(self):
        for observer in self.observers:
            observer.update(self.state)

    def set_state(self, state):
        self.state = state
        self.notify_observers()

# Observer: defines the interface for receiving updates
class Observer:
    def update(self, state):
        pass

# ConcreteObserver: implements the Observer interface
class ConcreteObserver(Observer):
    def __init__(self, name):
        self.name = name

    def update(self, state):
        print(f"{self.name} received update: State is now {state}")`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Observer Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class ObserverUsage {
    public static void main(String[] args) {
        ConcreteSubject subject = new ConcreteSubject();

        Observer observer1 = new ConcreteObserver("Observer 1");
        Observer observer2 = new ConcreteObserver("Observer 2");

        subject.registerObserver(observer1);
        subject.registerObserver(observer2);

        subject.setState(5);
        subject.setState(10);

        subject.removeObserver(observer1);

        subject.setState(15);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    subject = Subject()

    observer1 = ConcreteObserver("Observer 1")
    observer2 = ConcreteObserver("Observer 2")

    subject.register_observer(observer1)
    subject.register_observer(observer2)

    subject.set_state(5)
    subject.set_state(10)

    subject.remove_observer(observer1)

    subject.set_state(15)`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Observer Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`     +-------------------+      +-------------------+
     |     Subject       |<>----|     Observer      |
     +-------------------+      +-------------------+
     | +registerObserver()|      | +update(state)    |
     | +removeObserver()  |      +-------------------+
     | +notifyObservers() |               ^
     +-------------------+               |
                               +-------------------+
                               |  ConcreteObserver  |
                               +-------------------+
                               | - name: String     |
                               +-------------------+
                               | +update(state)    |
                               +-------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Subject is the subject interface, Observer is the observer interface, and ConcreteSubject and ConcreteObserver are the concrete implementations.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Observer Design Pattern enables effective communication between objects by maintaining a list of observers and notifying them of state changes. It promotes loose coupling between subjects and observers, allowing for a flexible and maintainable system. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Observer Pattern can lead to a more responsive and efficient system with better separation of concerns.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}


export default ObserverDesignPattern;
