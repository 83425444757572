import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-principle.css';
import SoftwareDesignPrincipleTopicsList from './SoftwareDesignPrincipleTopicsList'

function OpenClosedPrinciple() {
  return (
    <div className="software-design-principle-container">
      <h1>Open-Closed Principle (OCP)</h1>
      
      <p>The Open-Closed Principle (OCP) states that software entities should be open for extension but closed for modification. It means that you should be able to add new features without modifying existing code.
			</p>
			<p><b>Example in Java:</b>Consider a class that calculates the total price for different types of products.</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Not following OCP
public class PriceCalculator {
    public double calculateTotalPrice(Product product) {
        double totalPrice = product.getPrice();
        if (product.getType().equals("discounted")) {
            totalPrice -= product.getPrice() * 0.1;
        }
        return totalPrice;
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow OCP, we use polymorphism and inheritance to extend the behavior:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Following OCP
public abstract class Product {
    private double price;

    public Product(double price) {
        this.price = price;
    }

    public double getPrice() {
        return price;
    }

    public abstract double calculateTotalPrice();
}

public class RegularProduct extends Product {
    public RegularProduct(double price) {
        super(price);
    }

    public double calculateTotalPrice() {
        return getPrice();
    }
}

public class DiscountedProduct extends Product {
    public DiscountedProduct(double price) {
        super(price);
    }

    public double calculateTotalPrice() {
        return getPrice() - (getPrice() * 0.1);
    }
}`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Example in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Python
# Not following OCP
class PriceCalculator:
    def calculate_total_price(self, product):
        total_price = product.get_price()
        if product.get_type() == "discounted":
            total_price -= product.get_price() * 0.1
        return total_price`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow OCP in Python, we use polymorphism and inheritance to extend the behavior:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Following OCP
class Product:
    def __init__(self, price):
        self.price = price

    def get_price(self):
        return self.price

    def calculate_total_price(self):
        pass

class RegularProduct(Product):
    def calculate_total_price(self):
        return self.get_price()

class DiscountedProduct(Product):
    def calculate_total_price(self):
        return self.get_price() - (self.get_price() * 0.1)`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram</b></p>
      <SyntaxHighlighter  language="python" style={nord}>
{`         Java                          Python
+-------------------+           +-------------------+
| PriceCalculator   |           | PriceCalculator   |
+-------------------+           +-------------------+
| calculateTotalPrice() |       | calculate_total_price()|
+-------------------+           +-------------------+
        |                                |
        |                                |
+--------------------+          +--------------------+
|      Product       |          |      Product       |
+--------------------+          +--------------------+
| getPrice()         |          | get_price()        |
| calculateTotalPrice()|        | calculate_total_price()|
+--------------------+          +--------------------+
        |                                |
        |                                |
+-----------------------+     +-----------------------+
|    RegularProduct     |     |    RegularProduct     |
+-----------------------+     +-----------------------+
| calculateTotalPrice() |     | calculate_total_price()|
+-----------------------+     +-----------------------+
        |                                |
        |                                |
+-----------------------+     +-----------------------+
|   DiscountedProduct   |     |   DiscountedProduct   |
+-----------------------+     +-----------------------+
| calculateTotalPrice() |     | calculate_total_price()|
+-----------------------+     +-----------------------+`}
        </SyntaxHighlighter>
      <br/>
      <h2><b>Conclusion</b></h2>
      <p>In this tutorial, we have explored essential design principles in software system design, including SOLID (Single Responsibility, Open-Closed, Liskov Substitution, Interface Segregation, Dependency Inversion), DRY (Don't Repeat Yourself), KISS (Keep It Simple, Stupid), and YAGNI (You Aren't Gonna Need It). By adhering to these principles, developers can create well-structured, maintainable, and flexible software systems. The examples in both Java and Python, along with the diagrams, have illustrated the practical application of these principles. By incorporating these design principles into your software development practices, you can build high-quality and reliable software systems.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <SoftwareDesignPrincipleTopicsList/>
      </div>
    </div>
  );
}

export default OpenClosedPrinciple;
