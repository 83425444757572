import React from 'react';
import '../thirdparty-integgration-topics-list.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ThirdpartyIntegrationTopicsList from '../ThirdpartyIntegrationTopicsList'

function JavaIntegrationWithFacebook() {
  return (
    <div className="thirdparty-integgration-container">
      <h1>Java Integration with Facebook</h1>
      <p>Facebook provides an API that allows developers to interact with Facebook's platform programmatically. In this tutorial, we will demonstrate how to integrate Java with the Facebook API to access user data, post updates, and perform various actions on Facebook.</p>

      <p><h3><b>Prerequisites</b></h3>
		<ul>
		<li><p> <b>Create a Facebook Developer Account:</b> Go to <a href="https://developers.facebook.com/">Facebook Developer</a> and sign up for a Facebook developer account. Once you have an account, create a new app to obtain the necessary API credentials.</p></li>
		<li><p><b>Obtain Facebook API Credentials:</b> After creating a Facebook app, you'll get an App ID and App Secret. These credentials are required to authenticate your requests to the Facebook API.</p></li>
		<li><p><b>Set Up a Java Development Environment:</b> Make sure you have Java Development Kit (JDK) installed on your system. You can download it from <a href="https://www.oracle.com/java/technologies/javase-downloads.html">Oracle</a>.</p></li>
		<li><p><b>Install Required Libraries:</b> We'll use the restfb library for Java to interact with the Facebook API. You can add it to your project by including the following Maven dependency.</p></li>
		
    <SyntaxHighlighter language="xml" style={nord}>
{`<dependencies>
    <groupId>com.restfb</groupId>
    <artifactId>restfb</artifactId>
    <version>2.30.0</version>
</dependencies>`}
  </SyntaxHighlighter>
		</ul>
    </p>


      <p><h3><b>1. Set Up Facebook Developer Account and Obtain API Credentials : </b></h3>
      Sign up for a Facebook developer account at <a href="https://developers.facebook.com/">Facebook Developer</a>. After creating an application, you'll obtain an App ID and App Secret from the Facebook developer dashboard.</p>

    <p><h3><b>2. Authenticate with Facebook API : </b></h3>
		In order to make requests to the Facebook API, we need to authenticate our application using OAuth 2.0. The exact authentication flow depends on the type of application you are building (web-based, server-based, etc.). For this tutorial, we'll use the Client-Side User Authentication for simplicity.</p>
		
		<p>Here's an example Java code to authenticate using the Facebook API.</p>
  <SyntaxHighlighter language="java" style={nord}>
{`import com.restfb.DefaultFacebookClient;
import com.restfb.FacebookClient;
import com.restfb.Version;
import com.restfb.scope.ScopeBuilder;
import com.restfb.types.User;

public class FacebookIntegration {

    public static final String APP_ID = "YOUR_APP_ID";
    public static final String APP_SECRET = "YOUR_APP_SECRET";
    public static final String REDIRECT_URI = "YOUR_REDIRECT_URI";

    public static void main(String[] args) {
        FacebookClient facebookClient = new DefaultFacebookClient(Version.LATEST);
        ScopeBuilder scopeBuilder = new ScopeBuilder();
        String loginDialogUrl = facebookClient.getLoginDialogUrl(APP_ID, REDIRECT_URI, scopeBuilder);

        // Redirect the user to the loginDialogUrl for authentication
        System.out.println("Please visit the following URL to authenticate:");
        System.out.println(loginDialogUrl);

        // After the user grants permission, the app receives a code in the redirect URL.
        // Use the code to obtain the access token for further API requests.
        // Implement the code exchange and access token retrieval logic as needed.
    }
}`}
  </SyntaxHighlighter>
     
  <p><h3><b>3. Access User Data :</b></h3>
  Once authenticated, you can use the access token to access user data from Facebook. Depending on your application's scope and permissions, you can retrieve user profiles, posts, and other relevant information.</p>
  <p>Here's an example Java code to fetch a user's profile using the Facebook API:</p>
<SyntaxHighlighter language="java" style={nord}>
{`import com.restfb.DefaultFacebookClient;
import com.restfb.FacebookClient;
import com.restfb.Version;
import com.restfb.types.User;

public class FacebookIntegration {

    // ... (Previous code)

    public static void main(String[] args) {
        // ... (Previous code)

        // After the user grants permission and we obtain the access token, use it as follows:
        FacebookClient facebookClient = new DefaultFacebookClient(Version.LATEST, ACCESS_TOKEN);

        // Fetch user profile
        User user = facebookClient.fetchObject("me", User.class);
        System.out.println("User Profile:");
        System.out.println("Name: " + user.getName());
        System.out.println("Email: " + user.getEmail());
        System.out.println("Birthday: " + user.getBirthday());
    }
}`}
</SyntaxHighlighter>
<p><h3><b>4. Post a Status Update on Facebook :</b></h3>
You can also use the Facebook API to post status updates on behalf of a user. This allows your application to share content, such as status messages or links, directly on Facebook.</p>
		
<p>Here's an example Java code to post a status update on Facebook.</p>
<SyntaxHighlighter language="java" style={nord}>
{`import com.restfb.DefaultFacebookClient;
import com.restfb.FacebookClient;
import com.restfb.Version;

public class FacebookIntegration {

    // ... (Previous code)

    public static void main(String[] args) {
        // ... (Previous code)

        // After the user grants permission and we obtain the access token, use it as follows:
        FacebookClient facebookClient = new DefaultFacebookClient(Version.LATEST, ACCESS_TOKEN);

        // Post a status update
        String message = "Hello from my Java app!";
        String postId = facebookClient.publish("me/feed", String.class, com.restfb.Parameter.with("message", message));
        System.out.println("Post ID: " + postId);
    }
}`}
</SyntaxHighlighter>  
<p><b>Here's the complete Java code for integrating with the Facebook API.</b></p> 
<SyntaxHighlighter language="java" style={nord}>
{`import com.restfb.DefaultFacebookClient;
import com.restfb.FacebookClient;
import com.restfb.Version;
import com.restfb.scope.ScopeBuilder;
import com.restfb.types.User;

public class FacebookIntegration {

    public static final String APP_ID = "YOUR_APP_ID";
    public static final String APP_SECRET = "YOUR_APP_SECRET";
    public static final String REDIRECT_URI = "YOUR_REDIRECT_URI";

    public static void main(String[] args) {
        FacebookClient facebookClient = new DefaultFacebookClient(Version.LATEST);
        ScopeBuilder scopeBuilder = new ScopeBuilder();
        String loginDialogUrl = facebookClient.getLoginDialogUrl(APP_ID, REDIRECT_URI, scopeBuilder);

        // Redirect the user to the loginDialogUrl for authentication
        System.out.println("Please visit the following URL to authenticate:");
        System.out.println(loginDialogUrl);

        // After the user grants permission, the app receives a code in the redirect URL.
        // Use the code to obtain the access token for further API requests.
        // Implement the code exchange and access token retrieval logic as needed.
        // For this tutorial, let's assume we have the access token:

        String accessToken = "YOUR_ACCESS_TOKEN"; // Replace with the actual access token

        // Fetch user profile
        FacebookClient authenticatedClient = new DefaultFacebookClient(Version.LATEST, accessToken);
        User user = authenticatedClient.fetchObject("me", User.class);
        System.out.println("User Profile:");
        System.out.println("Name: " + user.getName());
        System.out.println("Email: " + user.getEmail());
        System.out.println("Birthday: " + user.getBirthday());

        // Post a status update
        String message = "Hello from my Java app!";
        String postId = authenticatedClient.publish("me/feed", String.class, com.restfb.Parameter.with("message", message));
        System.out.println("Post ID: " + postId);
    }
}`}
</SyntaxHighlighter>  
      <h2><b>Conclusion</b></h2>
      <p>By following this tutorial, you've learned how to integrate Java with the Facebook API. You can now authenticate your application, access user data, and perform actions like posting status updates on Facebook. Make sure to handle your Facebook API credentials securely and avoid sharing them publicly to protect your application's security.
      </p>
      <p>The restfb library provides many other functionalities to interact with various parts of the Facebook API. Explore the com.restfb.types package to access other objects like posts, comments, and more.</p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <ThirdpartyIntegrationTopicsList/>
      </div>
    </div>
  );
}

export default JavaIntegrationWithFacebook;
