import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './components/commons/HomePage';
import ContactUs from './components/commons/ContactUs';
import AboutMe from './components/commons/AboutMe';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import LeftFlyout from './components/commons/LeftFlyout';
import TopicsList from './components/topics-list/TopicsList'
import * as DesignPatterns from './components/software-design-pattern';
import * as PySpark from './components/pyspark';
import * as AdvanceSql from './components/advance-sql';
import SqlCheatSheet from './components/sql/SqlCheatSheet';
import * as ThirdPartyIntegration from './components/thirdparty-integrations';
import * as SystemDesign from './components/software-system-design'
import * as DesignPrinciple from './components/software-design-principle'

import './App.css';
import './css/global.css';
import Nmon from './components/nmon/Nmon';

function App() {
  return (
    <Router>
      <Header/>
      <ScrollToTop />
      
      <Routes>
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutMe />} />
        <Route path="/" element={<HomePage />} />
        
        <Route path="/pyspark-performance/*" element={<PySpark.PySparkPerformance />} />
        <Route path="/pyspark-performance/data-partitioning" element={<PySpark.PySparkDataPartitioning />} />
        <Route path="/pyspark-performance/broadcasting-small-dataframe" element={<PySpark.PySparkBroadcastingSmallDataframes />} />
        <Route path="/pyspark-performance/choose-riht-join-type" element={<PySpark.PySparkRightJoinType />} />
        <Route path="/pyspark-performance/optimize-memory-usage" element={<PySpark.PySparkOptimizeMemoryUsage />} />
        <Route path="/pyspark-performance/optimizing-partitioning" element={<PySpark.PySparkOptimizingPartitioning />} />
        <Route path="/pyspark-performance/sql-optimization" element={<PySpark.PySparkSqlOptimization />} />
        <Route path="/pyspark-performance/use-catalyst-optimizer" element={<PySpark.PySparkCatalystOptimizer />} />
        <Route path="/pyspark-performance/avoid-unnecessary-actions" element={<PySpark.PySparkAvoidUnnecessaryActions />} />
        <Route path="/pyspark-performance/use-data-types-wisely" element={<PySpark.PySparkRightDataTypes />} />
        <Route path="/pyspark-performance/cluster-configuration" element={<PySpark.PySparkClusterConfiguration />} />
        <Route path="/pyspark-performance/leverage-caching" element={<PySpark.PySparkLeverageCaching />} />

        <Route path="/software-design-pattern" element={<DesignPatterns.SoftwareDesignPattern />} />
        <Route path="/singleton-design-pattern" element={<DesignPatterns.SingletonDesignPattern />}/>
        <Route path="/factory-design-pattern" element={<DesignPatterns.FactoryDesignPattern />} />
        <Route path="/abstract-design-pattern" element={<DesignPatterns.AbstractDesignPattern />}/>
        <Route path="/builder-design-pattern" element={<DesignPatterns.BuilderDesignPattern />} />
        <Route path="/prototype-design-pattern" element={<DesignPatterns.PrototypeDesignPattern />}/>
        <Route path="/object-design-pattern" element={<DesignPatterns.ObjectDesignPattern />} />

        <Route path="/adapter-design-pattern" element={<DesignPatterns.AdapterDesignPattern />} />
        <Route path="/decorator-design-pattern" element={<DesignPatterns.DecoratorDesignPattern />} />
        <Route path="/proxy-design-pattern" element={<DesignPatterns.ProxyDesignPattern />} />
        <Route path="/composite-design-pattern" element={<DesignPatterns.CompositeDesignPattern />} />
        <Route path="/bridge-design-pattern" element={<DesignPatterns.BridgeDesignPattern />} />
        <Route path="/facade-design-pattern" element={<DesignPatterns.FacadeDesignPattern />} />
        <Route path="/flyweight-design-pattern" element={<DesignPatterns.FlyweightDesignPattern />} />

        <Route path="/observer-design-pattern" element={<DesignPatterns.ObserverDesignPattern />} />
        <Route path="/strategy-design-pattern" element={<DesignPatterns.StrategyDesignPattern />} />
        <Route path="/template-design-pattern" element={<DesignPatterns.TemplateDesignPattern />} />
        <Route path="/command-design-pattern" element={<DesignPatterns.CommandDesignPattern />} />
        <Route path="/chain-design-pattern" element={<DesignPatterns.ChainDesignPattern />} />
        <Route path="/state-design-pattern" element={<DesignPatterns.StateDesignPattern />} />
        <Route path="/mediator-design-pattern" element={<DesignPatterns.MediatorDesignPattern />} />
        <Route path="/visitor-design-pattern" element={<DesignPatterns.VisitorDesignPattern />} />
        <Route path="/interceptor-design-pattern" element={<DesignPatterns.InterceptorDesignPattern />} />
        <Route path="/iterator-design-pattern" element={<DesignPatterns.IteratorDesignPattern />} />
        <Route path="/memento-design-pattern" element={<DesignPatterns.MementoDesignPattern />} />
        <Route path="/sql-cheatsheet" element={<SqlCheatSheet />} />
        <Route path="/advance-sql/*" element={<AdvanceSql.AdvanceSqlCheatSheet />} />
        <Route path="/advance-sql/common-expression" element={<AdvanceSql.AdvanceSqlCommonTableExpressions />} />
        <Route path="/advance-sql/conditional-agg" element={<AdvanceSql.AdvanceSqlConditionalAggregation />} />
        <Route path="/advance-sql/date-time" element={<AdvanceSql.AdvanceSqlDateAndTime />} />
        <Route path="/advance-sql/handling-null" element={<AdvanceSql.AdvanceSqlHandlingNullValue />} />
        <Route path="/advance-sql/index-performance" element={<AdvanceSql.AdvanceSqlIndexPerformance />} />
        <Route path="/advance-sql/pivoting-data" element={<AdvanceSql.AdvanceSqlPivotingData />} />
        <Route path="/advance-sql/stored-procedures" element={<AdvanceSql.AdvanceSqlStoredProcedures />} />
        <Route path="/advance-sql/string-aggregation" element={<AdvanceSql.AdvanceSqlStringAggregation />} />
        <Route path="/advance-sql/subqueries" element={<AdvanceSql.AdvanceSqlSubqueries />} />
        <Route path="/advance-sql/transaction-management" element={<AdvanceSql.AdvanceSqlTransactionManagement />} />
        <Route path="/advance-sql/window-function" element={<AdvanceSql.AdvanceSqlWindowFunctions />} />
        <Route path="/advance-sql/with-json" element={<AdvanceSql.AdvanceSqlWorkingWithJson />} />
        <Route path="/java-integration-with-chatgpt" element={<ThirdPartyIntegration.JavaIntegrationWithChatGpt />} />
        <Route path="/python-integration-with-chatgpt" element={<ThirdPartyIntegration.PythonIntegrationWithChatGpt />} />
        <Route path="/java-integration-with-facebook" element={<ThirdPartyIntegration.JavaIntegrationWithFacebook />} />
        <Route path="/python-integration-with-facebook" element={<ThirdPartyIntegration.PythonIntegrationWithFacebook />} />
        <Route path="/java-integration-with-linkedin" element={<ThirdPartyIntegration.JavaIntegrationWithLinkedIn />} />
        <Route path="/python-integration-with-linkedin" element={<ThirdPartyIntegration.PythonIntegrationWithLinkedIn />} />
        <Route path="/java-integration-with-twitter" element={<ThirdPartyIntegration.JavaIntegrationWithTwitter />} />
        <Route path="/python-integration-with-twitter" element={<ThirdPartyIntegration.PythonIntegrationWithTwitter />} />
        <Route path="/java-integration-with-whatsapp" element={<ThirdPartyIntegration.JavaIntegrationWithWhatsApp />} />
        <Route path="/python-integration-with-whatsapp" element={<ThirdPartyIntegration.PythonIntegrationWithWhatsApp />} />
        <Route path="/nmon" element={<Nmon />} />
        <Route path="/software-system-design" element={<SystemDesign.SoftwareSystemDesign />} />
        <Route path="/scalability-and-performance-design" element={<SystemDesign.ScalabilityAndPerformanceDesign />} />
        <Route path="/software-component-based-design" element={<SystemDesign.SoftwareComponentBasedDesign />} />
        <Route path="/software-database-design" element={<SystemDesign.SoftwareDatabaseDesign />} />
        <Route path="/system-integration-design" element={<SystemDesign.SystemIntegrationDesign />} />
        <Route path="/user-interface-design" element={<SystemDesign.UserInterfaceDesign />} />
        <Route path="/software-architectural-patterns" element={<SystemDesign.SoftwareArchitecturalPatterns />} />
        <Route path="/software-design-principles" element={<DesignPrinciple.SoftwareDesignPrinciples />} />
        <Route path="/dependency-inversion-principle" element={<DesignPrinciple.DependencyInversionPrinciple />} />
        <Route path="/donot-repeat-yourself-principle" element={<DesignPrinciple.DonotRepeatYourselfPrinciple />} />
        <Route path="/interface-segregation-principle" element={<DesignPrinciple.InterfaceSegregationPrinciple />} />
        <Route path="/keep-it-simple-stupid-principle" element={<DesignPrinciple.KeepItSimpleStupidPrinciple />} />
        <Route path="/liskov-substitution-principle" element={<DesignPrinciple.LiskovSubstitutionPrinciple />} />
        <Route path="/open-closed-principle" element={<DesignPrinciple.OpenClosedPrinciple />} />
        <Route path="/single-responsibility-principle" element={<DesignPrinciple.SingleResponsibilityPrinciple />} />
        <Route path="/you-are-not-gonna-need-it-principle" element={<DesignPrinciple.YouArenotGonnaNeedItPrinciple />} />
        
      </Routes>
      <hr/>
      
      {/* <LeftFlyout /> */}
      <TopicsList/>
      <Footer/>
      
    </Router>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
