import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function DecoratorDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Decorator Design Pattern: Enhancing Objects Dynamically</h1>
      
      <p>The Decorator Design Pattern is a structural design pattern that allows you to add new functionalities or behaviors to objects dynamically. It acts as a wrapper around the original object, providing additional features without modifying its structure. The Decorator Pattern is particularly useful when you want to extend the functionality of individual objects without creating a subclass for each possible combination. In this tutorial, we will explore the Decorator Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Decorator Design Pattern:</b></h3>
			The Decorator Pattern enhances the behavior of objects at runtime by adding responsibilities to them through a series of decorator classes. These decorator classes implement the same interface as the original object and can add functionalities before or after invoking the original object's methods. This pattern follows the "open-closed principle" as it allows extension without modification.</p>
			
			<p><b>Implementing Decorator in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Component interface: the common interface for both the ConcreteComponent and Decorator classes
interface Coffee {
    double getCost();
    String getDescription();
}

// ConcreteComponent: the base class that implements the Component interface
class SimpleCoffee implements Coffee {
    public double getCost() {
        return 2.0; // Base cost of a simple coffee
    }

    public String getDescription() {
        return "Simple Coffee";
    }
}

// Decorator: the abstract class that implements the Component interface and holds a reference to the Component
abstract class CoffeeDecorator implements Coffee {
    protected Coffee decoratedCoffee;

    public CoffeeDecorator(Coffee coffee) {
        this.decoratedCoffee = coffee;
    }

    public double getCost() {
        return decoratedCoffee.getCost();
    }

    public String getDescription() {
        return decoratedCoffee.getDescription();
    }
}

// ConcreteDecorator: adds milk to the coffee
class MilkDecorator extends CoffeeDecorator {
    public MilkDecorator(Coffee coffee) {
        super(coffee);
    }

    public double getCost() {
        return super.getCost() + 1.0; // Additional cost for milk
    }

    public String getDescription() {
        return super.getDescription() + ", Milk";
    }
}

// ConcreteDecorator: adds whipped cream to the coffee
class WhippedCreamDecorator extends CoffeeDecorator {
    public WhippedCreamDecorator(Coffee coffee) {
        super(coffee);
    }

    public double getCost() {
        return super.getCost() + 1.5; // Additional cost for whipped cream
    }

    public String getDescription() {
        return super.getDescription() + ", Whipped Cream";
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Decorator in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Component interface: the common interface for both the ConcreteComponent and Decorator classes
class Coffee:
    def get_cost(self):
        pass

    def get_description(self):
        pass

# ConcreteComponent: the base class that implements the Component interface
class SimpleCoffee(Coffee):
    def get_cost(self):
        return 2.0  # Base cost of a simple coffee

    def get_description(self):
        return "Simple Coffee"

# Decorator: the abstract class that implements the Component interface and holds a reference to the Component
class CoffeeDecorator(Coffee):
    def __init__(self, coffee):
        self.decorated_coffee = coffee

    def get_cost(self):
        return self.decorated_coffee.get_cost()

    def get_description(self):
        return self.decorated_coffee.get_description()

# ConcreteDecorator: adds milk to the coffee
class MilkDecorator(CoffeeDecorator):
    def get_cost(self):
        return super().get_cost() + 1.0  # Additional cost for milk

    def get_description(self):
        return super().get_description() + ", Milk"

# ConcreteDecorator: adds whipped cream to the coffee
class WhippedCreamDecorator(CoffeeDecorator):
    def get_cost(self):
        return super().get_cost() + 1.5  # Additional cost for whipped cream

    def get_description(self):
        return super().get_description() + ", Whipped Cream"`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Decorator Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class DecoratorUsage {
    public static void main(String[] args) {
        Coffee coffee = new SimpleCoffee();
        System.out.println("Cost: $" + coffee.getCost() + ", Description: " + coffee.getDescription());

        Coffee milkCoffee = new MilkDecorator(coffee);
        System.out.println("Cost: $" + milkCoffee.getCost() + ", Description: " + milkCoffee.getDescription());

        Coffee whippedCreamCoffee = new WhippedCreamDecorator(coffee);
        System.out.println("Cost: $" + whippedCreamCoffee.getCost() + ", Description: " + whippedCreamCoffee.getDescription());

        Coffee milkAndWhippedCreamCoffee = new WhippedCreamDecorator(new MilkDecorator(coffee));
        System.out.println("Cost: $" + milkAndWhippedCreamCoffee.getCost() + ", Description: " + milkAndWhippedCreamCoffee.getDescription());
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    coffee = SimpleCoffee()
    print(f"Cost: $ {coffee.get_cost()}, Description: {coffee.get_description()}")

    milk_coffee = MilkDecorator(coffee)
    print(f"Cost: $ {milk_coffee.get_cost()}, Description: {milk_coffee.get_description()}")

    whipped_cream_coffee = WhippedCreamDecorator(coffee)
    print(f"Cost: $ {whipped_cream_coffee.get_cost()}, Description: {whipped_cream_coffee.get_description()}")

    milk_and_whipped_cream_coffee = WhippedCreamDecorator(MilkDecorator(coffee))
    print(f"Cost: $ {milk_and_whipped_cream_coffee.get_cost()}, Description: {milk_and_whipped_cream_coffee.get_description()}")`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Decorator Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`      +---------------------------+
      |         Coffee            |
      +---------------------------+
      | +getCost(): double       |
      | +getDescription(): String|
      +---------------------------+
                  ^
                  |
    +-------------------------------+
    |        CoffeeDecorator        |
    +-------------------------------+
    | - decoratedCoffee: Coffee    |
    +-------------------------------+
    | +getCost(): double           |
    | +getDescription(): String    |
    +-------------------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Coffee is the interface representing the base component and CoffeeDecorator is the abstract class representing the decorator. MilkDecorator and WhippedCreamDecorator are the concrete decorators that add specific functionalities to the coffee.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Decorator Design Pattern allows you to add new functionalities to objects dynamically without modifying their structure. By wrapping objects with decorator classes, you can extend their behavior at runtime. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Decorator Pattern can lead to more flexible and modular code, enabling the addition of features to objects in a more scalable way.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default DecoratorDesignPattern;
