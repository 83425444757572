import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function ProxyDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Proxy Design Pattern: Controlling Access to Objects</h1>
      
      <p>The Proxy Design Pattern is a structural design pattern that acts as a surrogate or placeholder for another object, controlling access to it. The Proxy Pattern provides an intermediary or a wrapper around the real object, allowing additional actions to be performed before or after accessing the real object's methods. This pattern is useful when you want to add functionality such as access control, logging, or caching without modifying the original object. In this tutorial, we will explore the Proxy Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Proxy Design Pattern:</b></h3>
			The Proxy Pattern allows you to manage access to an object and provide additional behaviors without altering the real object's implementation. The Proxy acts as a representative, forwarding client requests to the real object and performing pre or post-processing if needed. This pattern follows the "open-closed principle" as it allows for extension without modification.</p>
			
			<p><b>Implementing Proxy in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Subject interface: the common interface for both the RealSubject and Proxy classes
interface Image {
    void display();
}

// RealSubject: the actual object performing the operation
class RealImage implements Image {
    private String filename;

    public RealImage(String filename) {
        this.filename = filename;
        loadFromDisk();
    }

    private void loadFromDisk() {
        System.out.println("Loading " + filename);
    }

    public void display() {
        System.out.println("Displaying " + filename);
    }
}

// Proxy: acts as a surrogate for RealImage and controls access to it
class ProxyImage implements Image {
    private RealImage realImage;
    private String filename;

    public ProxyImage(String filename) {
        this.filename = filename;
    }

    public void display() {
        if (realImage == null) {
            realImage = new RealImage(filename);
        }
        realImage.display();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Proxy in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Subject interface: the common interface for both the RealSubject and Proxy classes
class Image:
    def display(self):
        pass

# RealSubject: the actual object performing the operation
class RealImage(Image):
    def __init__(self, filename):
        self.filename = filename
        self.load_from_disk()

    def load_from_disk(self):
        print("Loading", self.filename)

    def display(self):
        print("Displaying", self.filename)

# Proxy: acts as a surrogate for RealImage and controls access to it
class ProxyImage(Image):
    def __init__(self, filename):
        self.filename = filename
        self.real_image = None

    def display(self):
        if not self.real_image:
            self.real_image = RealImage(self.filename)
        self.real_image.display()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Proxy Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class ProxyUsage {
    public static void main(String[] args) {
        Image image = new ProxyImage("test.jpg");

        // Image will be loaded from disk when displayed for the first time
        image.display();

        // Image will not be loaded from disk again; it will be fetched from the proxy
        image.display();
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`if __name__ == "__main__":
    image = ProxyImage("test.jpg")

    # Image will be loaded from disk when displayed for the first time
    image.display()

    # Image will not be loaded from disk again; it will be fetched from the proxy
    image.display()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Proxy Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`      +------------------------+
      |         Image          |
      +------------------------+
      | +display(): void      |
      +------------------------+
                  ^
                  |
        +----------------------------+
        |      ProxyImage         |
        +----------------------------+
        | - realImage: RealImage   |
        | - filename: String       |
        +----------------------------+
        | +display(): void        |
        +----------------------------+
                  ^
                  |
        +------------------------+
        |       RealImage       |
        +------------------------+
        | - filename: String     |
        +------------------------+
        | +display(): void      |
        +------------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Image is the interface representing the subject, ProxyImage is the proxy class, and RealImage is the real subject.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Proxy Design Pattern provides a means to control access to objects and add additional functionality without modifying the real object. By creating a proxy, you can manage resource-heavy objects, control access, or provide caching mechanisms as required. We explored its implementation in both Java and Python, along with a simple usage example. Applying the Proxy Pattern can lead to improved performance and flexibility in managing object interactions and resources.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}


export default ProxyDesignPattern;
