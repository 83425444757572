import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-pattern.css'
import DesignPatternTopicsList from './DesignPatternTopicsList'

function FlyweightDesignPattern() {
  return (
    <div className="software-design-pattern-container">
      <h1>Flyweight Design Pattern: Efficiently Sharing Intrinsic State</h1>
      
      <p>The Flyweight Design Pattern is a structural design pattern that aims to minimize memory usage and improve performance by sharing common data among multiple objects. It is suitable for situations where an application uses a large number of similar objects that can be broken down into intrinsic (shared) and extrinsic (unique) states. By sharing the intrinsic state, the Flyweight Pattern reduces memory consumption and allows more efficient use of resources. In this tutorial, we will explore the Flyweight Design Pattern with examples in both Java and Python, along with a diagram illustration.
			</p>

      <p><h3><b>☐ Understanding the Flyweight Design Pattern:</b></h3>
			The Flyweight Pattern focuses on optimizing memory usage by sharing immutable intrinsic state among multiple objects. It helps in reducing the overall memory footprint when dealing with a large number of similar objects. The extrinsic state is managed separately for each object and is not shared among them.</p>
			
			<p><b>Implementing Flyweight in Java:</b></p>
        <SyntaxHighlighter  language="java" style={nord}>
{`import java.util.HashMap;
import java.util.Map;

// Flyweight interface: defines the common interface for flyweight objects
interface Shape {
    void draw();
}

// ConcreteFlyweight: implements the Flyweight interface for shared objects
class Circle implements Shape {
    private String color;
    private int x, y, radius;

    public Circle(String color) {
        this.color = color;
    }

    public void setX(int x) {
        this.x = x;
    }

    public void setY(int y) {
        this.y = y;
    }

    public void setRadius(int radius) {
        this.radius = radius;
    }

    public void draw() {
        System.out.println("Drawing Circle: Color " + color + ", X: " + x + ", Y: " + y + ", Radius: " + radius);
    }
}

// FlyweightFactory: manages and shares flyweight objects
class ShapeFactory {
    private static final Map<String, Shape> shapeMap = new HashMap<>();

    public static Shape getCircle(String color) {
        Circle circle = (Circle) shapeMap.get(color);

        if (circle == null) {
            circle = new Circle(color);
            shapeMap.put(color, circle);
            System.out.println("Creating Circle of Color: " + color);
        }

        return circle;
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p><b>Implementing Flyweight in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Flyweight interface: defines the common interface for flyweight objects
class Shape:
    def draw(self):
        pass

# ConcreteFlyweight: implements the Flyweight interface for shared objects
class Circle(Shape):
    def __init__(self, color):
        self.color = color
        self.x = 0
        self.y = 0
        self.radius = 0

    def set_x(self, x):
        self.x = x

    def set_y(self, y):
        self.y = y

    def set_radius(self, radius):
        self.radius = radius

    def draw(self):
        print(f"Drawing Circle: Color {self.color}, X: {self.x}, Y: {self.y}, Radius: {self.radius}")

# FlyweightFactory: manages and shares flyweight objects
class ShapeFactory:
    shape_map = {}

    @staticmethod
    def get_circle(color):
        circle = ShapeFactory.shape_map.get(color)

        if circle is None:
            circle = Circle(color)
            ShapeFactory.shape_map[color] = circle
            print(f"Creating Circle of Color: {color}")

        return circle`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Using the Flyweight Design Pattern</b></p>
      <p>Java Example:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`public class FlyweightUsage {
    private static final String[] colors = {"Red", "Green", "Blue"};

    public static void main(String[] args) {
        for (int i = 0; i < 10; i++) {
            Circle circle = (Circle) ShapeFactory.getCircle(getRandomColor());
            circle.setX(getRandomX());
            circle.setY(getRandomY());
            circle.setRadius(100);
            circle.draw();
        }
    }

    private static String getRandomColor() {
        return colors[(int) (Math.random() * colors.length)];
    }

    private static int getRandomX() {
        return (int) (Math.random() * 100);
    }

    private static int getRandomY() {
        return (int) (Math.random() * 100);
    }
}`}
        </SyntaxHighlighter>
        <br/>
        <p>Python Example:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`import random

if __name__ == "__main__":
    colors = ["Red", "Green", "Blue"]

    for _ in range(10):
        circle = ShapeFactory.get_circle(random.choice(colors))
        circle.set_x(random.randint(0, 100))
        circle.set_y(random.randint(0, 100))
        circle.set_radius(100)
        circle.draw()`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram for the Flyweight Design Pattern</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`       +-------------------+
       |       Shape       |
       +-------------------+
       | +draw(): void    |
       +-------------------+
                  ^
                  |
     +---------------------------+
     |         Circle           |
     +---------------------------+
     | - color: String         |
     | - x: int                |
     | - y: int                |
     | - radius: int           |
     +---------------------------+
     | +setX(int): void        |
     | +setY(int): void        |
     | +setRadius(int): void   |
     | +draw(): void           |
     +---------------------------+`}
        </SyntaxHighlighter>
      
      <p>In the diagram, Shape is the flyweight interface, and Circle is the concrete flyweight representing shared objects. ShapeFactory is the flyweight factory that manages and shares the flyweight objects.</p>
  
      <h2><b>Conclusion</b></h2>
      <p>The Flyweight Design Pattern efficiently manages memory usage by sharing intrinsic (shared) state among multiple objects. It improves performance and reduces memory consumption in situations where there are many similar objects. We explored
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <DesignPatternTopicsList/>
      </div>
    </div>
  );
}

export default FlyweightDesignPattern;
