import React from 'react';
import '../thirdparty-integgration-topics-list.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ThirdpartyIntegrationTopicsList from '../ThirdpartyIntegrationTopicsList'

function PythonIntegrationWithFacebook() {
  return (
    <div className="thirdparty-integgration-container">
      <h1>Python Integration with Facebook</h1>
      <p>Facebook provides an API that allows developers to interact with Facebook's platform programmatically. In this tutorial, we will demonstrate how to integrate Python with the Facebook API to access user data, post updates, and perform various actions on Facebook.</p>

      <p><h3><b>Prerequisites</b></h3>
		<ul>
		<li><p> <b>Create a Facebook Developer Account:</b> Go to <a href="https://developers.facebook.com/">Facebook Developer</a> and sign up for a Facebook developer account. Once you have an account, create a new app to obtain the necessary API credentials.</p></li>
		
    <li><p><b>Obtain Facebook API Credentials:</b> After creating a Facebook app, you'll get an App ID and App Secret. These credentials are required to authenticate your requests to the Facebook API.</p></li>

		<li><p><b>Set Up a Python Development Environment:</b>Make sure you have Python installed on your system. You can download it from <a href="https://www.python.org/downloads/">Python</a>.</p></li>
		<li><p><b>Install Required Libraries:</b> We'll use the facebook-sdk library for Python to interact with the Facebook API. You can install it using the following command:</p></li>
		
    <SyntaxHighlighter language="none" style={nord}>
{`pip install facebook-sdk`}
  </SyntaxHighlighter>
		</ul>
    </p>


      <p><h3><b>1. Set Up Facebook Developer Account and Obtain API Credentials : </b></h3>
      Sign up for a Facebook developer account at <a href="https://developers.facebook.com/">Facebook Developer</a>. After creating an application, you'll obtain an App ID and App Secret from the Facebook developer dashboard.</p>

    <p><h3><b>2. Authenticate with Facebook API : </b></h3>
		In order to make requests to the Facebook API, we need to authenticate our application using OAuth 2.0. The exact authentication flow depends on the type of application you are building (web-based, server-based, etc.). For this tutorial, we'll use the Client-Side User Authentication for simplicity.</p>
		
		<p>Here's an example Python code to authenticate using the Facebook API.</p>
  <SyntaxHighlighter language="python" style={nord}>
{`import facebook

APP_ID = 'YOUR_APP_ID'
APP_SECRET = 'YOUR_APP_SECRET'
REDIRECT_URI = 'YOUR_REDIRECT_URI'

# Create a Facebook Graph API object
graph = facebook.GraphAPI()

# Get the authorization URL for user login
auth_url = graph.get_auth_url(APP_ID, REDIRECT_URI)

# Redirect the user to the auth_url for authentication
print('Please visit the following URL to authenticate:')
print(auth_url)

# After the user grants permission, the app receives a code in the redirect URL.
# Use the code to obtain the access token for further API requests.
# Implement the code exchange and access token retrieval logic as needed.`}
  </SyntaxHighlighter>
     
  <p><h3><b>3. Access User Data :</b></h3>
  Once authenticated, you can use the access token to access user data from Facebook. Depending on your application's scope and permissions, you can retrieve user profiles, posts, and other relevant information.</p>
  <p>Here's an example Python code to fetch a user's profile using the Facebook API:</p>
<SyntaxHighlighter language="python" style={nord}>
{`import facebook

# ... (Previous code)

# After the user grants permission and we obtain the access token, use it as follows:
access_token = 'YOUR_ACCESS_TOKEN'  # Replace with the actual access token

# Create a Facebook Graph API object with the access token
graph = facebook.GraphAPI(access_token)

# Fetch user profile
user_profile = graph.get_object('me')
print('User Profile:')
print('Name:', user_profile['name'])
print('Email:', user_profile['email'])
print('Birthday:', user_profile['birthday'])`}
</SyntaxHighlighter>
<p><h3><b>4. Post a Status Update on Facebook :</b></h3>
You can also use the Facebook API to post status updates on behalf of a user. This allows your application to share content, such as status messages or links, directly on Facebook.</p>
		
<p>Here's an example Python code to post a status update on Facebook.</p>
<SyntaxHighlighter language="python" style={nord}>
{`import facebook

# ... (Previous code)

# After the user grants permission and we obtain the access token, use it as follows:
access_token = 'YOUR_ACCESS_TOKEN'  # Replace with the actual access token

# Create a Facebook Graph API object with the access token
graph = facebook.GraphAPI(access_token)

# Post a status update
message = 'Hello from my Python app!'
graph.put_object('me', 'feed', message=message)
print('Status update posted successfully.')`}
</SyntaxHighlighter>  
<p><b>Here's the complete Python code for integrating with the Facebook API.</b></p> 
<SyntaxHighlighter language="python" style={nord}>
{`import facebook

APP_ID = 'YOUR_APP_ID'
APP_SECRET = 'YOUR_APP_SECRET'
REDIRECT_URI = 'YOUR_REDIRECT_URI'

# Create a Facebook Graph API object
graph = facebook.GraphAPI()

# Get the authorization URL for user login
auth_url = graph.get_auth_url(APP_ID, REDIRECT_URI)

# Redirect the user to the auth_url for authentication
print('Please visit the following URL to authenticate:')
print(auth_url)

# After the user grants permission, the app receives a code in the redirect URL.
# Use the code to obtain the access token for further API requests.
# Implement the code exchange and access token retrieval logic as needed.
# For this tutorial, let's assume we have the access token:

access_token = 'YOUR_ACCESS_TOKEN'  # Replace with the actual access token

# Create a Facebook Graph API object with the access token
graph = facebook.GraphAPI(access_token)

# Fetch user profile
user_profile = graph.get_object('me')
print('User Profile:')
print('Name:', user_profile['name'])
print('Email:', user_profile['email'])
print('Birthday:', user_profile['birthday'])

# Post a status update
message = 'Hello from my Python app!'
graph.put_object('me', 'feed', message=message)
print('Status update posted successfully.')`}
</SyntaxHighlighter>  
      <h2><b>Conclusion</b></h2>
      <p>By following this tutorial, you've learned how to integrate Python with the Facebook API. You can now authenticate your application, access user data, and perform actions like posting status updates on Facebook. Make sure to handle your Facebook API credentials securely and avoid sharing them publicly to protect your application's security.
      </p>
      <p>The facebook-sdk library provides many other functionalities to interact with various parts of the Facebook API. Explore the documentation to access other objects like posts, comments, and more.</p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <ThirdpartyIntegrationTopicsList/>
      </div>
    </div>
  );
}

export default PythonIntegrationWithFacebook;
