import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './Nmon.css';

function Nmon() {
  return (
    <div className="nmon-container">
      <h1>Nmon for System Performance Monitoring</h1>
      {/* Your home page content */}
      <h4>What is Nmon?</h4>
      <p>Nmon (short for "Nigel's Performance Monitor") is a system monitoring tool that captures and displays real-time performance statistics for various system resources, including CPU, memory, disk I/O, network, and more. It is commonly used on Unix-like operating systems to monitor system health and identify performance bottlenecks.</p>

      <h4><b>Installing Nmon</b></h4>
      <p>Nmon is usually pre-installed on many Unix-like systems. However, if it's not available, you can easily install it using package managers like apt, yum, or from source code.</p>

      <h4><b>Nmon Usage and Command-Line Options</b></h4>
      <p>Nmon is a command-line tool with various options to customize data collection. Let's explore some common options:</p>

      <p><b>Basic Usage</b>
        <pre>
          nmon
        </pre>
        <p>Running the above command will display real-time statistics for various resources.</p>
        <p>Saving Output to a File</p>
        <pre>
          nmon -f -s 10 -c 100
        </pre>
      </p>

      <p>The above command saves the data to a file every 10 seconds with a count of 100 intervals.</p>
      <p>Specifying Refresh Interval:
        <pre>
          nmon -s 5
        </pre>
        The above command sets the data collection interval to 5 seconds. </p>

      <p><b>Analyzing Nmon Output</b>
        <p>Nmon output provides valuable insights into system performance. Let's look at some key statistics:</p>
        <b>CPU Statistics</b>
        <ul>
          <li>CPU utilization (user, system, idle, etc.)</li>
          <li>Context switches</li>
          <li>Process counts</li>
        </ul>
      </p>

      <p><b>Memory Statistics</b>
      <ul>
        <li>Total memory</li>
        <li>Free memory</li>
        <li>Swap space usage</li>
      </ul>
      </p>

      <p><b>Disk I/O Statistics</b>
      <ul>
        <li>Disk read/write rates</li>
        <li>Disk utilization</li>
      </ul>
      </p>
      <p><b>Network Statistics</b>
      <ul>
        <li>Network interface usage</li>
        <li>Network packet rates</li>
      </ul>
      </p>
      
      <p><b>Nmon Examples in Java and Python</b></p>
      <p><b>Nmon Data Collection in Java:</b> You can collect Nmon data programmatically in Java using ProcessBuilder to run the Nmon command. The following Java example demonstrates how to execute Nmon and capture its output:</p>
      <SyntaxHighlighter language="java" style={nord}>
        {`
import java.io.BufferedReader;
import java.io.IOException;
import java.io.InputStream;
import java.io.InputStreamReader;
public class NmonDataCollector {
    public static void main(String[] args) {
        try {
            // Run Nmon command and capture its output
            ProcessBuilder processBuilder = new ProcessBuilder("nmon", "-s", "5");
            processBuilder.redirectErrorStream(true);
            Process process = processBuilder.start();

            // Read and display Nmon output
            InputStream inputStream = process.getInputStream();
            BufferedReader reader = new BufferedReader(new InputStreamReader(inputStream));
            String line;
            while ((line = reader.readLine()) != null) {
                System.out.println(line);
            }

            // Wait for Nmon to finish and collect the exit code
            int exitCode = process.waitFor();
            System.out.println("Nmon process exited with code: " + exitCode);
        } catch (IOException | InterruptedException e) {
            e.printStackTrace();
        }
    }
}`}
      </SyntaxHighlighter>

      <p><b>Nmon Data Collection in Python:</b> You can also collect Nmon data programmatically in Python using the subprocess module to run the Nmon command. The following Python example demonstrates how to execute Nmon and capture its output:</p>
      <SyntaxHighlighter language="python" style={nord}>
        {`
import subprocess
def collect_nmon_data():
    try:
        # Run Nmon command and capture its output
        process = subprocess.Popen(["nmon", "-s", "5"], stdout=subprocess.PIPE, stderr=subprocess.STDOUT, text=True)

        # Read and display Nmon output
        for line in process.stdout:
            print(line, end="")

        # Wait for Nmon to finish and collect the return code
        return_code = process.wait()
        print(f"Nmon process exited with code: {return_code}")
    except subprocess.CalledProcessError as e:
        print(f"Error: {e}")
    except KeyboardInterrupt:
        process.terminate()
        print("Nmon process terminated.")

if __name__ == "__main__":
    collect_nmon_data()`}
      </SyntaxHighlighter>
      <p><b>Generated Graph :</b></p>
      <SyntaxHighlighter style={nord}>
        {`
      100% |        +--O--+
      |          |  O
  75% |          |  O
      |          |  O
  50% |          |  O
      |          |  O
  25% |          |  O
      |          |  O
   0% |__________|__O_  (time)
        12:00   12:15
      `}
      </SyntaxHighlighter>
      <h2><b>Conclusion</b></h2>
      <p>Nmon is a powerful tool for monitoring system performance in real-time. It provides essential data for system administrators and developers to analyze system health and optimize performance. Using Java and Python, you can programmatically collect Nmon data to perform custom analysis and integrate with other tools in your application monitoring pipelines. Remember to analyze and interpret the data carefully to make informed decisions and improve your system's performance and stability.</p>

    </div>
  );
}

export default Nmon;
