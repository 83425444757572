import React from 'react';
import { Link } from 'react-router-dom';
import './thirdparty-integgration-topics-list.css';

function ThirdpartyIntegrationTopicsList() {
  const topics = [
   
    { id: 1, name: 'Java Integration With ChatGpt', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/java-integration-with-chatgpt' },
    { id: 2, name: 'Python Integration With ChatGpt', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/python-integration-with-chatgpt' },
    
    { id: 3, name: 'Java Integration With Facebook', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/java-integration-with-facebook' },
    { id: 4, name: 'Python Integration With Facebook', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/python-integration-with-facebook' },
    
    { id: 5, name: 'Java Integration With LinkedIn', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/java-integration-with-linkedin' },
    { id: 6, name: 'Python Integration With LinkedIn', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/python-integration-with-linkedIn' },
    
    { id: 7, name: 'Java Integration With Twitter', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/java-integration-with-twitter' },
    { id: 8, name: 'Python Integration With Twitter', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/python-integration-with-twitter' },
    
    { id: 9, name: 'Java Integration With WhatsApp', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/java-integration-with-whatsapp' },
    { id: 10, name: 'Python Integration With WhatsApp', imageUrl: process.env.PUBLIC_URL + '/course.png', route: '/python-integration-with-whatsapp' },
   
    
    
    // Add more topics here
  ];

  return (
    <div className="topics-list-container">
      <h2>Thirdparty Integration Topics List</h2>
      <div className="topics-list">
        {topics.map(topic => (
          <Link key={topic.id} to={topic.route} className="topic-item">
            <img src={topic.imageUrl} alt={topic.name} />
            <p>{topic.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ThirdpartyIntegrationTopicsList;
