import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './software-design-principle.css';
import SoftwareDesignPrincipleTopicsList from './SoftwareDesignPrincipleTopicsList'

function InterfaceSegregationPrinciple() {
  return (
    <div className="software-design-principle-container">
      <h1>Interface Segregation Principle</h1>
      
      <p>The Interface Segregation Principle (ISP) states that a client should not be forced to depend on interfaces it does not use. It encourages creating smaller and more focused interfaces.
			</p>
			<p><b>Example in Java:</b>Consider a large interface for a document printer that includes unnecessary methods:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Not following ISP
public interface DocumentPrinter {
    void print();
    void scan();
    void fax();
}`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow ISP, we split the large interface into smaller, more focused interfaces:</p>
        <SyntaxHighlighter  language="java" style={nord}>
{`// Following ISP
public interface Printer {
    void print();
}

public interface Scanner {
    void scan();
}

public interface FaxMachine {
    void fax();
}`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Example in Python:</b></p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Python
# Not following ISP
class DocumentPrinter:
    def print(self):
        pass

    def scan(self):
        pass

    def fax(self):
        pass`}
        </SyntaxHighlighter>
        <br/>
        <p>To follow ISP in Python, we split the large interface into smaller, more focused interfaces:</p>
        <SyntaxHighlighter  language="python" style={nord}>
{`# Following ISP
class Printer:
    def print(self):
        pass

class Scanner:
    def scan(self):
        pass

class FaxMachine:
    def fax(self):
        pass`}
        </SyntaxHighlighter>
      <br/>
      <p><b>Diagram</b></p>
      <SyntaxHighlighter  language="none" style={nord}>
{`         Java                                   Python
+-------------------+                +-------------------+
| DocumentPrinter   |                | DocumentPrinter   |
+-------------------+                +-------------------+
| print()           |                | print()           |
| scan()            |                | scan()            |
| fax()             |                | fax()             |
+-------------------+                +-------------------+

    Following ISP                         Following ISP

         Java                                   Python
+---------------+                       +---------------+
| Printer       |                       | Printer       |
+---------------+                       +---------------+
| print()       |                       | print()       |
+---------------+                       +---------------+

         Java                                   Python
+-----------------+                 +-----------------+
| Scanner         |                 | Scanner         |
+-----------------+                 +-----------------+
| scan()          |                 | scan()          |
+-----------------+                 +-----------------+

         Java                                   Python
+-----------------+                 +-----------------+
| FaxMachine      |                 | FaxMachine      |
+-----------------+                 +-----------------+
| fax()           |                 | fax()           |
+-----------------+                 +-----------------+`}
        </SyntaxHighlighter>
      <br/>
      <h2><b>Conclusion</b></h2>
      <p>In this tutorial, we have explored essential design principles in software system design, including SOLID (Single Responsibility, Open-Closed, Liskov Substitution, Interface Segregation, Dependency Inversion), DRY (Don't Repeat Yourself), KISS (Keep It Simple, Stupid), and YAGNI (You Aren't Gonna Need It). By adhering to these principles, developers can create well-structured, maintainable, and flexible software systems. The examples in both Java and Python, along with the diagrams, have illustrated the practical application of these principles. By incorporating these design principles into your software development practices, you can build high-quality and reliable software systems.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <SoftwareDesignPrincipleTopicsList/>
      </div>
    </div>
  );
}

export default InterfaceSegregationPrinciple;
