import React from 'react';
import '../thirdparty-integgration-topics-list.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ThirdpartyIntegrationTopicsList from '../ThirdpartyIntegrationTopicsList'

function JavaIntegrationWithTwitter() {
  return (
    <div className="thirdparty-integgration-container">
      <h1>Java Integration with Twitter API</h1>
      <p>Twitter provides an API that allows developers to interact with Twitter's platform programmatically. In this tutorial, we will demonstrate how to integrate Java with the Twitter API to access tweets, post new tweets, and perform various actions on Twitter.</p>

      <p><h3><b>Prerequisites</b></h3>
      <ul>
		<li><p>Create a Twitter Developer Account: Go to <a href="https://developer.twitter.com/">Twitter Developer</a> and sign up for a Twitter developer account. Once you have an account, create a new app to obtain the necessary API credentials.</p></li>
		<li><p>Obtain Twitter API Credentials: After creating a Twitter app, you'll get an API key, API secret key, access token, and access token secret. These credentials are required to authenticate your requests to the Twitter API.</p></li>
		<li><p>Set Up a Java Development Environment: Make sure you have Java Development Kit (JDK) installed on your system. You can download it from <a href="https://www.oracle.com/java/technologies/javase-downloads.html">Oracle</a>.</p></li>
		<li><p>Install Required Libraries: We'll use the twitter4j library for Java to interact with the Twitter API. You can download it from <a href="http://twitter4j.org/en/index.html">twitter4j</a> and include the JAR files in your project.</p></li>
		</ul>
    </p>

      <p><h3><b>1. Set Up Twitter Developer Account and Obtain API Credentials: </b></h3>
      Sign up for a Twitter developer account at <a href="https://developer.twitter.com/">Twitter Developer</a>. After creating an application, you'll obtain an API key, API secret key, access token, and access token secret from the Twitter developer dashboard.</p>

    <p><h3><b>2. Authenticate with Twitter API: </b></h3>
		To make requests to the Twitter API, we need to authenticate our application using OAuth 1.0a or OAuth 2.0. For this tutorial, we'll use OAuth 1.0a for user-based authentication.</p>

    <p>Here's an example Java code to authenticate using the Twitter API.</p>

  <SyntaxHighlighter language="java" style={nord}>
{`import twitter4j.Twitter;
import twitter4j.TwitterFactory;
import twitter4j.auth.AccessToken;
import twitter4j.auth.RequestToken;

public class TwitterIntegration {

    public static final String CONSUMER_KEY = "YOUR_CONSUMER_KEY";
    public static final String CONSUMER_SECRET = "YOUR_CONSUMER_SECRET";
    public static final String ACCESS_TOKEN = "YOUR_ACCESS_TOKEN";
    public static final String ACCESS_TOKEN_SECRET = "YOUR_ACCESS_TOKEN_SECRET";

    public static void main(String[] args) {
        Twitter twitter = new TwitterFactory().getInstance();
        twitter.setOAuthConsumer(CONSUMER_KEY, CONSUMER_SECRET);
        twitter.setOAuthAccessToken(new AccessToken(ACCESS_TOKEN, ACCESS_TOKEN_SECRET));

        // Now you can use the Twitter object to interact with the Twitter API.
        // Implement your desired functionality using the Twitter object.
    }
}`}
  </SyntaxHighlighter>

<p><h3><b>3. Access Tweets and User Data:</b></h3>
Once authenticated, you can use the Twitter object to access tweets, user profiles, and other data from Twitter. You can fetch tweets, user timelines, mentions, and more.</p>
		
<p>Here's an example Java code to fetch a user's timeline using the Twitter API.</p>
<SyntaxHighlighter language="java" style={nord}>
{`import twitter4j.Twitter;
import twitter4j.TwitterException;
import twitter4j.TwitterFactory;
import twitter4j.auth.AccessToken;

public class TwitterIntegration {

    // ... (Previous code)

    public static void main(String[] args) {
        Twitter twitter = new TwitterFactory().getInstance();
        twitter.setOAuthConsumer(CONSUMER_KEY, CONSUMER_SECRET);
        twitter.setOAuthAccessToken(new AccessToken(ACCESS_TOKEN, ACCESS_TOKEN_SECRET));

        try {
            // Fetch user's timeline
            List<Status> tweets = twitter.getHomeTimeline();
            System.out.println("User's Timeline:");
            for (Status tweet : tweets) {
                System.out.println(tweet.getUser().getName() + " - " + tweet.getText());
            }
        } catch (TwitterException e) {
            e.printStackTrace();
        }
    }
}`}
</SyntaxHighlighter>  

<p><h3><b>4. Post a New Tweet on Twitter:</b></h3>
You can also use the Twitter object to post new tweets on Twitter. This allows your application to share content directly on Twitter.</p>
		
<p>Here's an example Java code to post a new tweet using the Twitter API.</p>
<SyntaxHighlighter language="java" style={nord}>
{`import twitter4j.Twitter;
import twitter4j.TwitterException;
import twitter4j.TwitterFactory;
import twitter4j.auth.AccessToken;

public class TwitterIntegration {

    // ... (Previous code)

    public static void main(String[] args) {
        Twitter twitter = new TwitterFactory().getInstance();
        twitter.setOAuthConsumer(CONSUMER_KEY, CONSUMER_SECRET);
        twitter.setOAuthAccessToken(new AccessToken(ACCESS_TOKEN, ACCESS_TOKEN_SECRET));

        try {
            // Post a new tweet
            twitter.updateStatus("Hello from my Java app!");
            System.out.println("Tweet posted successfully.");
        } catch (TwitterException e) {
            e.printStackTrace();
        }
    }
}`}
</SyntaxHighlighter>  

<p><b>Here's the the complete Java code for integrating with the Twitter API.</b></p> 
<SyntaxHighlighter language="java" style={nord}>
{`import twitter4j.*;
import twitter4j.auth.AccessToken;

public class TwitterIntegration {

    public static final String CONSUMER_KEY = "YOUR_CONSUMER_KEY";
    public static final String CONSUMER_SECRET = "YOUR_CONSUMER_SECRET";
    public static final String ACCESS_TOKEN = "YOUR_ACCESS_TOKEN";
    public static final String ACCESS_TOKEN_SECRET = "YOUR_ACCESS_TOKEN_SECRET";

    public static void main(String[] args) {
        Twitter twitter = new TwitterFactory().getInstance();
        twitter.setOAuthConsumer(CONSUMER_KEY, CONSUMER_SECRET);
        twitter.setOAuthAccessToken(new AccessToken(ACCESS_TOKEN, ACCESS_TOKEN_SECRET));

        try {
            // Fetch user's timeline
            ResponseList<Status> tweets = twitter.getHomeTimeline();
            System.out.println("User's Timeline:");
            for (Status tweet : tweets) {
                System.out.println(tweet.getUser().getName() + " - " + tweet.getText());
            }

            // Post a new tweet
            twitter.updateStatus("Hello from my Java app!");
            System.out.println("Tweet posted successfully.");
        } catch (TwitterException e) {
            e.printStackTrace();
        }
    }
}`}
</SyntaxHighlighter>  
      <h2><b>Conclusion</b></h2>
      <p>By following this tutorial, you've learned how to integrate Java with the Twitter API. You can now authenticate your application, access tweets and user data, and post new tweets on Twitter. Make sure to handle your Twitter API credentials securely and avoid sharing them publicly to protect your application's security.
      </p>
      <p>The twitter4j library provides many other functionalities to interact with various parts of the Twitter API. Explore the documentation to access other objects like mentions, direct messages, and more.</p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <ThirdpartyIntegrationTopicsList/>
      </div>
    </div>
  );
}

export default JavaIntegrationWithTwitter;
