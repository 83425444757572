import React from 'react';
import { Link } from 'react-router-dom';
import './advance-sql-cheatsheet.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import AdvanceSqlTopicsList from './AdvanceSqlTopicsList'

function AdvanceSqlCommonTableExpressions() {
  return (
    <div className="sql-cheat-sheet-container">
      <h1>Advance SQL CheatSheet</h1>
      {/* Your home page content */}
      <p>In this advanced SQL cheat sheet, we will cover some of the commonly used advanced SQL queries and techniques. These queries go beyond the basics and are useful for more complex data manipulation and analysis. We assume you have a good understanding of the basic SQL concepts.</p>
      
      <p><b><h3>▸<Link to="/advance-sql/subqueries">Subqueries :</Link></h3>
        <SyntaxHighlighter language="none" style={nord}>
          {`    ◦ Simple Subquery
    ◦ Correlated Subquery
    ◦ Subquery with EXISTS
    ◦ Subquery with IN
    ◦ Subquery with ANY / ALL
    ◦ Subquery with JOIN`}
        </SyntaxHighlighter>
      </b>
      </p>
      <br/>

      <p><b><h3>▸<Link to="/advance-sql/common-expression">Common Table Expressions (CTEs) :</Link></h3>
        <SyntaxHighlighter language="none" style={nord}>
          {`    ◦ WITH Clause
    ◦ Recursive CTEs`}
        </SyntaxHighlighter>
      </b>
      </p>
      <br/>

      <p><b><h3>▸<Link to="/advance-sql/window-function">Window Functions :</Link></h3>
        <SyntaxHighlighter language="none" style={nord}>
          {`    ◦ OVER Clause
    ◦ ROW_NUMBER()
    ◦ RANK(), DENSE_RANK(), NTILE()
    ◦ LAG() and LEAD()
    ◦ SUM(), AVG(), MIN(), MAX() with Window Functions`}
        </SyntaxHighlighter>
      </b>
      </p>
      <br/>

      <p><b><h3>▸<Link to="/advance-sql/pivoting-data">Pivoting Data :</Link></h3>
        <SyntaxHighlighter language="none" style={nord}>
          {`    ◦ PIVOT
    ◦ UNPIVOT`}
        </SyntaxHighlighter>
      </b>
      </p>
      <br/>

      <p><b><h3>▸<Link to="/advance-sql/string-aggregation">String Aggregation :</Link></h3>
        <SyntaxHighlighter language="none" style={nord}>
          {`    ◦ GROUP_CONCAT() (MySQL)
    ◦ STRING_AGG() (PostgreSQL)
    ◦ LISTAGG() (Oracle)
    ◦ STRING_AGG() (SQL Server 2017+)`}
        </SyntaxHighlighter>
      </b>
      </p>
      <br/>

      <p><b><h3>▸<Link to="/advance-sql/date-time">Date and Time Manipulation :</Link></h3>
        <SyntaxHighlighter language="none" style={nord}>
          {`    ◦ Date Functions
    ◦ Date Arithmetic
    ◦ Extracting Date Parts`}
        </SyntaxHighlighter>
      </b>
      </p>
      <br/>

      <p><b><h3>▸<Link to="/advance-sql/conditional-agg">Conditional Aggregation :</Link></h3>
        <SyntaxHighlighter language="none" style={nord}>
          {`    ◦ CASE Statement
    ◦ SUM() with CASE
    ◦ COUNT() with CASE`}
        </SyntaxHighlighter>
      </b>
      </p>
      <br/>

      <p><b><h3>▸<Link to="/advance-sql/common-expression">Using Common Table Expressions (CTEs) with Recursive Queries :</Link></h3>
      </b>
      </p>
      <br/>

      <p><b><h3>▸<Link to="/advance-sql/stored-procedures">Stored Procedures :</Link></h3>
        <SyntaxHighlighter language="none" style={nord}>
          {`    ◦ Creating Stored Procedures
    ◦ Executing Stored Procedures
    ◦ Subquery with EXISTS`}
        </SyntaxHighlighter>
      </b>
      </p>
      <br/>

      <p><b><h3>▸<Link to="/advance-sql/transaction-management">Transaction Management :</Link></h3>
        <SyntaxHighlighter language="none" style={nord}>
          {`    ◦ BEGIN TRANSACTION, COMMIT, ROLLBACK`}
        </SyntaxHighlighter>
      </b>
      </p>
      <br/>

      <p><b><h3>▸<Link to="/advance-sql/index-performance">Indexes and Performance Optimization :</Link></h3>
        <SyntaxHighlighter language="none" style={nord}>
          {`    ◦ Index Types
    ◦ Query Optimization Techniques`}
        </SyntaxHighlighter>
      </b>
      </p>
      <br/>

      <p><b><h3>▸<Link to="/advance-sql/handling-null">Handling NULL Values :</Link></h3>
        <SyntaxHighlighter language="none" style={nord}>
          {`    ◦ COALESCE
    ◦ NULLIF`}
        </SyntaxHighlighter>
      </b>
      </p>
      <br/>

      <p><b><h3>▸<Link to="/advance-sql/with-json">Working with JSON Data :</Link></h3>
        <SyntaxHighlighter language="none" style={nord}>
          {`    ◦ JSON Functions (MySQL, PostgreSQL, SQL Server)`}
        </SyntaxHighlighter>
      </b>
      </p>

      <h2><b>Conclusion</b></h2>
      <p>This advanced SQL cheat sheet covers a wide range of topics, from subqueries and common table expressions to window functions, pivoting data, date and time manipulation, and performance optimization. Mastering these advanced SQL techniques will enable you to perform complex data analysis and efficiently manipulate data in your database.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <AdvanceSqlTopicsList/>
      </div>
    </div>
  );
}

export default AdvanceSqlCommonTableExpressions;
