import React from 'react';
import { Link } from 'react-router-dom';
import '../thirdparty-integgration-topics-list.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ThirdpartyIntegrationTopicsList from '../ThirdpartyIntegrationTopicsList'

function JavaIntegrationWithChatGpt() {
  return (
    <div className="thirdparty-integgration-container">
      <h1>Java Integration With ChatGpt</h1>
      <p>ChatGPT is a language model developed by OpenAI that can generate human-like responses to a given text prompt. Integrating ChatGPT with Java allows you to create applications that can interact with the language model programmatically.In this tutorial, we'll cover the steps to integrate ChatGPT with Java and demonstrate how to create a simple Java application that interacts with the ChatGPT API. We'll also include examples and diagrams to illustrate the integration process.</p>

      <p><h3><b>1. Set up a Free OpenAI Account and Obtain API Key : </b></h3>
		Before we start integrating ChatGPT with Java, you need to sign up for a free OpenAI account at <Link to="https://openai.com" >OpenAI</Link>. Once you have an account, obtain an API key from the OpenAI dashboard. This API key will be used to authenticate your requests to the ChatGPT API.</p>

    <p><h3><b>2. Create a Java Project : </b></h3>
		Let's start by creating a new Java project using your preferred IDE or a text editor. Organize the project structure as needed.</p>
		
		<p><h3><b>3. Install Required Libraries : </b></h3>
		To make HTTP requests to the ChatGPT API, we'll use the Apache HttpClient library. Add the following Maven dependency to your pom.xml file (if using Maven):</p>
  <SyntaxHighlighter language="xml" style={nord}>
{`<dependencies>
    <dependency>
        <groupId>org.apache.httpcomponents</groupId>
        <artifactId>httpclient</artifactId>
        <version>4.5.13</version>
    </dependency>
</dependencies>`}
  </SyntaxHighlighter>
     
  <p><h3><b>4. Make API Requests to ChatGPT :</b></h3>
			Now, let's write Java code to make API requests to ChatGPT. We'll use the HttpClient library to perform the HTTP POST request to the ChatGPT API and obtain a response.</p>
<SyntaxHighlighter language="java" style={nord}>
{`// Import statements

public class ChatGPTIntegration {

    private static final String OPENAI_API_KEY = "YOUR_API_KEY_HERE";

    public static void main(String[] args) {
        // Input text for the conversation with ChatGPT
        String prompt = "You are a helpful assistant.";

        // Make the API request
        String response = makeChatGPTRequest(prompt);

        // Display the response from ChatGPT
        System.out.println(response);
    }

    private static String makeChatGPTRequest(String prompt) {
        try (CloseableHttpClient httpClient = HttpClients.createDefault()) {
            HttpPost httpPost = new HttpPost("https://api.openai.com/v1/engines/davinci-codex/completions");
            httpPost.addHeader("Authorization", "Bearer " + OPENAI_API_KEY);
            httpPost.addHeader("Content-Type", "application/json");

            // Set up the request body
            StringEntity requestEntity = new StringEntity("{"prompt": "" + prompt + "", "max_tokens": 150}");
            httpPost.setEntity(requestEntity);

            // Execute the request
            HttpResponse response = httpClient.execute(httpPost);
            HttpEntity entity = response.getEntity();

            // Read the response and return it as a string
            try (BufferedReader br = new BufferedReader(new InputStreamReader(entity.getContent()))) {
                StringBuilder responseString = new StringBuilder();
                String line;
                while ((line = br.readLine()) != null) {
                    responseString.append(line);
                }
                return responseString.toString();
            }
        } catch (IOException e) {
            e.printStackTrace();
            return null;
        }
    }
}`}
</SyntaxHighlighter>
<p><h3><b>5. Handle Responses from ChatGPT :</b></h3>
		The response from ChatGPT will be in JSON format, containing the generated text as part of the choices field. You can parse the JSON to extract the generated text and use it in your application accordingly. Modify the main method to handle the JSON response.</p>
		
		<p><h3><b>6. Example: Building a Chatbot Application :</b></h3>
		Now that you've successfully integrated ChatGPT with Java, you can extend the functionality to create interactive chatbot applications. You can use user input as the prompt and pass it to the makeChatGPTRequest method to get responses from ChatGPT. Create a loop to allow continuous conversation with the chatbot.</p>
      

      <h2><b>Conclusion</b></h2>
      <p>In this tutorial, we covered the steps to integrate ChatGPT with Java. We set up a free OpenAI account, obtained an API key, and created a Java project with the required dependencies. We used the Apache HttpClient library to make API requests to ChatGPT and handle responses. Additionally, we discussed building a chatbot application using the ChatGPT integration.
      </p>
      <p><b>Happy coding!</b></p>
     
      <div>
        <hr/>
        <ThirdpartyIntegrationTopicsList/>
      </div>
    </div>
  );
}

export default JavaIntegrationWithChatGpt;
